import * as Blockly from "blockly";
import { addButton, addSvg } from "../blocks/html";

const dropdownClassIds = {};
const dropdownFunctionIds = {};
const tags = {
  body: "body",
  div: "div",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  p: "p",
  span: "span",
  button: "button",
  input: "input",
  img: "img",
};

export const addClassOption = (optionName = "", optionId = "") => {
  if (Object.values(dropdownClassIds).includes(optionName.trim())) return;
  dropdownClassIds[optionId] = optionName.trim();
};

export const addFunctionOption = (optionName = "", optionId = "") => {
  if (Object.values(dropdownFunctionIds).includes(optionName)) return;
  dropdownFunctionIds[optionId] = optionName;
};

export const getClassOptions = () => Object.keys(dropdownClassIds);
export const getFunctionOptions = () => Object.keys(dropdownFunctionIds);

const classSelector = (title, dynamicDropdown) => ({
  init: function () {
    const dropdown = new Blockly.FieldDropdown(this.dynamicOptions);
    this.appendDummyInput().appendField(title).appendField(dropdown, "OPTIONS");

    this.appendStatementInput("DO");
    this.setColour(160);
  },

  dynamicOptions: function () {
    const dynamicDropdownItems = Object.values(dynamicDropdown);

    if (!dynamicDropdownItems.length) {
      return [["", "OPTION0"]];
    }
    return dynamicDropdownItems.map((option) => [option, option]);
  },
});

const classPseudoSelector = (title, dynamicDropdown) => ({
  init: function () {
    const dropdown = new Blockly.FieldDropdown(this.dynamicOptions);
    const pseudoDropdown = new Blockly.FieldDropdown([
      ["hover", "hover"],
      ["active", "active"],
      ["focus", "focus"],
      ["before", "before"],
      ["after", "after"],
    ]);
    this.appendDummyInput().appendField(title).appendField(dropdown, "OPTIONS");
    this.appendDummyInput()
      .appendField("on")
      .appendField(pseudoDropdown, "PSEUDO");

    this.appendStatementInput("DO");
    this.setColour(160);
  },

  dynamicOptions: function () {
    const dynamicDropdownItems = Object.values(dynamicDropdown);

    if (!dynamicDropdownItems.length) {
      return [["", "OPTION0"]];
    }
    return dynamicDropdownItems.map((option) => [option, option]);
  },
});

const functionSelector = (title, dynamicDropdown, blockType) => ({
  init: function () {
    const dropdown = new Blockly.FieldDropdown(this.dynamicOptions);
    this.jsonInit(blockType);
    this.appendDummyInput().appendField(title).appendField(dropdown, "OPTIONS");
  },

  dynamicOptions: function () {
    const dynamicDropdownItems = Object.values(dynamicDropdown);

    if (!dynamicDropdownItems.length) {
      return [["", "OPTION0"]];
    }
    return dynamicDropdownItems.map((option) => [option, option]);
  },
});

// TODO: add canvas selector
// const canvasSelector = (title, dynamicDropdown) => ({
//   init: function () {
//     const dropdown = new Blockly.FieldDropdown(this.dynamicOptions);
//     this.jsonInit(getCanvas);
//     this.appendDummyInput().appendField(title).appendField(dropdown, "OPTIONS");
//   },

//   dynamicOptions: function () {
//     const dynamicDropdownItems = Object.values(dynamicDropdown);

//     if (!dynamicDropdownItems.length) {
//       return [["", "OPTION0"]];
//     }
//     return dynamicDropdownItems.map((option) => [option, option]);
//   },
// });

export const styleClasses = classSelector("Style class", dropdownClassIds);
export const styleClassesPseudo = classPseudoSelector(
  "Style class",
  dropdownClassIds
);
export const styleElement = classSelector("Style element", tags);
export const buttonFunctionsList = functionSelector(
  "on click",
  dropdownFunctionIds,
  addButton
);
// export const canvasList = canvasSelector("element id", dropdownCanvasIds);

Blockly.Blocks["style_classes"] = styleClasses;
Blockly.Blocks["style_classes_pseudo"] = styleClassesPseudo;
Blockly.Blocks["style_element"] = styleElement;
Blockly.Blocks["add_button"] = buttonFunctionsList;
// Blockly.Blocks["canvas"] = canvasList;
