/* eslint-disable no-template-curly-in-string */
/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import * as Blockly from "blockly/core";
import { javascriptGenerator } from "blockly/javascript";
import {
  addText,
  addAlert,
  addLink,
  elementValueBlock,
  elementBlock,
  setElementProperty,
  getItemFromDatabase,
  addConsoleLog,
  setItemToDatabase,
  getCanvas,
  drawLine,
  setElementStyle,
  addEventListener,
} from "../blocks/javascript";
import { HTML, JS } from "../constants/types";
import { styleBlocks } from "../blocks/javascript";
import { STYLES_TO_JS } from "../constants/styles";

// Export all the code generators for our custom blocks,
// but don't register them with Blockly yet.
// This file has no side effects!
export const generator = {
  store: {},
  onLoad: () => {},
  onBlockProcess: () => {},
};

const DEFAULT_COLOUR = "'#ffffff'";
const DEFAULT_TEXT = "''";

const DEFAULT_FIELD_TYPES = {
  String: DEFAULT_TEXT,
  Colour: DEFAULT_COLOUR,
};

export const stripId = (id) => {
  return id.replace(/[\W_]+/g, "");
};

/**
 * Converts function to stringified function required to attach to custom block
 */
const convertFn = (name, fn) => {
  const fnToString = fn.toString();

  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(fnToString);

  const fnArgs = matches[1];

  let fnDefinition = "function ";

  if (fnToString.includes("async function")) {
    fnDefinition = "async function ";
  }

  let formattedFuncArr = [
    fnDefinition +
      javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_ +
      `(${fnArgs}) {`,
  ];

  const fnBody = fnToString.split(/{(.*)/s)[1];

  const lines = fnBody.split("\n");
  const formattedLines = lines.filter((line) => !!line);
  formattedFuncArr = [...formattedFuncArr, ...formattedLines];

  return javascriptGenerator.provideFunction_(name, formattedFuncArr);
};

const addToMap = (generator, id, params, type, category, children = []) => {
  if (!Object.keys(generator.store).includes(category)) {
    generator.store[category] = {};
  }

  if (Object.keys(generator.store[category]).includes(id)) {
    // TODO: We may need to do something here if we want to update the params, etc.
  }

  generator.store[category][id] = {
    params,
    type,
    children,
  };
};

const HELPER_FNS = {
  input_value: "valueToCode",
  input_statement: "statementToCode",
};

const attachFnToBlock = (blockData, category) => {
  const { type, generator: generatorFn, args0 } = blockData;

  // can call other fns here to execute on load
  generator.onLoad();

  const callbackWithSignals = function (block) {
    // call signals that need to be called each time block code is executed
    generator.onBlockProcess();

    let values = [];
    const params = {};
    let { id } = block;
    id = stripId(id);
    let children = [];

    args0.forEach((arg) => {
      const helper = HELPER_FNS[arg.type];
      let args = [block, arg.name];
      if (arg.type === "input_value") {
        args.push(javascriptGenerator.ORDER_NONE);
      }

      let value = "";
      if (arg.type === "field_dropdown") {
        value = `"${block.getFieldValue(arg.name)}"`;
      } else {
        value = javascriptGenerator[helper](...args);
      }

      if (arg.type === "input_statement") {
        let child = block.getChildren()?.[1];
        let childId = "";

        if (child) {
          childId = stripId(child?.id);
          children.push(childId);
        }

        while (child?.getNextBlock()) {
          child = child.getNextBlock();
          if (child) {
            childId = stripId(child?.id);
            children.push(childId);
          }
        }
        return;
      }

      if (typeof value === "string") {
        value = value.replace(/'/g, '"');
      }

      params[arg.name] = value;

      values.push(value || DEFAULT_FIELD_TYPES[arg.check]);
    });

    if (category === HTML) {
      params["id"] = `"${id}"`;
      values.push(`"${id}"`);
    }

    const blockFn = convertFn(type, generatorFn);

    // TODO: Make map of these
    addToMap(generator, id, params, type, category, children);

    values = values.filter((value) => !!value);

    // TODO: We don't need to add this argument to each fn, only certain ones that require it
    const code = `${blockFn}(${values.join(",")});`;

    // calls original callback fn - can add arguments here if needed
    return code;
  };

  generator[type] = callbackWithSignals;
};

javascriptGenerator["element_value"] = function (block) {
  const blockFn = convertFn("element_value", elementValueBlock.generator);
  const textValue = javascriptGenerator.valueToCode(
    block,
    "TEXT",
    javascriptGenerator.ORDER_NONE
  );
  const code = `${blockFn}(${textValue})`;

  return [code, 0];
};

javascriptGenerator["element_by_class_name"] = function (block) {
  const blockFn = convertFn("element_by_class_name", elementBlock.generator);
  const textValue = javascriptGenerator.valueToCode(
    block,
    "TEXT",
    javascriptGenerator.ORDER_NONE
  );
  const code = `${blockFn}(${textValue})`;

  return [code, 0];
};

javascriptGenerator["set_element_property"] = function (block) {
  const blockFn = convertFn(
    "set_element_property",
    setElementProperty.generator
  );
  const element = javascriptGenerator.valueToCode(
    block,
    "ELEMENT",
    javascriptGenerator.ORDER_NONE
  );
  const value = javascriptGenerator.valueToCode(
    block,
    "TEXT",
    javascriptGenerator.ORDER_NONE
  );

  const propertyType = block.getFieldValue("PROPERTY_TYPE");
  const code = `${blockFn}(${element}, '${propertyType}', ${value});`;

  return code;
};

javascriptGenerator["database_set"] = function (block) {
  const blockFn = convertFn("database_set", setItemToDatabase.generator);

  const datastore = javascriptGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );

  const value = javascriptGenerator.valueToCode(
    block,
    "VALUE",
    javascriptGenerator.ORDER_NONE
  );

  let isDebounced = !!value && block.parentBlock_.type.includes("procedure");

  const code = `${blockFn}("${datastore}", ${value}, ${isDebounced});`;

  return code;
};

javascriptGenerator["database_get"] = function (block) {
  const blockFn = convertFn("database_get", getItemFromDatabase.generator);

  const datastore = javascriptGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );

  let isDebounced =
    block?.parentBlock_?.parentBlock_ &&
    block.parentBlock_.parentBlock_.type.includes("procedure");

  const code = `${blockFn}("${datastore}", ${isDebounced});`;

  return [code, 0];
};

javascriptGenerator["get_canvas"] = function (block) {
  const blockFn = convertFn("get_canvas", getCanvas.generator);
  const idName = javascriptGenerator.valueToCode(
    block,
    "ID",
    javascriptGenerator.ORDER_NONE
  );

  const canvasCommands = javascriptGenerator.statementToCode(block, "BODY");

  const code = `${blockFn}("${idName}"); ${canvasCommands}`;

  return code;
};

javascriptGenerator["draw_line"] = function (block) {
  const x1 = javascriptGenerator.valueToCode(
    block,
    "X1",
    javascriptGenerator.ORDER_NONE
  );
  const y1 = javascriptGenerator.valueToCode(
    block,
    "Y1",
    javascriptGenerator.ORDER_NONE
  );
  const x2 = javascriptGenerator.valueToCode(
    block,
    "X2",
    javascriptGenerator.ORDER_NONE
  );
  const y2 = javascriptGenerator.valueToCode(
    block,
    "Y2",
    javascriptGenerator.ORDER_NONE
  );
  const color = javascriptGenerator.valueToCode(
    block,
    "COLOR",
    javascriptGenerator.ORDER_NONE
  );
  const lineWidth = javascriptGenerator.valueToCode(
    block,
    "LINE_WIDTH",
    javascriptGenerator.ORDER_NONE
  );
  const blockFn = convertFn("draw_line", drawLine.generator);

  let parentBlock = block.parentBlock_;
  while (parentBlock && parentBlock.parentBlock_) {
    parentBlock = parentBlock.parentBlock_;
  }
  let canvasBlock = parentBlock.childBlocks_[0];
  const canvasName = canvasBlock.getFieldValue("TEXT");

  const code = `${blockFn}("${canvasName}", ${x1}, ${y1}, ${x2}, ${y2}, ${color}, ${lineWidth});`;

  return code;
};

styleBlocks.forEach((blockData) => {
  javascriptGenerator[blockData.type] = function (block) {
    const argValues = blockData.args0.map((arg) => {
      if (arg.type === "field_dropdown") {
        return block.getFieldValue(arg.name);
      } else {
        if (arg.name === "PX") {
          return (
            javascriptGenerator.valueToCode(
              block,
              arg.name,
              javascriptGenerator.ORDER_NONE
            ) + "px"
          );
        }
        return javascriptGenerator.valueToCode(
          block,
          arg.name,
          javascriptGenerator.ORDER_NONE
        );
      }
    });

    const values = argValues.join(" ");
    const propertyName = STYLES_TO_JS[blockData.type.split("js_")[1]];

    return [`${propertyName}: ${values};`, 0];
  };
});

javascriptGenerator["set_element_style"] = function (block) {
  const blockFn = convertFn("set_element_style", setElementStyle.generator);
  const element = javascriptGenerator.valueToCode(block, "ELEMENT", 0);
  const style = javascriptGenerator.valueToCode(
    block,
    "STYLE",
    javascriptGenerator.ORDER_NONE
  );

  const styleObj = {};

  for (const styleItem of style.split(";")) {
    if (!styleItem.includes(":")) continue;
    const [property, value] = styleItem.split(": ");
    if (!property || !value) continue;

    styleObj[property] = value.replace(/'/g, "");
  }

  const code = `${blockFn}(${element}, ${JSON.stringify(styleObj)});`;

  return code;
};

javascriptGenerator["add_event_listener"] = function (block) {
  const blockFn = convertFn("add_event_listener", addEventListener.generator);

  const element = javascriptGenerator.valueToCode(
    block,
    "ELEMENT",
    javascriptGenerator.ORDER_NONE
  );
  const type = block.getFieldValue("TYPE");

  let callback = javascriptGenerator.statementToCode(block, "CALLBACK");
  callback = callback.replace(/\(\);/g, "");

  const code = `${blockFn}(${element}, "${type}", ${callback});`;
  console.log(code);

  return code;
};

// JS generators
attachFnToBlock(addText, JS);
attachFnToBlock(addAlert, JS);
attachFnToBlock(addConsoleLog, JS);
attachFnToBlock(addLink, JS);
