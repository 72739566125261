import React from "react";
import "./styles/Homepage.css";
import { Helmet } from "react-helmet-async";
import Footer from "./components/Footer";

// TODO: Proper h1 and other description / info down below
const About = () => {
  return (
    <>
      <Helmet>
        <title>About Blockpen</title>
        <meta
          name="description"
          content="About Blockpen, how it was created and the mission statement behind it. Blockpen is a block-based programming tool for learning the basics of web development."
        />
        <meta
          name="og:description"
          content="About Blockpen, how it was created and the mission statement behind it. Blockpen is a block-based programming tool for learning the basics of web development."
        />
      </Helmet>
      <main id="homepage-container">
        <section className="about-section" style={{ marginTop: "4rem" }}>
          <div className="about-content">
            <h3
              className="homepage-header-custom small"
              style={{
                color: "#fff",
              }}
            >
              About
            </h3>
            <span className="header-info-container">
              <p className="info-text">
                Blockpen was inspired by the young coders at the{" "}
                <a
                  href="https://renaissanceyouth.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Renaissance Youth Center's
                </a>{" "}
                Code the Bronx program. As part of the six week Saturday course,
                middle school and high schoolers learned the basics of web
                development with HTML, CSS and JS. Started in 2019, Code the
                Bronx is led by a group of volunteers who by day are software
                developers (and coding enthusiasts!). We've led classes in
                Scratch, web development and Python with Raspberry Pi's. More{" "}
                <a
                  href="https://www.youtube.com/watch?v=hRtNmyIoDWs"
                  target="_blank"
                  rel="noreferrer"
                >
                  recent classes have focused on teaching kids the fundamentals
                  of building websites
                </a>
                , where we've seen a larger interest.
                <br />
                <br />
                Building websites that are accessible on the internet is
                empowering, giving middle schoolers and high schoolers the tools
                to create their own online presence and effect change in
                communities. In our most recent module February-March 2023, kids
                ended the class with a fully functioning website designed around
                a United Nations Sustainable Development Goal. This is now
                featured on{" "}
                <a
                  href="https://letsworktogethersdg.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  RYC's Let's Work Together SDG website
                </a>
                <br />
                <br />
                Having led these classes several times, I noticed that there was
                a significant struggle in making the leap from a block-based
                coding tool like Scratch (which the majority of kids are
                familiar with) to web development with real-world coding
                languages like HTML, CSS and JS. The largest challenge is the
                syntax, or language of computers, where a misspelled keyword or
                a missing bracket can cause the program to fail. While the focus
                should be on learning the fundamentals of web development and
                how HTML, CSS and JS work together to produce interactive
                webpages, the learning process often gets bogged down by the
                syntax requirements of these languages.
                <br />
                <br />
                This is where Blockpen comes in. Designed with this problem in
                mind, Blockpen is a fully-functional editor that allows users to
                drag and drop blocks to build interactive webpages. I developed
                this app in tandem with the Code the Bronx class, seeing the
                enormous impact it had on the learning process in real-time.
                Allowing the kids in our class to see the development process of
                Blockpen while providing valuable user feedback was an awesome
                experience and one that made me believe firmly in the impact
                that this app could have.
                <br />
                <br />
                Developed as a passion project, Blockpen is still in its early
                stages of development. Feel free to test it out, play around and
                send us your thoughts! We love to implement new features based
                on user requests so shoot us a message on our contact page!
                <br />
                <br />
                <b>Nick</b>
              </p>
            </span>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default About;
