import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

import MainEditor from "./components/MainEditor";
import DisplayOnly from "./components/DisplayOnly";
import LearnBlog from "./components/blog/LearnBlog";
import HomePage from "./components/homepage/HomePage";
import { gql, useQuery } from "@apollo/client";
import "./styles/App.css";
import Navbar from "./components/Navbar";
import { LoadingSvg } from "./components/ui/LoadingSvg";
import BLOG_POSTS from "./posts";
import About from "./About";
import Contact from "./Contact";
import LearnGallery from "./components/blog/LearnGallery";
import NotFound from "./components/NotFound";

const GOOGLE_CLIENT_ID =
  process.env.GOOGLE_CLIENT_ID ||
  "6851215464-26647rkris0i9bjt9rqoht9tei7c4986.apps.googleusercontent.com";

export const FETCH_USER = gql`
  query fetchUserOperation {
    fetchUser {
      id
      name
      email
    }
  }
`;

const App = () => {
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);

  const authToken = localStorage.getItem("AUTH_TOKEN");

  // fetch user details
  const { data } = useQuery(FETCH_USER, { skip: !authToken });

  const user = data?.fetchUser;

  const setAuthToken = (token) => {
    setLoading(true);
    localStorage.setItem("AUTH_TOKEN", token);
  };

  useEffect(() => {
    if (!!authToken && loading) {
      setLoading(false);
    }
  }, [authToken]);

  const commonProps = {
    menuOpen,
    setMenuOpen,
    loginScreen,
    setLoginScreen,
  };

  return (
    <HelmetProvider>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        {loading && <LoadingSvg />}
        <Navbar user={user} setAuthToken={setAuthToken} {...commonProps} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/learn" element={<LearnGallery />} />
          {BLOG_POSTS.map((post) => (
            <Route
              key={post.path}
              path={post.path}
              element={<LearnBlog post={post.data} />}
            />
          ))}
          <Route path="/projects" element={<MainEditor {...commonProps} />} />
          <Route
            path="/projects/:path"
            element={<MainEditor {...commonProps} />}
          />
          <Route
            path="/projects/edit/:id"
            element={<MainEditor {...commonProps} />}
          />
          <Route path="/projects/share/:id" element={<DisplayOnly />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </GoogleOAuthProvider>
    </HelmetProvider>
  );
};

export default App;
