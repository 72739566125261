import React, { useState, useEffect } from "react";
import { createBlock, getBlockWorkspaceType } from "../../helpers/howToHelpers";
import LearnTooltip from "./LearnTooltip";
import "../../styles/HowTo.css";

const DEFAULT_OFFSET_AMOUNT = 250;

export const getBlockOffset = (
  workspace,
  activeBlock,
  type = "HTML",
  offsetAmount = DEFAULT_OFFSET_AMOUNT
) => {
  const topBlocks = workspace[type].getTopBlocks();

  const numOfTopBlocksOnWorkspace = topBlocks.filter(
    (block) => block !== activeBlock
  )?.length;

  return numOfTopBlocksOnWorkspace * offsetAmount;
};

const HowTo = ({
  workspace,
  setExpand,
  lesson,
  onFinish,
  onCancel,
  blockList,
  setBlockList,
}) => {
  const [active, setActive] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [activeRect, setActiveRect] = useState(true);
  const [tooltip, setTooltip] = useState(null);

  const emphasizeBlock = (block, offset = 0) => {
    if (!block) return;

    // 50% invert obfuscates it
    function revealBlock(elem) {
      let invert = 25;
      let id = null;
      clearInterval(id);
      id = setInterval(frame, 5);
      function frame() {
        if (invert === 0) {
          clearInterval(id);
          elem.style.filter = "";
          block.svgGroup_.style.outline = "";
        } else {
          invert = invert - 0.25;
          elem.style.filter = `drop-shadow(${invert / 2}px ${
            invert / 2
          }px ${invert}px black) invert(${invert}%)`;
          block.svgGroup_.style.pointerEvents = `all`;
          block.svgGroup_.style.outline = `3px dashed #7c8a94`;
        }
      }
    }

    const rect = block.svgGroup_.getBoundingClientRect();

    if (offset) {
      block.moveBy(offset, 0);
    }

    setActiveWithPosition(rect.top - 20, rect.right + 10 + offset);

    // This obfuscates the block
    //   htmlBlock.svgGroup_.style.filter = `drop-shadow(50px 50px 50px black) invert(50%)`;
    //   htmlBlock.svgGroup_.style.pointerEvents = `none`;

    // call once help text is shown
    revealBlock(block.svgGroup_);
  };

  // data structure for a how to

  const isLastStep = active === lesson.steps.length - 1;

  const closeModal = () => {
    onFinish();
    setIsOpen(false);
  };

  const setActiveWithPosition = (top, left) => {
    setIsOpen(true);
    setActiveRect({ top, left });
  };

  useEffect(() => {
    const currentStep = lesson.steps[active];

    if (currentStep.block) {
      let parentBlock = null;
      let parentBlockArgPosition = 0;
      if (currentStep.parentBlock !== undefined) {
        if (Array.isArray(currentStep.parentBlock)) {
          parentBlock = blockList[currentStep.parentBlock[0]];
          parentBlockArgPosition = currentStep.parentBlock[1];
        } else {
          parentBlock = blockList[currentStep.parentBlock];
        }
      }
      const block = createBlock(
        currentStep.block,
        currentStep.args,
        workspace,
        parentBlock,
        parentBlockArgPosition
      );

      const hasParent = currentStep.parentBlock !== undefined;
      let offset = 0;
      if (!hasParent) {
        const workspaceType = getBlockWorkspaceType(currentStep.block);
        offset = getBlockOffset(workspace, block, workspaceType);
      }

      emphasizeBlock(block, offset);
      setBlockList((blockList) => [...blockList, block]);
    } else {
      setBlockList((blockList) => [...blockList, null]);
    }
  }, [active]);

  useEffect(() => {
    const lessonBody = document.querySelector(".how-to-container");
    lessonBody.addEventListener("click", (e) => {
      if (e.target.tagName === "B") {
        const { top, left } = e.target.getBoundingClientRect();

        setTooltip({
          type: e.target.innerText,
          top,
          left,
        });
      } else {
        setTooltip(null);
      }
    });
  }, []);

  return isOpen ? (
    <div
      className="how-to-container"
      style={{ top: activeRect.top || 250, left: activeRect.left || 250 }}
    >
      {tooltip && (
        <LearnTooltip
          type={tooltip.type}
          top={tooltip.top}
          left={tooltip.left}
          closeTooltip={() => setTooltip(null)}
        />
      )}
      <div className="header-container">
        <h1 className="lesson-title">{lesson.steps[active].title}</h1>
        <span
          className="close"
          onClick={() => {
            onCancel();
          }}
        ></span>
      </div>

      <div className="slider-container">
        <div className="slider-turn">
          <p className="lesson-body">{lesson.steps[active].text}</p>;
        </div>
      </div>

      <div className="bottom">
        <div className="step">
          <ul>
            {lesson.steps &&
              lesson.steps.map((_, i) => {
                return (
                  <li
                    key={i}
                    className={i === active ? "active" : ""}
                    data-num={i}
                    onClick={() => setActive(i)}
                  ></li>
                );
              })}
          </ul>
        </div>
        <button
          className="btn"
          onClick={() => {
            if (isLastStep) {
              closeModal();
              return;
            }

            const currentStep = lesson.steps[active];
            if (currentStep.expand) setExpand(currentStep.expand);

            setActive(active + 1);
          }}
        >
          {isLastStep ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  ) : (
    ""
  );
};

export default HowTo;
