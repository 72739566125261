import { LEARN_WEB } from "./learn/web";
import { MAKE_A_GAME_WITH_SCRATCH } from "./learn/makeGameWithScratch";
import { MAKE_A_LANDING_PAGE } from "./learn/makeALandingPage";

const BLOG_POSTS = [
  {
    path: "/learn/code-on-web",
    data: LEARN_WEB,
    tags: ["Websites", "Starter", "Code"],
  },
  {
    path: "/learn/make-a-game-with-scratch",
    data: MAKE_A_GAME_WITH_SCRATCH,
    tags: ["Scratch", "Starter", "Games"],
  },
  {
    path: "/learn/how-to-make-a-landing-page",
    data: MAKE_A_LANDING_PAGE,
    tags: ["Websites", "Professional"],
  },
];

export default BLOG_POSTS;
