import React from "react";
import "./styles/Homepage.css";
import { Helmet } from "react-helmet-async";
import Footer from "./components/Footer";

// TODO: Proper h1 and other description / info down below
const Contact = () => {
  const [email, setEmail] = React.useState("");
  const [content, setContent] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  return (
    <>
      <Helmet>
        <title>Blockpen | Contact Us</title>
        <meta
          name="description"
          content="Contact us at Blockpen. We love to hear suggestions for our app and how we can improve it!"
        />
        <meta
          name="og:description"
          content="Contact us at Blockpen. We love to hear suggestions for our app and how we can improve it!"
        />
      </Helmet>
      <main id="homepage-container">
        <section className="contact-section" style={{ marginTop: "4rem" }}>
          <div className="about-content">
            <h3
              className="homepage-header-custom small"
              style={{
                color: "#fff",
              }}
            >
              Contact
            </h3>
            <span className="header-info-container">
              <p className="info-text">
                {success ? (
                  <b>Thanks for contacting us!</b>
                ) : (
                  <b>We'd love to hear from you!</b>
                )}
                {!success && (
                  <div className="auth-form">
                    <input
                      type="email"
                      name="email"
                      placeholder="email"
                      className="auth-input"
                      onChange={(e) => setEmail(e.target.value)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{ padding: "0 1rem" }}
                    />
                    <textarea
                      type="text"
                      name="text"
                      placeholder="Tell us what's up!"
                      className="auth-input"
                      onChange={(e) => setContent(e.target.value)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{
                        height: "8rem",
                        padding: "1rem",
                      }}
                    />
                    <button
                      className="auth-button"
                      onClick={async (e) => {
                        e.stopPropagation();

                        const res = await fetch(
                          "https://eobtrkgf8hw54y4.m.pipedream.net",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              email: email,
                              body: content,
                            }),
                          }
                        );

                        if (res.status === 200) {
                          setSuccess(true);
                        }
                      }}
                    >
                      Send
                    </button>
                  </div>
                )}
              </p>
            </span>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default Contact;
