import { addClassOption, addFunctionOption } from "../blocks/dynamicDropdowns";
import { CLASS_STYLE_BLOCKS } from "../constants/blocksToGenerators";

export const loadCSSClasses = (HTML, CSS) => {
  const parsedCSSBlocks = JSON.parse(CSS);
  const classes = parsedCSSBlocks?.blocks?.blocks || [];

  for (const block of classes) {
    if (CLASS_STYLE_BLOCKS.includes(block.type)) {
      const id = block?.id;
      const name = block?.fields?.OPTIONS;

      if (!id || !name) continue;

      addClassOption(name, id);
    }
  }

  const parsedHTMLBlocks = JSON.parse(HTML);
  const htmlElements = parsedHTMLBlocks?.blocks?.blocks;

  if (!htmlElements) return;

  const _addHtmlClasses = (block) => {
    if (block?.next) {
      _addHtmlClasses(block?.next?.block);
    }
    const id = block?.inputs?.CLASS?.shadow?.id;
    const inputs = block?.inputs;

    for (const key in inputs) {
      const block = inputs[key];

      if (block?.block) {
        const { block: childBlock } = block;

        if (childBlock?.next) {
          _addHtmlClasses(childBlock?.next?.block);
        }

        if (childBlock?.inputs) {
          _addHtmlClasses(childBlock);
        }
      } else if (key === "CLASS") {
        const fieldValue = Object.values(block);
        const className = fieldValue?.[0]?.fields?.TEXT;
        if (!id || !className) continue;

        addClassOption(className, id);
      }
    }
  };

  for (const block of htmlElements) {
    _addHtmlClasses(block);
  }
};

export const loadJSFunctions = (JS) => {
  const parsedJSBlocks = JSON.parse(JS);
  const functions = parsedJSBlocks?.blocks?.blocks || [];

  for (const block of functions) {
    const id = block?.id;
    const name = block?.fields?.NAME;

    if (!id || !name) continue;

    addFunctionOption(name, id);
  }
};
