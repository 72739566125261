export const LEARN_WEB = {
  header: "Learn to code on the web",
  subheader: "A beginner-friendly guide to web development with Blockpen",
  description:
    "Learning to code on the web can be scary. Start here to learn the basics of HTML, CSS, and JavaScript with Blockpen, a beginner-friendly code editor that lets you build websites without having to worry about the setup.",
  headerImg:
    "https://cdn.discordapp.com/attachments/1010577922941263892/1103493068306514060/PNG_image_24.PNG",
  headerAlt: "Code the web",
  sections: [
    {
      title: "Introduction",
      items: [
        {
          title: "Why build on the web?",
          id: "why-build-websites",
          body: (
            <span>
              The <b>web</b> is a magical place where you can create anything
              you set your mind to. From simple webpages to complex{" "}
              <b>web applications</b> like YouTube and Roblox, the web is a
              blank canvas where you can use the magical paintbrush of your
              imagination to paint all sorts of amazing things. Building on the
              web also offers the advantage of being accessible from anywhere
              with an internet connection, making it the perfect platform for
              collaboration and sharing your work with others. You can imagine
              the web as one big spider web interconnecting millions and
              billions of threads. Things you create will be spun into that web
              and will be available to anyone with a thread!
            </span>
          ),
          image:
            "https://media.discordapp.net/attachments/1010577922941263892/1104582731972751391/com.apple.Pasteboard.BJj9Up.PNG?width=1065&height=1015",
          alt: "magical paintbrush of the web",
        },
      ],
    },
    {
      title: "What can I build",
      items: [
        {
          title: "Ideas for builds",
          id: "ideas-for-builds",
          body: (
            <span>
              Your only limit is your imagination. Whether you're interested in
              making fun games for your friends to play, a website to showcase
              your artwork, a blog, a virtual escape room, a tool to help people
              learn a new skill, or a meme generator, the web offers endless
              possibilities for creative expression! The key is to start small
              and build up your skills and knowledge over time, experimenting
              and trying out new ideas along the way. With a willingness to
              learn and a passion for creating, the web is your playground and
              the sky is the limit!
            </span>
          ),
        },
        {
          title: "Building websites is hard",
          id: "building-websites-is-hard",
          body: (
            <span>
              Building on the web can be challenging, especially if you are just
              starting out. There are many different programming languages,
              frameworks, and tools to choose from, and it can be overwhelming
              to figure out where to start. But don't let that discourage you!
              There are many resources available to help you learn, including
              online tutorials and coding tools. Remember, the best way to learn
              is by doing, so start with a small project and don't be afraid to
              make mistakes. With time and practice, you'll be amazed at what
              you can create!
            </span>
          ),
        },
        {
          title: "Blockpen is here to help",
          id: "blockpen-is-here-to-help",
          body: (
            <span>
              Blockpen was designed for young learners starting their coding
              journey on the web. Similar to Scratch, Blockpen provides a visual
              interface that allows you to drag and drop blocks of code. This
              makes it easy to experiment and see the results of your changes in
              real-time. As you become more comfortable with coding, you can
              gradually transition to writing code using a traditional text
              editor. With Blockpen, the possibilities are endless and the
              learning curve is gentle, making it a great tool for all ages and
              skill levels. Just click start building and begin dragging and
              dropping blocks to make a real website!
            </span>
          ),
          tieInLesson: { text: "Start building", href: "/projects/new" },
        },
        {
          title: "What is a programming language and how is it used?",
          id: "what-is-a-programming-language",
          body: (
            <span>
              A programming language is a way to communicate with a computer. It
              is a set of instructions and commands that a computer can
              understand and run. Another way to think of it is like a secret
              code you might use with your friends. Only instead of passing
              secret messages to your friends, you're passing instructions to
              the computer. Just like you need to know the special rules of your
              secret code to understand the messages, a computer needs to know
              the special rules of a programming language to understand the
              instructions. There are many different types of programming
              languages just like there are many different types of secret
              codes! For building on the web, we're going to focus on three main
              ones. <b>HTML</b>, <b>CSS</b> and <b>JS</b>.
            </span>
          ),
        },
      ],
    },
    {
      title: "What's HTML?",
      items: [
        {
          title: "Overview",
          id: "html-overview",
          body: (
            <span>
              This is the skeleton or foundation of the webpage. Imagine you're
              building a treehouse. You'd need wood, nails, and maybe some paint
              to make it look just the way you want. When you're building a
              website, HTML is like the wood and nails for your treehouse. It
              forms the structure and support of the webpage in much the same
              way that the wood and nails form the structure and support of the
              treehouse.
            </span>
          ),
        },
        {
          title: "What does HTML code look like?",
          id: "what-does-html-code-look-like",
          body: (
            <span>
              HTML stands for HyperText Markup Language. That sounds complicated
              but it's really just a fancy way of saying a language used to
              create and structure webpage content. HTML uses different tags,
              which are like commands, to create elements like headings,
              paragraphs, links, images, and so much more! In typical website
              code this is structured like this:
              <pre>{`<h1>Welcome to my treehouse</h1>`}</pre>
              This defines a header of the #1 size and is used for the largest
              text on a webpage! This is the text that is meant to stand out
              first to those who visit your site.
            </span>
          ),
        },
        {
          title: "HTML in Blockpen",
          id: "html-in-blockpen",
          body: (
            <span>
              In Blockpen, you can make an h1 by dragging and dropping a header
              block and setting it to h1. The difference between using Blockpen
              to drag blocks and writing code by hand is that if you make a
              mistake, the computer won't understand! Think of the secret code.
              If one or two letters is off, the entire message might not make
              sense. Blockpen makes it easier to start coding without worrying
              about the computer understanding. Every block you drag is fully
              understandable by the computer and there are no mistakes in
              Blockpen!
            </span>
          ),
        },
      ],
    },
    {
      title: "What's CSS?",
      items: [
        {
          title: "Overview",
          id: "html-overview",
          body: (
            <span>
              If HTML is like the wood and nails of your treehouse, CSS is the
              paint and all the decorations that make your treehouse look
              awesome and unique. CSS stands for Cascading Style Sheets and you
              can think of it like a sheet on which you can write down all sorts
              of instructions to make your treehouse look the way you want it
              to. Imagine our treehouse. We've just put up the wood beams, the
              ladder and the structure of the house. It works and you can sit in
              it, but it's all wood-colored and a bit boring. So, you decide to
              paint it blue, add some cool stickers, and maybe even some shiny
              curtains! That's pretty much what CSS does to a website. It adds
              colors, changes the size and style of the text, adjusts the
              layout, and much more.
            </span>
          ),
        },
        {
          title: "What does JavaScript code look like?",
          id: "what-does-js-code-look-like",
          body: (
            <span>
              We call items on a webpage `elements`. Examples of some elements
              are text, images and buttons. To style these elements we refer to
              them in our CSS and write what styles we want. CSS code looks like
              this: <pre>h1 &#123; color: red; &#125;</pre> In the above example
              we're referring to the `h1` (or header 1) element and telling our
              computer to make it red! This `h1` is called a selector. Selectors
              are how we specify which elements we want to change.
            </span>
          ),
        },
        {
          title: "CSS in Blockpen",
          id: "css-in-blockpen",
          body: (
            <span>
              In Blockpen, the selector block is like a label that tells which
              part of your webpage you want to style. Once you've chosen a part
              of your webpage with a selector block, you can connect it to as
              many style blocks as you want to create the look you're going for.
              For example, let's say you have a selector block for the heading 1
              (often written as "h1" in HTML). This is like saying, "I want to
              style the main title of my webpage." Next, you might connect a
              style block that sets the font size to make your title big and
              bold. Maybe another style block to change the color of the text.
              You could even add a style block to give your title a cool
              background color!
            </span>
          ),
        },
      ],
    },
    {
      title: "What's JavaScript?",
      items: [
        {
          title: "Overview",
          id: "js-overview",
          body: (
            <span>
              JavaScript or JS for short is the language that makes all the
              gadgets and gizmos on a webpage work! Without JS, webpages would
              look great but they wouldn't do much. Thinking back to our
              treehouse, JavaScript is like the doors, ropes, pulleys, secret
              passages and other fun gadgets. It adds interactivity to a
              webpage. If you've ever searched or clicked a button or done any
              action on a webpage, then you've used JavaScript!
            </span>
          ),
        },
        {
          title: "What does JavaScript code look like?",
          id: "what-does-js-code-look-like",
          body: (
            <span>
              The code for JavaScript looks like this and can be arranged in
              countless different ways to unlock different abilities!
              <pre>alert('Hello user!')</pre> This code opens an alert box that
              says `Hello user!`. This is one simple use of JavaScript but there
              are countless others. The language is made up of different
              statements and expressions which are covered in our JavaScript
              deep dive! In Blockpen, JavaScript is made up of different kinds
              of blocks that you can combine in different ways to form unique
              commands and instructions for interactivity.
            </span>
          ),
        },
        {
          title: "JS in Blockpen",
          id: "js-in-blockpen",
          body: (
            <span>
              In Blockpen, JavaScript is made up of different kinds of blocks
              that you can combine in different ways to form unique commands and
              instructions for interactivity.
            </span>
          ),
        },
      ],
    },
    {
      title: "How it comes together",
      items: [
        {
          title: "Recap",
          id: "recap",
          body: (
            <span>
              When you're building a webpage, HTML, CSS, and JavaScript all work
              together, each one doing its own special job to create an
              interactive, attractive and functional website. Here is an
              overview!{" "}
              <ol>
                <li>
                  1.{" "}
                  <b data-definition="HTML">HTML (HyperText Markup Language)</b>
                  : This is the skeleton or foundation of your webpage. It's
                  like the wood and nails of your treehouse. HTML creates the
                  basic structure and content of your webpage, like headings,
                  paragraphs, images, and links.{" "}
                </li>{" "}
                <br />
                <li>
                  2. <b data-definition="CSS">CSS (Cascading Style Sheets)</b>:
                  This is the decorator of your webpage. Like the paint and
                  decorations of your treehouse, CSS makes your webpage look
                  nice and stylish. It changes colors, adjusts the layout,
                  changes the size and style of your text, and much more.{" "}
                </li>{" "}
                <br />
                <li>
                  3. <b data-definition="JS">JavaScript</b>: This is the magic
                  that makes your webpage interactive. It's like the ropes and
                  pulleys in your treehouse. JavaScript makes things happen on
                  your webpage, like displaying messages when buttons are
                  clicked, changing content dynamically, validating form inputs,
                  and so much more.
                </li>
              </ol>
            </span>
          ),
        },
        {
          title: "Example Website",
          id: "example-website",
          body: (
            <span>
              Let's break down a simple example of a website and see how HTML,
              CSS, and JavaScript each play their unique roles:
              <ol>
                <li>
                  1. <b>HTML</b>: Imagine we're building a webpage with a big
                  title at the top, a picture of a cute puppy, and a button that
                  says "Click me for a surprise!" The HTML would create the
                  structure for this. It would set up the title using a heading
                  tag (like **
                  {`<h1>`}**), add the puppy picture using an image tag (**
                  {`<img>`}**), and create the button with a button tag (**
                  {`<button>`}**).{" "}
                </li>
                <br />
                <li>
                  2. <b>CSS</b>: Now, our webpage is functional, but it's pretty
                  plain. That's where CSS comes in. With CSS, we can make the
                  title a fun color, like bright blue, and maybe we'll make the
                  text really big. We can also add a border around the puppy
                  picture and give our button a cool background color and some
                  rounded corners to make it look more button-like.
                </li>{" "}
                <br />
                <li>
                  3. <b>JavaScript</b>: Our webpage looks great now, but nothing
                  happens when we click the button. That's where JavaScript
                  comes in. With JavaScript, we can make something fun happen
                  when the button is clicked. Maybe when the button is clicked,
                  we could display a hidden message that says, "You found the
                  surprise!" or change the image to a picture of a kitten.
                </li>
              </ol>
            </span>
          ),
        },
        {
          title: "Starting with Blockpen",
          id: "starting-with-blockpen",
          body: (
            <span>
              In Blockpen, you'll find blocks for <b>HTML</b>, <b>CSS</b> and{" "}
              <b>JS</b> arranged from left to right. You can start by dragging
              and dropping HTML blocks to create some elements like text, images
              and buttons on the webpage. After that, you can start styling it
              with CSS blocks. Once you like the look and feel, you can use
              JavaScript blocks to add interactivity and make it do something
              cool! As you get more comfortable with each language, you can
              start looking at the actual code behind the blocks and learn how
              to write it yourself. Before long, you'll be able to create a
              complete website, all on your own. So go ahead, start building,
              and let your imagination run wild!
            </span>
          ),
          tieInLesson: { text: "Start creating", href: "/projects/new" },
        },
      ],
    },
  ],
};
