import React, { useEffect, useState } from "react";
import HowTo from "./HowTo";
import HowToOverview from "./HowToOverview";
import HowToList, { ALIEN_LANDING_PAGE, howToTypes } from "./HowToList";

const HowToContainer = ({
  workspace,
  setExpand,
  closeModal,
  isDemo,
  learnParam,
}) => {
  const [showOverview, setShowOverview] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [selectedSection, setSelectedSection] = useState(0);
  const [isListOpen, setIsListOpen] = useState(true);
  const [blockList, setBlockList] = useState([]);

  const demoLesson = howToTypes[ALIEN_LANDING_PAGE];

  useEffect(() => {
    if (isDemo && demoLesson) {
      setIsListOpen(false);
      setShowOverview(true);
      setSelectedLesson(demoLesson);
    }
  }, [isDemo]);

  useEffect(() => {
    if (learnParam) {
      const lesson = howToTypes[learnParam];
      if (lesson) {
        setIsListOpen(false);
        setShowOverview(true);
        setSelectedLesson(lesson);
      }
    }
  }, [learnParam]);

  return (
    <>
      {showOverview && selectedLesson?.sections && (
        <HowToOverview
          selectedLesson={selectedLesson}
          setSelectedSection={() => {
            setShowOverview(false);
          }}
          selectedSection={selectedSection}
          closeModal={closeModal}
        />
      )}
      {isListOpen && (
        <HowToList
          setSelectedLesson={(lesson) => {
            setIsListOpen(false);
            if (lesson?.sections?.length > 1) {
              setShowOverview(true);
            }

            setSelectedLesson(lesson);
          }}
          closeModal={() => {
            closeModal();
          }}
        />
      )}
      {!showOverview && selectedLesson && (
        <HowTo
          workspace={workspace}
          setExpand={setExpand}
          lesson={
            selectedLesson?.sections
              ? selectedLesson?.sections?.[selectedSection]
              : selectedLesson
          }
          onCancel={() => {
            if (!selectedLesson?.sections) {
              setSelectedLesson(null);
              setIsListOpen(true);
              return;
            }

            setShowOverview(true);
          }}
          onFinish={() => {
            if (
              selectedSection === selectedLesson?.sections?.length - 1 ||
              !selectedLesson?.sections?.length
            ) {
              closeModal();
              return;
            }

            setShowOverview(true);
            setSelectedSection((prev) => prev + 1);
          }}
          blockList={blockList}
          setBlockList={setBlockList}
        />
      )}
    </>
  );
};

export default HowToContainer;
