import { addAlertCallback, addTextCallback } from "../generator/javascript";

export const BLOCKS_TO_GENERATORS = {
  add_text: addTextCallback,
  alert: addAlertCallback,
};

export const CLASS_STYLE_BLOCKS = [
  "style_classes",
  "style_element",
  "style_classes_pseudo",
];
