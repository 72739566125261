const PRODUCTION_API_URL = "https://api.blockpen.app/";
const DEVELOPMENT_API_URL = "http://localhost:3000/";

export const getApiUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return PRODUCTION_API_URL;
  } else {
    return DEVELOPMENT_API_URL;
  }
};
