import * as Blockly from "blockly";
import { cssBlocksForGeneration } from "../blocks/css";

export const cssGenerator = new Blockly.Generator("CSS");

cssGenerator.PRECEDENCE = 0;

cssGenerator.scrub_ = function (block, code, thisOnly) {
  const nextBlock = block.nextConnection && block.nextConnection.targetBlock();
  if (nextBlock && !thisOnly) {
    return code + cssGenerator.blockToCode(nextBlock);
  }
  return code;
};

cssGenerator["text"] = function (block) {
  const textValue = block.getFieldValue("TEXT");
  const code = `${textValue}`;
  return [code, cssGenerator.PRECEDENCE];
};

cssGenerator["math_number"] = function (block) {
  const code = String(block.getFieldValue("NUM"));
  return [code, cssGenerator.PRECEDENCE];
};

cssGenerator["colour_picker"] = function (block) {
  // Colour picker.
  const code = block.getFieldValue("COLOUR");
  return [code, cssGenerator.PRECEDENCE];
};

cssGenerator["style_classes"] = function (block) {
  const className = block.getFieldValue("OPTIONS");
  const statement = cssGenerator.statementToCode(block, "DO");

  if (!statement) {
    return "";
  }

  const code = `.${className} {
    ${statement}
  }`;

  return code;
};

cssGenerator["style_classes_pseudo"] = function (block) {
  const className = block.getFieldValue("OPTIONS");
  const pseudoClass = block.getFieldValue("PSEUDO");
  const statement = cssGenerator.statementToCode(block, "DO");

  if (!statement) {
    return "";
  }

  const code = `.${className}:${pseudoClass} {
    ${statement}
  }`;

  return code;
};

cssGenerator["style_element"] = function (block) {
  const element = block.getFieldValue("OPTIONS");
  const statement = cssGenerator.statementToCode(block, "DO");

  if (!statement) {
    return "";
  }

  const code = `${element} {
    ${statement}
  }`;

  return code;
};

cssGenerator["add_animation"] = function (block) {
  const animationName = cssGenerator.valueToCode(
    block,
    "NAME",
    cssGenerator.PRECEDENCE
  );
  const from = cssGenerator.statementToCode(block, "FROM");
  const to = cssGenerator.statementToCode(block, "TO");

  if (!from && !to) {
    return "";
  }

  const code = `
    @keyframes ${animationName} {
      from {
        ${from}
      }
      to {
        ${to}
      }
    }`;

  return code;
};

cssBlocksForGeneration.forEach((blockData) => {
  cssGenerator[blockData.type] = function (block) {
    const argValues = blockData.args0.map((arg) => {
      if (arg.type === "field_dropdown") {
        return block.getFieldValue(arg.name);
      } else if (arg.type === "input_value") {
        if (arg.name.includes("PX")) {
          return (
            cssGenerator.valueToCode(block, arg.name, cssGenerator.PRECEDENCE) +
            "px"
          );
        }

        if (arg.name === "SECONDS") {
          return (
            cssGenerator.valueToCode(block, arg.name, cssGenerator.PRECEDENCE) +
            "s"
          );
        }
        if (arg.name === "PERCENT") {
          return (
            cssGenerator.valueToCode(block, arg.name, cssGenerator.PRECEDENCE) +
            "%"
          );
        }

        if (blockData.valueFormatter) {
          return blockData.valueFormatter(
            cssGenerator.valueToCode(block, arg.name, cssGenerator.PRECEDENCE)
          );
        }

        return cssGenerator.valueToCode(
          block,
          arg.name,
          cssGenerator.PRECEDENCE
        );
      }
    });

    const values = argValues.join(" ");

    return `${blockData.type}: ${values};`;
  };
});
