/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/*
This toolbox contains nearly every single built-in block that Blockly offers,
in addition to the custom block 'add_text' this sample app adds.
You probably don't need every single block, and should consider either rewriting
your toolbox from scratch, or carefully choosing whether you need each block
listed here.
*/

import { CSS_BLOCK_TYPES } from "../blocks/css";

export const ARG_TO_INPUT_TYPES = {
  PX: { inputType: "math_number", defaultValue: 50, defaultValueType: "NUM" },
  TEXT: { inputType: "text", defaultValue: "", defaultValueType: "TEXT" },
  COLOR: {
    inputType: "colour_picker",
    defaultValue: "#aa00cc",
    defaultValueType: "COLOUR",
  },
  INTEGER: {
    inputType: "math_number",
    defaultValue: 10,
    defaultValueType: "NUM",
  },
  FLOAT: {
    inputType: "math_number",
    defaultValue: 0.5,
    defaultValueType: "NUM",
  },
  SECONDS: {
    inputType: "math_number",
    defaultValue: 1,
    defaultValueType: "NUM",
  },
  PERCENT: {
    inputType: "math_number",
    defaultValue: 50,
    defaultValueType: "NUM",
  },
};

const toolboxBlocks = Object.keys(CSS_BLOCK_TYPES).map((type) => {
  const inputs = {};

  let count = 1;
  CSS_BLOCK_TYPES[type].args.forEach((arg) => {
    if (arg === "dropdown") {
      return;
    }

    let argName = arg.toUpperCase();
    const { inputType, defaultValue, defaultValueType } =
      ARG_TO_INPUT_TYPES[argName];

    if (Object.keys(inputs).includes(argName)) {
      argName = `${argName}${count}`;
      count += 1;
    }

    inputs[argName] = {
      shadow: {
        type: inputType,
        fields: {
          [defaultValueType]: defaultValue,
        },
      },
    };
  });

  return {
    kind: "block",
    type: type,
    inputs,
  };
});

export const styleToolboxCategory = {
  name: "Styles",
  categorystyle: "text_category",
  kind: "category",
  contents: [
    ...toolboxBlocks,
    {
      kind: "block",
      type: "add_animation",
      inputs: {
        NAME: {
          shadow: {
            type: "text",
            fields: {
              TEXT: "animation",
            },
          },
        },
      },
    },
  ],
};

const toolboxStructure = {
  Font: ["font-family", "font-size", "text-shadow"],
  Color: ["color", "background-color"],
  Size: ["width", "height"],
  Spacing: [
    "margin",
    "padding",
    "margin-right",
    "margin-left",
    "margin-top",
    "margin-bottom",
    "padding-right",
    "padding-left",
    "padding-top",
    "padding-bottom",
  ],
  Animations: [
    "add_animation",
    "animation-name",
    "animation-duration",
    "animation-iteration-count",
    "animation-direction",
    "transition",
  ],
  Appearance: [
    "opacity",
    "border-radius",
    "border",
    "box-shadow",
    "cursor",
    "z-index",
  ],
  Alignment: [
    "position",
    "text-align",
    "display",
    "flex-direction",
    "justify-content",
    "align-items",
    "top",
    "bottom",
    "left",
    "right",
  ],
  Image: [
    "background-image",
    "background-size",
    "background-repeat",
    "background-position",
  ],
};

export const toolbox = {
  kind: "categoryToolbox",
  contents: [
    {
      name: "Selectors",
      categorystyle: "text_category",
      kind: "category",
      contents: [
        { kind: "block", type: "style_classes" },
        { kind: "block", type: "style_classes_pseudo" },
        { kind: "block", type: "style_element" },
      ],
    },
    ...Object.keys(toolboxStructure).map((category) => ({
      name: category,
      categorystyle: "text_category",
      kind: "category",
      contents: toolboxStructure[category].map((block) => {
        return styleToolboxCategory.contents.find(
          (toolboxBlock) => block === toolboxBlock.type
        );
      }),
    })),
  ],
};
