import React from "react";

const Footer = () => (
  <footer className="homepage-info footer-svg" style={{ marginBottom: "0" }}>
    <div
      className="homepage-content"
      style={{
        width: "60%",
      }}
    >
      <div className="footer-container">
        <a href="/about" className="footer-item">
          About
        </a>
        <a href="/contact" className="footer-item">
          Contact
        </a>
        <a href="/learn" className="footer-item">
          Learn
        </a>
      </div>
      <p className="copyright-text">
        Copyright © 2023 Blockpen. All rights reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
