export const MAKE_A_GAME_WITH_SCRATCH = {
  header: "How to make a game with Scratch",
  subheader:
    "A beginner-friendly guide to planning and making a game in Scratch",
  headerImg:
    "https://en.scratch-wiki.info/w/images/thumb/ScratchCat-Small.png/200px-ScratchCat-Small.png",
  headerAlt: "Scratch cat",
  sections: [
    {
      title: "Introduction",
      items: [
        {
          title: "Why use Scratch?",
          id: "why-use-scratch",
          body: (
            <span>
              Scratch is a great platform for beginners in coding. It uses a
              block-based <b>programming language</b> which allows users to
              focus on the logic behind programming without needing to worry
              about getting the exact <b>syntax</b> right. Coding a fully
              functional game with Scratch can be a fun, challenging and
              rewarding endeavor so we'll outline the process here!
            </span>
          ),
        },
      ],
    },
    {
      title: "Planning",
      items: [
        {
          title: "Come up with an idea for your Scratch game",
          id: "come-up-with-n-scratch-game-idea",
          body: (
            <span>
              Before you start, you should have an idea of what kind of game you
              want to make in Scratch. You could make a simple platformer like
              Geometry Dash, a puzzle game like Candy Crush, a racing game like
              Mario Kart, or even a story-based adventure! These are just a few
              of the types of games you can create with Scratch. When outlining
              your idea, it's good to think of a few things:
              <ol>
                <li>
                  <span style={{ fontWeight: "800" }}>1. Art style:</span> The
                  way the game should look. It could be cartoonish, pixelated,
                  realistic or abstract to name a few ideas!
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>
                    2. User Interface (UI):
                  </span>{" "}
                  How will the player of my game interact with it? Will it be
                  with the arrow keys or AWSD? Will the character use space bar
                  to jump? This also includes the HUD or Heads Up Display. This
                  generally includes anything from the health bar to a score to
                  an inventory.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>3. Objective:</span> What
                  does the player need to do to succeed in the game? How many
                  levels will there be? Can they win by achieving a certain
                  score or is it by completing a certain amount of levels? These
                  are a few of the questions to ask when planning your game!{" "}
                </li>
              </ol>
            </span>
          ),
        },
      ],
    },
    {
      title: "Getting familiar with Scratch",
      items: [
        {
          title: "Create a new project",
          id: "create-a-new-project",
          body: (
            <span>
              Once you have an idea of what you want to make, you can start by
              creating a new project in Scratch. You can do this by going to the
              Scratch website and creating a new project. You'll start with a
              blank canvas and a default sprite, the Scratch Cat.
            </span>
          ),
        },
        {
          title: "Get to know the Scratch interface",
          id: "scratch-interface",
          body: (
            <span>
              Get to know the Scratch <b>user interface</b>. This interface is
              divided into several areas:
              <ol>
                <li>
                  <span style={{ fontWeight: "800" }}>The Stage:</span> This is
                  where your game takes place. You can add backgrounds here.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>The Sprites Pane:</span>{" "}
                  This is where you can add, delete, and manage the characters,
                  objects, or elements in your game.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>The Blocks Palette:</span>{" "}
                  This contains the blocks of code that you'll use to program
                  your game. The blocks are categorized by function (motion,
                  looks, sound, etc.)
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>The Scripts Area:</span>{" "}
                  This is where you'll drag and drop blocks to control what your
                  sprites do.
                </li>
              </ol>
            </span>
          ),
        },
      ],
    },
    {
      title: "Making your game",
      items: [
        {
          title: "Add sprites and backgrounds",
          id: "add-sprites-and-backgrounds",
          body: (
            <span>
              You can add new sprites using the 'Choose a Sprite from library'
              button or by drawing your own. Same goes for the background, you
              can choose from the library or paint your own.
            </span>
          ),
        },
        {
          title: "Start Coding",
          id: "start-coding",
          body: (
            <span>
              Drag and drop the blocks from the Blocks Palette into the Scripts
              Area. You can click on a block to see what it does. Blocks snap
              together to form scripts, which tell the sprites what to do. For
              example, if you want a sprite to move when you press a certain
              key, you might use something like this:
              <img
                src="https://media.discordapp.net/attachments/1010577922941263892/1114175079820050472/Screen_Shot_2023-06-02_at_8.53.36_AM.png?width=827&height=403"
                height="250"
                weight="250"
                style={{
                  borderRadius: "25px",
                  boxShadow: "0px 0px 10px #000",
                  margin: "25px 0",
                }}
              />
              <br />
              This script will move the sprite 10 steps to the right whenever
              the right arrow key is pressed. Combining blocks in different ways
              will do different things and exploring is a great way to learn
              more about the types of blocks and what you can do.
            </span>
          ),
        },
        {
          title: "Refining your game",
          id: "refining-your-game",
          body: (
            <span>
              Once you have the basic functionality of your game, you can start
              to add more features and refine it. You can add more sprites,
              backgrounds, and scripts to make your game more interesting. You
              can also add sound effects and music to make your game more
              immersive. You can also add more levels to your game to make it
              more challenging. The possibilities are endless! As you add more
              code, <b>test</b> your game often to make sure everything works as
              expected. If something's not right, <b>troubleshoot</b> by
              checking your scripts and trying different solutions.
            </span>
          ),
        },
        {
          title: "Sharing your game",
          id: "sharing-your-game",
          body: (
            <span>
              Once you're happy with your game and have tested it, you can share
              it with the Scratch community. Just click on the orange Share
              button on the top of the page. Not only is sharing a way of
              showing off your work, but it's also a great way to get feedback
              from others about what you can improve! Remember, making a game is
              a process of creativity and problem-solving. You'll likely
              encounter challenges along the way, but that's part of the fun.
              Happy game designing!
            </span>
          ),
        },
        {
          title: "Ready to level up?",
          id: "level-up-with-blockpen",
          body: (
            <span>
              Want to level up and start learning to make games with real-world
              programming languages? Start building with Blockpen!
            </span>
          ),
          tieInLesson: { text: "Start creating", href: "/projects/new" },
        },
      ],
    },
  ],
};
