import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        padding: "5rem 2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 style={{ fontFamily: "Sigmar One", color: "white" }}>
        404: Page Not Found
      </h1>
      <img
        src="https://media.discordapp.net/attachments/1010577922941263892/1114257731059196014/PNG_image_33.PNG"
        width="250"
        height="250"
        alt="404 image"
      />
    </div>
  );
};

export default NotFound;
