import { HTML, CSS } from "../constants/types";

export const HOW_TO_CENTER_CONTENT_STEPS = {
  steps: [
    {
      title: "Centering content",
      text: (
        <span>
          Aligning content to the center of a webpage takes a few steps. First
          we need a container to hold the content we want to center.
        </span>
      ),
      expand: HTML,
    },
    {
      // TODO: can add definitions around certain words that trigger separate tooltips
      title: "Add a div element",
      text: (
        <span>
          We can use a <b>div</b> element to hold the content. Let's create one
          now in the <b>HTML</b> workspace.
        </span>
      ),
      block: "div",
      args: {
        CLASS: "center-container",
      },
    },
    {
      title: "Add text to center",
      text: (
        <span>
          Let's add a paragraph within the <b>div</b> so that we can see the
          content we want to center.
        </span>
      ),
      block: "add_paragraph",
      args: {
        TEXT: "This is text we want to center",
        CLASS: "centered-paragraph",
      },
      // expands a workspace for the next step
      expand: CSS,
      // References the block that was created in the previous step (the number signifying the index of the step)
      parentBlock: 1,
    },
    {
      title: "Add a selector",
      text: (
        <span>
          In <b>CSS</b>, we need to create a <b>selector</b> to point to that{" "}
          <b>div</b> with a <b>class</b> of center-container.
        </span>
      ),
      block: "style_classes",
      args: {
        CLASS: "center-container",
      },
    },
    {
      title: "Set display to flex",
      text: (
        <span>
          We set the <b>display</b> to <b>flex</b> in order to move the content
          flexibly.
        </span>
      ),
      block: "display",
      args: {
        VALUE: "flex",
      },
      parentBlock: 3,
    },
    {
      title: "Position to center",
      text: (
        <span>
          To set the position on the horizontal axis, we can use{" "}
          <b>justify-content</b> and set it to center.
        </span>
      ),
      block: "justify-content",
      args: {
        VALUE: "center",
      },
      parentBlock: 3,
    },
  ],
};

export const ALIEN_LANDING_PAGE_STEPS = {
  title: "Alien landing page",
  sections: [
    {
      title: "Make a header",
      steps: [
        {
          // 0
          title: "Welcome to Earth",
          text: (
            <span>
              Let's create a <b>landing page</b> for an alien. We'll start with
              the <b>HTML</b> and then add some <b>CSS</b> to style it for our
              earthbound friends.
            </span>
          ),
          expand: HTML,
        },
        {
          // 1
          title: "Add a div element",
          text: (
            <span>
              We can begin by creating a container with a <b>div</b> element.
            </span>
          ),
          block: "div",
          args: {
            CLASS: "alien-container",
          },
        },
        // TODO: It'd be cool to add time to "explore" the workspace before moving on to the next step - for example, try changing the text here
        // Then the next step is triggered by satisfying whatever the "explore" requirement was - for example, changing the text of this header
        {
          // 2
          title: "Add a header",
          text: (
            <span>
              Next, we need a <b>header</b> to welcome our alien friends to
              Earth. We can add the text as well as a <b>class</b> which we'll
              use to identify it in <b>CSS</b>.
            </span>
          ),
          block: "add_header",
          args: {
            TEXT: "Welcome to Earth",
            CLASS: "alien-header",
            SIZE: "h1",
          },
          parentBlock: 1,
        },
        {
          // 3
          title: "What do you think?",
          text: "The text is there, but our earthbound friends might be a little underwhelmed by our welcome. Let's add some styles to make it stand out.",
          expand: CSS,
        },
        {
          // 4
          title: "Add a selector",
          text: (
            <span>
              In order to style in <b>CSS</b>, we need to create a{" "}
              <b>selector</b> to point to the <b>HTML</b> element. We can select
              the <b>header</b> by using the <b>class name</b> we gave it.
            </span>
          ),
          block: "style_classes",
          args: {
            CLASS: "alien-header",
          },
        },
        {
          // 5
          title: "Make the text bigger",
          text: (
            <span>
              We can use the <b>font-size</b> property to make the text bigger.
              The number refers to the amount of pixels used.
            </span>
          ),
          block: "font-size",
          args: {
            PX: 50,
          },
          parentBlock: 4,
        },
        {
          // 6
          title: "Try a different color",
          text: (
            <span>
              We can change the color of the text to make it pop. We can use the{" "}
              <b>color</b> property to do this.
            </span>
          ),
          block: "color",
          args: {
            // green
            COLOR: "#33ff33",
          },
          parentBlock: 4,
        },
        {
          // 7
          title: "Let's change the font.",
          text: (
            <span>
              We can change the font type with the <b>font-family</b> property.
              Let's try a new style!
            </span>
          ),
          block: "font-family",
          args: {
            VALUE: "Bungee Shade, cursive",
          },
          parentBlock: 4,
        },
      ],
    },
    {
      title: "Set a background",
      steps: [
        {
          // 8
          title: "Add a selector for container",
          text: (
            <span>
              We need to add a <b>selector</b> for the container <b>div</b>. We
              can use the <b>class</b> name we gave it.
            </span>
          ),
          block: "style_classes",
          args: {
            CLASS: "alien-container",
          },
        },
        {
          // 9
          title: "Add a background image",
          text: (
            <span>
              Let's add a background image to our <b>landing page</b>. We'll use
              the <b>background-image</b> property to set the image on the
              container <b>div</b>. For background images, we need to use a URL
              that references the image address.
            </span>
          ),
          block: "background-image",
          args: {
            TEXT: "https://tinyurl.com/alien-img",
          },
          parentBlock: 8,
        },
        {
          // 10
          title: "Set display",
          text: (
            <span>
              Now, in order to center this header so that appears in the middle
              of the background image, we can set the <b>display</b> property. A
              value of <b>flex</b> allows us to move content flexibly.
            </span>
          ),
          block: "display",
          args: {
            VALUE: "flex",
          },
          parentBlock: 8,
        },
        {
          // 11
          title: "Move to center",
          text: (
            <span>
              We can use the <b>justify-content</b> property to move the{" "}
              <b>header</b>
              to the center of the page on the horizontal or x axis.
            </span>
          ),
          block: "justify-content",
          args: {
            VALUE: "center",
          },
          parentBlock: 8,
        },
        {
          // 12
          title: "Make it taller",
          text: (
            <span>
              We can use the <b>height</b> property to make the container taller
              so that the <b>header</b> takes up more space.
            </span>
          ),
          block: "height",
          args: {
            PX: "250",
          },
          parentBlock: 8,
        },
        {
          // 13
          title: "Notice anything?",
          text: (
            <span>
              The <b>header</b> is centered on the horizontal axis from left to
              right but it's still at the top of the page. We need to move it to
              the center of the page on the vertical axis as well.
            </span>
          ),
        },
        {
          // 14
          title: "Center vertically",
          text: (
            <span>
              We can use the <b>align-items</b> property to move the{" "}
              <b>header</b> to the center of the page from top to bottom.
            </span>
          ),
          block: "align-items",
          args: {
            VALUE: "center",
          },
          parentBlock: 8,
        },
      ],
    },
    {
      title: "Animate it",
      steps: [
        {
          // 15
          title: "Now for the fun!",
          text: (
            <span>
              Let's add some animation to our landing page. We'll use the{" "}
              <b>animation</b> property in <b>CSS</b> to do this.
            </span>
          ),
        },
        {
          // 16
          title: "Using animation keyframes",
          text: (
            <span>
              We can use the <b>animation</b> property to create two{" "}
              <b>keyframes</b> in our animation. We then specify where we want
              the animation to start and end. We can use any{" "}
              <b>CSS properties</b> here. Let's name it something that describes
              what it will do. In this case, we can name it{" "}
              <em>move-background</em>.
            </span>
          ),
          block: "add_animation",
          args: {
            NAME: "move-background",
          },
        },
        {
          // 17
          title: "Set the animation's start",
          text: (
            <span>
              We can use the <b>from</b> keyword to set the animation's start.
              To give the moving background effect we want, we can set the{" "}
              <b>background-position</b> property to the left side of the image.
              This will allow the image to pan from left to right and give the{" "}
              <b>header</b> the effect of moving.
            </span>
          ),
          block: "background-position",
          args: {
            PERCENT: 5,
          },
          parentBlock: [16, 0],
        },
        {
          title: "Set the animation's end",
          text: (
            <span>
              We can use the <b>to</b> keyword to set where we want the
              animation to end. In this case, setting the{" "}
              <b>background-position</b> property to a higher percent will allow
              the animation to transition to the right side of the image.
            </span>
          ),
          block: "background-position",
          args: {
            PERCENT: 95,
          },
          parentBlock: [16, 1],
        },
        {
          // 19
          title: "Use the animation",
          text: (
            <span>
              Now we can use the <b>animation</b> property to apply this{" "}
              animation to our <b>header</b>. We want to set the{" "}
              <b>animation-name</b> property on the <em>alien-container</em> to
              the name of the animation we created.
            </span>
          ),
          block: "animation-name",
          args: {
            TEXT: "move-background",
          },
          // We need to reference the block from the previous part here.
          parentBlock: 8,
        },
        {
          // 20
          title: "Set the animation duration",
          text: (
            <span>
              We can use the <b>animation-duration</b> property to set how long
              we want the animation to last. We can set this to 30 seconds. Feel
              free to change this to any value you want! Try a small number to
              make it go quicker or a large one to make it go slower.
            </span>
          ),
          block: "animation-duration",
          args: {
            SECONDS: 30,
          },
          parentBlock: 8,
        },
        {
          // 21
          title: "Set the number of times to run",
          text: (
            <span>
              We can use the <b>animation-iteration-count</b> property to set
              how many times we want the animation to run. We can set this to 10
              to make the animation run ten times.
            </span>
          ),
          block: "animation-iteration-count",
          args: {
            INTEGER: 10,
          },
          parentBlock: 8,
        },
      ],
    },
  ],
};

export const IMAGE_CAROUSEL_STEPS = {
  title: "Image Carousel",
  sections: [
    {
      title: "Add an image",
      steps: [
        {
          // 0
          title: "What is an image carousel?",
          text: (
            <span>
              An image carousel is an interactive slideshow of images on a
              webpage. It's a great way to show off multiple images on a single
              page without overcrowding the page.
            </span>
          ),
          expand: HTML,
        },
        {
          // 1
          title: "Create the container",
          text: (
            <span>
              We'll start by creating a <b>div</b> to hold our images. We can
              use the <b>class name</b> <b>attribute</b> to give it a name so
              that we can reference it in our <b>CSS</b>. In this case, we can
              name it <em>image-carousel</em>.
            </span>
          ),
          block: "div",
          args: {
            CLASS: "image-carousel",
          },
        },
        {
          // 2
          title: "Add the images",
          text: (
            <span>
              To start, we'll only add one <b>img</b> tag to the <b>HTML</b>. We
              will then use <b>JavaScript</b> to rotate through the images{" "}
              <b>programmatically</b>. You'll notice that we're using a
              placeholder image for now. We'll replace this with our own images
              later in this lesson.
            </span>
          ),
          block: "add_image",
          args: {
            SRC: "https://i.stack.imgur.com/mwFzF.png",
            WIDTH: 250,
            HEIGHT: 250,
            CLASS: "image",
          },
          parentBlock: 1,
          expand: CSS,
        },
        {
          // 3
          title: "Add the image container selector",
          text: (
            <span>
              In order to add <b>CSS styles</b> to our <b>HTML</b>, we need a
              way to reference them. We can use a selector to reference the{" "}
              <b>image-carousel</b> class.
            </span>
          ),
          block: "style_classes",
          args: {
            VALUE: "image-carousel",
          },
        },
        {
          // 4
          title: "Style the image container",
          text: (
            <span>
              We can use the <b>display</b> property set to <b>flex</b> to allow
              our container <b>div</b> to be flexibly sized. This will allow us
              to center our image in the next step.
            </span>
          ),
          block: "display",
          args: {
            VALUE: "flex",
          },
          parentBlock: 3,
        },
        {
          // 5
          title: "Position to center",
          text: (
            <span>
              We can use the <b>justify-content</b> property to center the image
              horizontally. We can set this to center.
            </span>
          ),
          block: "justify-content",
          args: {
            VALUE: "center",
          },
          parentBlock: 3,
        },
        {
          // 6
          title: "Position to center",
          text: (
            <span>
              Now that we've centered the content horizontally, we need to
              center it vertically. We can use the <b>align-items</b> property
              to center the image top to bottom.
            </span>
          ),
          block: "align-items",
          args: {
            VALUE: "center",
          },
          parentBlock: 3,
        },
      ],
    },
    {
      title: "Add styling",
      steps: [
        {
          // 7
          title: "Style the image",
          text: (
            <span>
              We can now add a <b>selector</b> to the image class to change the
              look and feel of the <b>element</b> containing the image.
            </span>
          ),
          block: "style_classes",
          args: {
            VALUE: "image",
          },
        },
        {
          // 8
          title: "Give the image a border radius",
          text: (
            <span>
              We can use the <b>border-radius</b> property to give the image a
              rounded border. We can set this to 50 but are free to change it
              however we'd like.
            </span>
          ),
          block: "border-radius",
          args: {
            PX: 50,
          },
          parentBlock: 7,
        },
        {
          // 9
          title: "Add a box shadow",
          text: (
            <span>
              We can use the <b>box-shadow</b> property to add a shadow to the
              image. This gives it the appearance of being raised off the page.
            </span>
          ),
          block: "box-shadow",
          args: {
            PX: 5,
            PX1: 5,
            PX2: 5,
            COLOR: "#c0c0c0",
          },
          parentBlock: 7,
        },
      ],
    },
  ],
};
