import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const LOAD_PEN_BY_NAME_AND_ID = gql`
  query loadPenById($penId: Int!) {
    loadPenById(penId: $penId) {
      id
      title
      html
      css
      js
      document
      isCurrentUser
    }
  }
`;

const DisplayOnly = () => {
  const [code, setCode] = useState("");
  const params = useParams();
  const { data } = useQuery(LOAD_PEN_BY_NAME_AND_ID, {
    variables: { penId: parseInt(params.id) },
    skip: !params.id,
  });

  useEffect(() => {
    if (data?.loadPenById) {
      const { document } = data.loadPenById;

      setCode(document);
    }
  }, [data?.loadPenById]);

  const { id } = data?.loadPenById || {};

  return (
    <>
      <Link to={`/projects/edit/${id}`}>
        <div class="tag topright" />
      </Link>
      {code ? (
        <iframe title="display" className="full-screen" srcDoc={code} />
      ) : (
        ""
      )}
    </>
  );
};

export default DisplayOnly;
