/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */
import {
  addTextCallback,
  addAlertCallback,
  openLinkCallback,
  elementValueCallback,
  elementByClassNameCallback,
  setElementCallback,
  storeDataCallback,
  getDataCallback,
  addConsoleLogCallback,
  getCanvasCallback,
  drawLineCallback,
  setElementStyleCallback,
  addEventListenerCallback,
} from "../generator/javascript";
import { blocks as cssBlocks } from "./css";

// Create a custom block called 'add_text' that adds
// text to the output div on the sample app.
// This is just an example and you should replace this with your
// own custom blocks.
export const addText = {
  type: "add_text",
  message0: "Add text %1 with color %2",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
    {
      type: "input_value",
      name: "COLOR",
      check: "Colour",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  tooltip: "",
  helpUrl: "",
  generator: addTextCallback,
};

export const addAlert = {
  type: "alert",
  message0: "Alert %1",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  generator: addAlertCallback,
};

export const addConsoleLog = {
  type: "console_log",
  message0: "Console log %1",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  generator: addConsoleLogCallback,
};

export const addLink = {
  type: "link",
  message0: "Open link %1",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  generator: openLinkCallback,
};

export const setElementStyle = {
  type: "set_element_style",
  message0: "Set element %1 style to %2",
  args0: [
    {
      type: "input_value",
      name: "ELEMENT",
      check: "String",
    },
    {
      type: "input_value",
      name: "STYLE",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  generator: setElementStyleCallback,
};

export const elementValueBlock = {
  type: "element_value",
  message0: "Get value of input by class name %1",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  generator: elementValueCallback,
  output: "String",
};

export const elementBlock = {
  type: "element_by_class_name",
  message0: "Get element with class name %1",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 219,
  generator: elementByClassNameCallback,
  output: "String",
};

export const setElementProperty = {
  type: "set_element_property",
  message0: "Set element %1 %2 to %3 ",
  args0: [
    {
      type: "input_value",
      name: "ELEMENT",
      check: "String",
    },
    {
      type: "field_dropdown",
      name: "PROPERTY_TYPE",
      options: [["src", "src"]],
    },
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  generator: setElementCallback,
};

// export const setDatabase = {
//   type: "database_set",
//   message0: "Store %1 in %2 database",
//   args0: [
//     {
//       type: "input_value",
//       name: "TEXT",
//       check: "String",
//     },
//     {
//       type: "input_value",
//       name: "DB_NAME",
//       check: "String",
//     },
//   ],
//   previousStatement: null,
//   nextStatement: null,
//   colour: 50,
//   generator: storeDataCallback,
// };

// export const getDatabase = {
//   type: "database_get",
//   message0: "Get data from %1 database",
//   args0: [
//     {
//       type: "input_value",
//       name: "DB_NAME",
//       check: "String",
//     },
//   ],
//   previousStatement: null,
//   nextStatement: null,
//   colour: 50,
//   generator: getDataCallback,
// };

export const getItemFromDatabase = {
  type: "database_get",
  message0: "Get values from %1 in database",
  args0: [
    {
      type: "field_variable",
      name: "VAR",
      variable: "%{BKY_VARIABLES_DEFAULT_NAME}",
      variableTypes: ["String"], // Specifies what types to put in the dropdown
      defaultType: "String",
    },
  ],
  output: "String",
  colour: 50,
  generator: getDataCallback,
};

export const setItemToDatabase = {
  type: "database_set",
  message0: "%{BKY_VARIABLES_SET} database",
  args0: [
    {
      type: "input_value",
      name: "VALUE",
      check: "String",
    },
    {
      type: "field_variable",
      name: "VAR",
      variable: "%{BKY_VARIABLES_DEFAULT_NAME}",
      variableTypes: ["String"],
      defaultType: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 50,
  generator: storeDataCallback,
};

export const getCanvas = {
  type: "get_canvas",
  message0: "Canvas %1 %2",
  args0: [
    {
      type: "input_value",
      name: "ID",
      check: "String",
    },
    { type: "input_statement", name: "BODY" },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 570,
  generator: getCanvasCallback,
};

export const drawLine = {
  type: "draw_line",
  message0: "Draw line x1 %1 y1 %2 x2 %3 y2 %4 color %5 stroke width %6",
  args0: [
    {
      type: "input_value",
      name: "X1",
      check: "Number",
    },
    {
      type: "input_value",
      name: "Y1",
      check: "Number",
    },
    {
      type: "input_value",
      name: "X2",
      check: "Number",
    },
    {
      type: "input_value",
      name: "Y2",
      check: "Number",
    },
    {
      type: "input_value",
      name: "COLOR",
      check: "Colour",
    },
    {
      type: "input_value",
      name: "LINE_WIDTH",
      check: "Number",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 570,
  generator: drawLineCallback,
};

// Create the block definitions for the JSON-only blocks.
// This does not register their definitions with Blockly.
// This file has no side effects!

export const styleBlocks = cssBlocks.map((block) => ({
  ...block,
  type: `js_${block.type}`,
  output: "String",
}));

export const addEventListener = {
  type: "add_event_listener",
  message0: "Add event listener to element %1 on event %2 call function %3",
  args0: [
    {
      type: "input_value",
      name: "ELEMENT",
      check: "String",
    },
    {
      type: "field_dropdown",
      name: "TYPE",
      options: [
        ["click", "click"],
        ["change", "change"],
        ["blur", "blur"],
        ["mouseover", "mouseover"],
        ["mouseout", "mouseout"],
        ["mousemove", "mousemove"],
        ["keypress", "keypress"],
      ],
    },
    {
      type: "input_statement",
      name: "CALLBACK",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  generator: addEventListenerCallback,
};

export const blocks = [
  ...styleBlocks,
  addText,
  addAlert,
  addLink,
  elementValueBlock,
  elementBlock,
  setElementProperty,
  setItemToDatabase,
  getItemFromDatabase,
  addConsoleLog,
  getCanvas,
  drawLine,
  setElementStyle,
  addEventListener,
];
