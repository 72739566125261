import * as Blockly from "blockly/core";

import { blocks as htmlBlocks } from "./html";
import { blocks as jsBlocks } from "./javascript";
import { blocks as cssBlocks } from "./css";

export const blocks = Blockly.common.createBlockDefinitionsFromJsonArray([
  ...htmlBlocks,
  ...jsBlocks,
  ...cssBlocks,
]);
