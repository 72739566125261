import HtmlIcon from "@mui/icons-material/Html";
import CssIcon from "@mui/icons-material/Css";
import JavascriptIcon from "@mui/icons-material/Javascript";
import CodeIcon from "@mui/icons-material/Code";
import WebIcon from "@mui/icons-material/Web";
import { HTML, CSS, JS } from "../constants/types";

const CODE = "code";
const WEB = "web";

export const TOOLTIP_ICONS = {
  [HTML]: { Icon: HtmlIcon, color: "#E34F26" },
  [CSS]: { Icon: CssIcon, color: "#1572B6" },
  [JS]: { Icon: JavascriptIcon, color: "#1572B6" },
  [CODE]: { Icon: CodeIcon, color: "#1572B6" },
  [WEB]: { Icon: WebIcon, color: "#1572B6" },
};

export const TOOLTIPS = {
  div: {
    text: "Invisible containers that hold content on a webpage",
    category: HTML,
  },
  HTML: {
    text: "The skeleton of a webpage that determines how content is structured",
    category: HTML,
  },
  CSS: {
    text: "A programming language that's used to determine the look and feel of a webpage.",
    category: CSS,
  },
  selector: {
    text: "Points to the specific element that we want to style",
    category: CSS,
  },
  class: {
    text: "A name to give an element so that we can style it with CSS",
    category: CSS,
  },
  "class name": {
    text: "A name to give an element so that we can style it with CSS",
    category: CSS,
  },
  display: {
    text: "Determines how an element is displayed on the page",
    category: CSS,
  },
  flex: {
    text: "Flex is a layout mode that allows us to align items how we want on the page",
    category: CSS,
  },
  "justify-content": {
    text: "The left to right position of an element on the page",
    category: CSS,
  },
  "landing page": {
    text: "A page that welcomes visitors to a website",
    category: HTML,
  },
  color: {
    text: "CSS property that changes the color of the text on a webpage",
    category: CSS,
  },
  header: {
    text: "A section at the top of a webpage that contains a title",
    category: HTML,
  },
  "font-size": {
    text: "The size of the text on a webpage",
    category: CSS,
  },
  "font-family": {
    text: "The type & style of font that is used on a webpage. Think Times New Roman!",
    category: CSS,
  },
  "background-image": {
    text: "The image that is displayed as the background of a specific HTML element",
    category: CSS,
  },
  animation: {
    text: "A CSS property that allows us to change the appearance of an element over time",
    category: CSS,
  },
  "CSS properties": {
    text: "A CSS property is a specific way to style an element. For example, the color property changes the color of text.",
    category: CSS,
  },
  keyframes: {
    text: "A specific point in time during an animation",
    category: CSS,
  },
  from: {
    text: "The starting point or keyframe of an animation",
    category: CSS,
  },
  to: {
    text: "The ending point or keyframe of an animation",
    category: CSS,
  },
  "animation-name": {
    text: "The name of the animation that we want to use",
    category: CSS,
  },
  "animation-duration": {
    text: "The length of time in seconds that an animation takes to complete",
    category: CSS,
  },
  "animation-iteration-count": {
    text: "The number of times that an animation repeats",
    category: CSS,
  },
  "align-items": {
    text: "The top to bottom position of an element on the page in a flex container",
    category: CSS,
  },
  "background-position": {
    text: "The position of the background image on a webpage",
    category: CSS,
  },
  attribute: {
    text: "A piece of information that is attached to an HTML element",
    category: HTML,
  },
  web: {
    text: "A network of information that is accessed through the internet",
    category: HTML,
  },
  "web applications": {
    text: "A program that allows people to interact and save information to access later.",
    category: HTML,
  },
  JS: {
    text: "A programming language that allows us to add interactivity to a webpage",
    category: JS,
  },
  elements: {
    text: "The building blocks of a webpage",
    category: HTML,
  },
  "programming language": {
    text: "A language that allows us to give instructions to a computer",
    category: CODE,
  },
  syntax: {
    text: "The grammar and spelling of a programming language",
    category: CODE,
  },
  "user interface": {
    text: "The visual elements of a program that a user interacts with",
    category: CODE,
  },
  test: {
    text: "The process of checking if a game or program works as expected",
    category: CODE,
  },
  troubleshoot: {
    text: "The process of finding and fixing problems in a program",
    category: CODE,
  },
  "call to action": {
    text: "A button or link that prompts a user to take an action",
    category: WEB,
  },
  JavaScript: {
    text: "A programming language that allows us to add interactivity to a webpage",
    category: JS,
  },
  img: {
    text: "An HTML element that displays an image on a webpage",
    category: HTML,
  },
  programmatically: {
    text: "A fancy way of saying that we will let the computer do the work for us",
    category: JS,
  },
  "CSS styles": {
    text: "This is a way of altering the look and feel of HTML elements on a webpage",
    category: CSS,
  },
  "border-radius": {
    text: "The degree to which the corners of an element are rounded",
    category: CSS,
  },
  "box-shadow": {
    text: "A CSS property that allows us to add a shadow to an element",
    category: CSS,
  },
};
