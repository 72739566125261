export const connectAllTopLevelBlocks = (workspace) => {
  // Get all top-level blocks in the workspace
  const topLevelBlocks = workspace.getTopBlocks().reverse();

  // Iterate through the top-level blocks, connecting each one to the next
  for (let i = 0; i < topLevelBlocks.length - 1; i++) {
    const currentBlock = topLevelBlocks[i];
    const nextBlock = topLevelBlocks[i + 1];

    // Find the first available connection on the current block
    const currentBlockConnection = currentBlock.nextConnection;

    // Find the first available connection on the next block
    const nextBlockConnection = nextBlock.previousConnection;

    // Connect the blocks if both connections are available
    if (currentBlockConnection && nextBlockConnection) {
      currentBlockConnection.connect(nextBlockConnection);
    }
  }
};
