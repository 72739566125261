import {
  addDiv,
  addHeader,
  addImage,
  addInput,
  addParagraph,
  addSpan,
  addButton,
} from "../blocks/html";

import {
  createClassSelectorBlock,
  createClassStyleBlock,
  createMultipartBlock,
  STYLE_TYPES,
  createTagSelectorBlock,
} from ".";
import { getBlockOffset } from "../components/lessons/HowTo";
import { connectAllTopLevelBlocks } from "./connections";

const htmlTagsToBlocks = {
  div: addDiv,
  h1: addHeader,
  h2: addHeader,
  h3: addHeader,
  h4: addHeader,
  h5: addHeader,
  h6: addHeader,
  img: addImage,
  input: addInput,
  p: addParagraph,
  span: addSpan,
  button: addButton,
};

// border
// box-shadow
const cssStylesToBlocks = {
  color: { blockType: "color", type: STYLE_TYPES.COLOR },
  "background-color": {
    blockType: "background-color",
    type: STYLE_TYPES.COLOR,
  },
  "background-image": { blockType: "background-image", type: STYLE_TYPES.TEXT },
  "background-size": {
    blockType: "background-size",
    type: STYLE_TYPES.DROPDOWN,
  },
  "background-repeat": {
    blockType: "background-repeat",
    type: STYLE_TYPES.DROPDOWN,
  },
  "border-radius": { blockType: "border-radius", type: STYLE_TYPES.NUMBER },
  width: { blockType: "width", type: STYLE_TYPES.NUMBER },
  height: { blockType: "height", type: STYLE_TYPES.NUMBER },
  "font-size": { blockType: "font-size", type: STYLE_TYPES.NUMBER },
  margin: { blockType: "margin", type: STYLE_TYPES.NUMBER },
  "margin-top": { blockType: "margin-top", type: STYLE_TYPES.NUMBER },
  "margin-bottom": { blockType: "margin-bottom", type: STYLE_TYPES.NUMBER },
  "margin-left": { blockType: "margin-left", type: STYLE_TYPES.NUMBER },
  "margin-right": { blockType: "margin-right", type: STYLE_TYPES.NUMBER },
  padding: { blockType: "padding", type: STYLE_TYPES.NUMBER },
  "padding-top": { blockType: "padding-top", type: STYLE_TYPES.NUMBER },
  "padding-bottom": { blockType: "padding-bottom", type: STYLE_TYPES.NUMBER },
  "padding-left": { blockType: "padding-left", type: STYLE_TYPES.NUMBER },
  "padding-right": { blockType: "padding-right", type: STYLE_TYPES.NUMBER },
  "font-family": { blockType: "font-family", type: STYLE_TYPES.DROPDOWN },
  "text-align": { blockType: "text-align", type: STYLE_TYPES.DROPDOWN },
  display: { blockType: "display", type: STYLE_TYPES.DROPDOWN },
  "flex-direction": { blockType: "flex-direction", type: STYLE_TYPES.DROPDOWN },
  "justify-content": {
    blockType: "justify-content",
    type: STYLE_TYPES.DROPDOWN,
  },
  "align-items": { blockType: "align-items", type: STYLE_TYPES.DROPDOWN },
  "box-shadow": { blockType: "align-items", type: STYLE_TYPES.MULTIPART },
};

// .hero {
//     color: red;
//     background-image: url(https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png);
//     background-color: blue;
//     background-size: cover;
//     background-repeat: no-repeat;
//     border-radius: 10px;
//     width: 100px;
//     height: 100px;
//     font-size: 20px;
//     margin: 10px;
//     margin-top: 10px;
//     margin-bottom: 10px;
//     margin-left: 10px;
//     margin-right: 10px;
//     padding: 10px;
//     padding-top: 10px;
//     padding-bottom: 10px;
//     padding-left: 10px;
//     padding-right: 10px;
//     font-family: 'Courier New', monospace;
//     text-align: center;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     box-shadow: 10px 10px 5px #888888;
// }

const createHtmlElement = (
  htmlBlockly,
  tagName,
  className,
  innerText,
  width = 0,
  height = 0,
  src = ""
) => {
  const block = htmlTagsToBlocks[tagName];
  const element = htmlBlockly.newBlock(block.type);
  element.initSvg();
  element.render();

  block.args0.forEach((arg, index) => {
    if (arg.type === "input_value") {
      let value = "";
      if (arg.name === "CLASS") {
        value = className;
      } else if (arg.name === "TEXT") {
        value = innerText;
      } else if (arg.name === "WIDTH") {
        value = width;
      } else if (arg.name === "HEIGHT") {
        value = height;
      } else if (arg.name === "IMAGE_URL") {
        value = src;
      }

      if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(tagName)) {
        element.setFieldValue(tagName, "HEADER_SIZE");
      }

      let argType = "TEXT";
      let inputBlock = htmlBlockly.newBlock("text");
      if (arg.check === "Number") {
        inputBlock = htmlBlockly.newBlock("math_number");
        argType = "NUM";
      }

      inputBlock.setFieldValue(value, argType);
      inputBlock.initSvg();
      inputBlock.render();
      inputBlock.setColour("#e56a6a");

      inputBlock.outputConnection.connect(element.inputList[index].connection);
    }

    if (arg.type === "field_dropdown") {
      const argValue = "test";
      element.setFieldValue(argValue, arg.name);
    }
  });

  //   const classInputBlock = htmlBlockly.newBlock("text");
  //   classInputBlock.setFieldValue(className, "TEXT");
  //   classInputBlock.initSvg();
  //   classInputBlock.render();
  //   classInputBlock.setColour("#e56a6a");

  //   classInputBlock.outputConnection.connect(element.inputList[0].connection);

  return element;
};

export const generateHTMLBlocksFromCode = (html, htmlBlockly) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const body = doc.body;

  function traverseDomTree(node, parentBlock) {
    var i, child;

    let block = "";

    if (node.nodeType !== Node.ELEMENT_NODE) {
      return;
    }

    try {
      block = createHtmlElement(
        htmlBlockly,
        node.tagName.toLowerCase(),
        node.className,
        node.innerText || node.placeholder,
        node.width,
        node.height,
        node.src
      );
    } catch (error) {
      // console.log(node, error);
    }

    if (parentBlock && block) {
      parentBlock.inputList[1].connection.connect(block.previousConnection);
    }

    for (i = node.childNodes.length - 1; i >= 0; i--) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        child = node.childNodes[i];
        traverseDomTree(child, block);
      }

      // TODO: Text nodes could be converted to paragraphs, etc.
    }
  }

  traverseDomTree(body);

  connectAllTopLevelBlocks(htmlBlockly);

  return body;
};

function parseCSSSelectors(cssString) {
  const pattern =
    /(?:[.#]?[a-zA-Z0-9-_]+|\:[a-z-]+)+(?:\s*,\s*(?:[.#]?[a-zA-Z0-9-_]+|\:[a-z-]+))*\s*{[^}]*}/g;
  const selectors = cssString.match(pattern);

  return selectors;
}

export function parseCSS(css) {
  const styles = [];

  const selectors = parseCSSSelectors(css);

  selectors.forEach((block, index) => {
    const [selector, ...declarations] = block.split("{");
    if (!declarations.length) return;

    let type = "class";
    let name = selector.slice(1);
    let pseudoClassName = "";

    if (selector.includes("::")) {
      type = "pseudoClass";
      name = selector.slice(1, selector.indexOf("::"));
      pseudoClassName = selector.slice(selector.indexOf("::"));
    } else if (selector.includes(":")) {
      type = "pseudoClass";
      name = selector.slice(1, selector.indexOf(":"));
      pseudoClassName = selector.slice(selector.indexOf(":"));
    } else if (selector[0] === ".") {
      type = "class";
    } else if (selector[0] === "#") {
      type = "id";
    } else {
      type = "tag";
      name = selector;
    }

    const properties = declarations[0]
      .split("}")[0]
      .split(";")
      .reduce((obj, decl) => {
        if (!decl) return obj;
        const propertyIndex = decl.indexOf(":");
        // const [property, value] = decl.split(":");
        const property = decl.slice(0, propertyIndex);
        const value = decl.slice(propertyIndex + 1);
        if (!property || !value) return obj;
        obj[property.trim()] = value.trim();
        return obj;
      }, {});

    styles.push({
      type,
      name,
      properties,
      pseudoClass: pseudoClassName,
    });

    // Remove the parsed selector and declarations from the CSS string
    css = css.replace(selector + "{", "");
  });

  return styles;
}

export const generateCSSBlocksFromCode = async (
  css,
  cssBlockly,
  addClassOption
) => {
  const styles = parseCSS(css);
  const selectorBlocks = [];
  const blocks = {};

  await styles.forEach(async (style) => {
    const selectorName = style.name.trim();
    const randomNumber = Math.floor(Math.random() * 1000000);
    const id = `${selectorName}-${randomNumber}`;
    const pseudoClass = style.pseudoClass;

    await addClassOption(selectorName, id);
    blocks[selectorName] = { id };

    if (pseudoClass) blocks[selectorName]["pseudoClass"] = pseudoClass;
  });

  styles.forEach(async (style) => {
    const selectorName = style.name.trim();
    const selectorType = style.type;
    const properties = style.properties;

    const id = blocks[selectorName].id;
    const pseudoClassName = blocks[selectorName].pseudoClass;

    let selectorBlock = "";
    if (selectorType === "class") {
      selectorBlock = createClassSelectorBlock(
        cssBlockly,
        {
          CLASS: selectorName,
        },
        id
      );

      selectorBlocks.push({ block: selectorBlock, className: selectorName });
    } else if (selectorType === "pseudoClass") {
      selectorBlock = createClassSelectorBlock(
        cssBlockly,
        {
          CLASS: selectorName,
          PSEUDO: pseudoClassName,
        },
        id,
        "style_classes_pseudo"
      );

      selectorBlocks.push({ block: selectorBlock, className: selectorName });
    } else if (selectorType === "tag") {
      selectorBlock = createTagSelectorBlock(
        cssBlockly,
        {
          TAG: selectorName,
        },
        id
      );

      selectorBlocks.push({ block: selectorBlock, className: selectorName });
    }

    const offset = getBlockOffset({ CSS: cssBlockly }, selectorBlock, "CSS");
    if (offset) {
      selectorBlock.moveBy(offset, 0);
    }

    Object.keys(properties).forEach((property) => {
      let value = properties[property];
      const block = cssStylesToBlocks[property];

      if (!block) return;

      const { blockType, type } = cssStylesToBlocks[property];

      let dropdownName = "VALUE";

      if (type === STYLE_TYPES.NUMBER) {
        if (value.includes("px")) {
          value = value.split("px")[0];
        }

        value = parseInt(value);
      }

      if (type === STYLE_TYPES.TEXT) {
        if (value.includes("'") || value.includes('"')) {
          value = value.replace(/'/g, "");
          value = value.replace(/"/g, "");
        }
      }

      if (property === "background-image") {
        value = value.split("url(")[1].split(")")[0];
      }

      if (property === "font-family") {
        dropdownName = "FONT";
      }

      if (property === "text-align") {
        dropdownName = "ALIGN";
      }

      if (property.includes("box-shadow")) {
        const values = value.split(" ");
        const offsetX = values[0].split("px")[0];
        const offsetY = values[1].split("px")[0];
        const blurRadius = values[2].split("px")[0];
        const color = values[3];
        createMultipartBlock(
          cssBlockly,
          selectorBlock,
          offsetX,
          offsetY,
          blurRadius,
          color
        );
      } else {
        let connectionIndex = 1;
        if (selectorBlock.type === "style_classes_pseudo") {
          connectionIndex = 2;
        }
        createClassStyleBlock(
          cssBlockly,
          selectorBlock,
          blockType,
          value,
          type,
          dropdownName,
          connectionIndex
        );
      }
    });
  });
};
