import React from "react";

const UNFINISHED_BORDER_COLOR = "5px dotted rgba(83, 85, 96, 0.5)";
const UNFINISHED_BORDER_CIRCLE_COLOR = "5px solid rgba(83, 85, 96, 0.35)";
const FINISHED_BORDER_COLOR = "5px solid #535560";
const UNFINISHED_BORDER_CONNECTOR_COLOR = "5px dotted rgba(83, 85, 96, 0.5)";
const FINISHED_BORDER_CONNECTOR_COLOR = "5px dotted #535560";

const HowToOverview = ({
  selectedLesson,
  closeModal,
  setSelectedSection,
  selectedSection,
}) => {
  const sections = selectedLesson.sections;
  return (
    <div
      className="full-page-modal"
      onClick={() => {
        console.log("closing modal");
        closeModal();
      }}
    >
      <div
        className="how-to-overview-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="header-container"
          // TODO: Make this background color dynamic
          style={{ backgroundColor: "rgb(119 152 64 / 77%)" }}
        >
          <h1 className="lesson-title">{selectedLesson.title}</h1>
          <span className="close" onClick={closeModal}></span>
        </div>
        <ul>
          {sections.map((section, i) => (
            <li
              className={selectedSection === i ? "active" : ""}
              onClick={() => {
                setSelectedSection(i);
              }}
              style={{
                "--border-left-color":
                  selectedSection - 1 >= i
                    ? FINISHED_BORDER_COLOR
                    : UNFINISHED_BORDER_COLOR,
                "--border-circle-color":
                  selectedSection >= i
                    ? FINISHED_BORDER_COLOR
                    : UNFINISHED_BORDER_CIRCLE_COLOR,
                "--border-connector-color":
                  selectedSection >= i
                    ? FINISHED_BORDER_CONNECTOR_COLOR
                    : UNFINISHED_BORDER_CONNECTOR_COLOR,
              }}
            >
              <p
                style={
                  selectedSection === i
                    ? { boxShadow: "0 0 25px rgb(255 255 255 / 78%)" }
                    : {}
                }
              >
                {section.title}
              </p>
            </li>
          ))}
        </ul>
        <div className="bottom">
          <div
            className="step"
            style={{ background: "rgb(119 152 64 / 77%)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default HowToOverview;
