import React, { useEffect, useState } from "react";
import { TOOLTIPS, TOOLTIP_ICONS } from "../../constants/tooltips";
import "../../styles/HowTo.css";

const LearnTooltip = ({ type, top, left, closeTooltip }) => {
  const [calculatedTop, setCalculatedTop] = useState(0);
  const [calculatedLeft, setCalculatedLeft] = useState(0);

  const { text, category } = TOOLTIPS?.[type];
  const { Icon } = TOOLTIP_ICONS?.[category];

  useEffect(() => {
    const learnTooltip = document.querySelector(".learn-tooltip");
    const { height, width } = learnTooltip.getBoundingClientRect();

    if (learnTooltip) {
      setCalculatedTop(top - height / 2 - 55);
      setCalculatedLeft(left - width / 2);
    }
  }, [type]);

  if (!text) return <div />;

  return (
    <div className="wrapper" onClick={closeTooltip} key={type}>
      <div
        className="learn-tooltip"
        style={{ top: calculatedTop, left: calculatedLeft }}
      >
        <span className="learn-tooltip-icon">{!!Icon && <Icon small />}</span>
        <span style={{ marginLeft: 5 }}>{text}</span>
      </div>
    </div>
  );
};

export default LearnTooltip;
