import React from "react";

const SavePen = ({ isOpen, savePen, closeModal }) => {
  const [penName, setPenName] = React.useState("");

  return isOpen ? (
    <div id="pens-container" onClick={closeModal}>
      <div className="pens-list">
        <input
          className="auth-input"
          type="text"
          placeholder="My awesome pen"
          onChange={(e) => setPenName(e.target.value)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <button
          className="auth-button"
          onClick={(e) => {
            savePen(penName);
          }}
          style={{ marginLeft: "1rem" }}
        >
          Save Pen
        </button>
      </div>
    </div>
  ) : (
    ""
  );
};

export default SavePen;
