import React, { useEffect } from "react";
import "../../styles/Homepage.css";
import { Helmet } from "react-helmet-async";
import Footer from "../Footer";

// TODO: Proper h1 and other description / info down below
const HomePage = () => {
  useEffect(() => {
    const elts = {
      text1: document.getElementById("text1"),
      text2: document.getElementById("text2"),
      morph: document.getElementById("svg-morph"),
    };

    const texts = [
      "Make Games",
      "Learn Code",
      "Build Websites",
      "Share Creations",
    ];

    const morphTime = 2;
    const cooldownTime = 1;

    let textIndex = texts.length - 1;
    let time = new Date();
    let morph = 0;
    let cooldown = cooldownTime;

    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];

    function doMorph() {
      morph -= cooldown;
      cooldown = 0;

      let fraction = morph / morphTime;

      if (fraction > 1) {
        cooldown = cooldownTime;
        fraction = 1;
      }

      setMorph(fraction);
    }

    function setMorph(fraction) {
      elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      fraction = 1 - fraction;
      elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      elts.text1.textContent = texts[textIndex % texts.length];
      elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    }

    function doCooldown() {
      morph = 0;

      elts.text2.style.filter = "";
      elts.text2.style.opacity = "100%";

      elts.text1.style.filter = "";
      elts.text1.style.opacity = "0%";
    }

    function animate() {
      requestAnimationFrame(animate);

      let newTime = new Date();
      let shouldIncrementIndex = cooldown > 0;
      let dt = (newTime - time) / 1000;
      time = newTime;

      cooldown -= dt;

      if (cooldown <= 0) {
        if (shouldIncrementIndex) {
          textIndex++;
        }

        doMorph();
      } else {
        doCooldown();
      }
    }

    animate();
  }, []);

  //   const colors = ["#FFC1AD", "#3EC6A2", "#FEE25A", "#E7A200"];
  return (
    <>
      <Helmet>
        <title>Blockpen | Learn to Code</title>
        <meta
          name="description"
          content="Learn to code with Blockpen, a block-based programming tool like Scratch to teach the basics of web development."
        />
        <meta
          name="og:description"
          content="Learn to code with Blockpen, a block-based programming tool like Scratch to teach the basics of web development."
        />
      </Helmet>
      <main id="homepage-container">
        <div className="blog-hero">
          <div
            className="hero-content"
            style={{ columnGap: 0, alignItems: "starts" }}
          >
            <span className="homepage-graphic">
              <img
                src="https://cdn.discordapp.com/attachments/1010577922941263892/1104097340513599498/PNG_image_25.PNG"
                alt="hero"
                height="360"
                width="300"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="250"
                height="350"
                fill="none"
                viewBox="0 0 923 1228"
                style={{
                  position: "absolute",
                  zIndex: -1,
                  transform: "translate(-125%, 0)",
                }}
              >
                <g>
                  <path
                    fill="#E7A200"
                    d="M888.718 318.273c-143.426-528.756-982.647-305.536-860.898 0 121.748 305.535 206.564 323.5 0 706.127-206.565 382.62 803.661 131.15 842.71 8.16 39.049-122.992 85.84-464.882 18.188-714.287Z"
                  />
                </g>
              </svg>
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="600"
                height="600"
                fill="none"
                viewBox="0 0 500 500"
                style={{
                  position: "absolute",
                  zIndex: -1,
                  transform: "translate(10%, -10%)",
                }}
              >
                <g>
                  <path
                    id="svg-morph"
                    fill="#E7A200"
                    d="M52.359 252.959c202.32-420.249 1386.141-242.836 1214.401 0s-291.386 257.115 0 561.219c291.38 304.102-1133.662 104.241-1188.745 6.488C22.932 722.914-43.072 451.184 52.36 252.959Z"
                  >
                    <animate
                      id="animation-to-check"
                      repeatCount="indefinite"
                      fill="freeze"
                      attributeName="d"
                      dur="10s"
                      values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45
                        c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2
                        c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7
                        c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z;
                        
                        M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4
                        c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6
                        c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8
                        C48.9,198.6,57.8,191,51,171.3z;
                        
                        M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45
                        c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2
                        c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7
                        c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z"
                    />
                  </path>
                </g>
                <defs>
                  <filter
                    id="a"
                    width="1500"
                    height="1500"
                    x="0"
                    y="0"
                    color-interpolation-filters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      result="hardAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="25" dy="25" />
                    <feGaussianBlur stdDeviation="12.5" />
                    <feComposite
                      in2="hardAlpha"
                      k2="-1"
                      k3="1"
                      operator="arithmetic"
                    />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_872" />
                  </filter>
                </defs>
              </svg>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <div id="container">
                  <span id="text1"></span>
                  <span id="text2"></span>
                </div>
                <svg id="filters">
                  <defs>
                    <filter id="threshold">
                      <feColorMatrix
                        in="SourceGraphic"
                        type="matrix"
                        values="1 0 0 0 0
									0 1 0 0 0
									0 0 1 0 0
									0 0 0 255 -140"
                      />
                    </filter>
                  </defs>
                </svg>
                {/* <img
                  src="https://media.discordapp.net/attachments/1010577922941263892/1104120431344099348/PNG_image.png"
                  width="100"
                  height="100"
                  style={{
                    margin: "auto",
                  }}
                /> */}
              </span>
            </span>
          </div>
        </div>
        <section className="homepage-info" style={{ marginTop: "10rem" }}>
          <div className="homepage-content">
            <span className="header-info-container">
              <h3
                className="homepage-header-custom large"
                style={{
                  color: "#28323A",
                }}
              >
                Coding is a
              </h3>
              <h3
                className="homepage-header-custom large"
                style={{
                  color: "#33768B",
                }}
              >
                {" "}
                superpower
              </h3>
            </span>
            {/* <span>
                <img
                  src="https://media.discordapp.net/attachments/1010577922941263892/1109222101031858286/PNG_image.PNG"
                  width="90"
                  height="150"
                  style={{ marginRight: "4rem" }}
                />
              </span> */}

            <span style={{ position: "relative", marginLeft: "auto" }}>
              <img
                src="https://media.discordapp.net/attachments/1010577922941263892/1109222101031858286/PNG_image.PNG"
                width="90"
                height="150"
                style={{
                  marginRight: "4rem",
                  marginBottom: 0,
                  position: "absolute",
                  bottom: 0,
                  right: -50,
                  cursor: "pointer",
                }}
              />
              <a className="action-button" href="/projects/new">
                Unlock yours
              </a>
            </span>
          </div>
        </section>
        <section className="homepage-info svg-2" style={{ marginTop: "10rem" }}>
          <div className="homepage-content">
            <span className="header-info-container">
              <h1
                className="homepage-header-custom small"
                style={{
                  color: "#28323A",
                }}
              >
                Build websites and games with
              </h1>
              <h3
                className="homepage-header-custom small"
                style={{
                  color: "#33768B",
                }}
              >
                {" "}
                blocks
              </h3>
            </span>
            <span className="image-action-container">
              <img
                src="https://media.discordapp.net/attachments/1010577922941263892/1109842973505634355/PNG_image_32.PNG"
                width="90"
                height="150"
                style={{
                  marginRight: "4rem",
                  marginBottom: 0,
                  position: "absolute",
                  bottom: 0,
                  right: -50,
                  cursor: "pointer",
                }}
              />
              <a className="action-button" href="/learn">
                Learn how
              </a>
            </span>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default HomePage;
