/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import { STYLES_TO_JS } from "../constants/styles";

export const CSS_BLOCK_TYPES = {
  color: {
    args: ["color"],
  },
  "background-color": {
    args: ["color"],
  },
  "background-image": {
    args: ["text"],
    valueFormatter: (value) => `url(${value})`,
  },
  "background-size": {
    args: ["dropdown"],
    dropdownValue: ["cover", "contain", "auto"],
  },
  "background-repeat": {
    args: ["dropdown"],
    dropdownValue: ["repeat", "no-repeat", "repeat-x", "repeat-y"],
  },
  "border-radius": {
    args: ["px"],
  },
  width: {
    args: ["px"],
  },
  height: {
    args: ["px"],
  },
  "font-size": {
    args: ["px"],
  },
  margin: {
    args: ["px"],
  },
  "margin-left": {
    args: ["px"],
  },
  "margin-right": {
    args: ["px"],
  },
  "margin-top": {
    args: ["px"],
  },
  "margin-bottom": {
    args: ["px"],
  },
  padding: {
    args: ["px"],
  },
  "padding-left": {
    args: ["px"],
  },
  "padding-right": {
    args: ["px"],
  },
  "padding-top": {
    args: ["px"],
  },
  "padding-bottom": {
    args: ["px"],
  },
  "text-align": {
    args: ["dropdown"],
    dropdownValue: ["left", "center", "right"],
  },
  display: {
    args: ["dropdown"],
    dropdownValue: ["flex", "block", "inline-block", "none"],
  },
  "flex-direction": {
    args: ["dropdown"],
    dropdownValue: ["row", "column"],
  },
  "justify-content": {
    args: ["dropdown"],
    dropdownValue: [
      "flex-start",
      "center",
      "flex-end",
      "space-between",
      "space-around",
      "space-evenly",
    ],
  },
  "align-items": {
    args: ["dropdown"],
    dropdownValue: ["flex-start", "center", "flex-end", "stretch", "baseline"],
  },
  "font-family": {
    args: ["dropdown"],
    dropdownValue: [
      ["Arial", "Arial, sans-serif"],
      ["Times New Roman", "Times New Roman, serif"],
      ["Verdana", "Verdana, sans-serif"],
      ["Georgia", "Georgia, serif"],
      ["Helvetica", "Helvetica, sans-serif"],
      ["Courier New", "Courier New, monospace"],
      ["Comic Sans MS", "Comic Sans MS, cursive"],
      ["Trebuchet MS", "Trebuchet MS, sans-serif"],
      ["Arial Black", "Arial Black, sans-serif"],
      ["Impact", "Impact, sans-serif"],
      ["Tahoma", "Tahoma, sans-serif"],
      ["Lucida Sans Unicode", "Lucida Sans Unicode, sans-serif"],
      ["Tilt Prism", "Tilt Prism, cursive"],
      ["Amatic SC", "Amatic SC, cursive"],
      ["Blaka Hollow", "Blaka Hollow, cursive"],
      ["Bungee Inline", "Bungee Inline, cursive"],
      ["Bungee Shade", "Bungee Shade, cursive"],
      ["Creepster", "Creepster, cursive"],
      ["Dancing Script", "Dancing Script, cursive"],
      ["Dosis", "Dosis, sans-serif"],
      ["Fjord One", "Fjord One, serif"],
      ["Fredoka One", "Fredoka One, cursive"],
      ["Gajraj One", "Gajraj One, cursive"],
      ["Gloock", "Gloock, serif"],
      ["Josefin Sans", "Josefin Sans, sans-serif"],
      ["Marhey", "Marhey, cursive"],
      ["Montserrat", "Montserrat, sans-serif"],
      ["Mynerve", "Mynerve, cursive"],
      ["Rampart One", "Rampart One, cursive"],
      ["Space Grotesk", "Space Grotesk, sans-serif"],
      ["Tilt Neon", "Tilt Neon, cursive"],
      ["Tilt Prism", "Tilt Prism, cursive"],
      ["Tourney", "Tourney, cursive"],
      ["Ultra", "Ultra, serif"],
    ],
  },
  "box-shadow": {
    args: ["px", "px", "px", "color"],
  },
  opacity: {
    args: ["float"],
  },
  border: {
    args: ["px", "dropdown", "color"],
    dropdownValue: ["solid", "dashed", "dotted"],
  },
  "z-index": {
    args: ["integer"],
  },
  position: {
    args: ["dropdown"],
    dropdownValue: ["absolute", "relative", "fixed"],
  },
  transition: {
    args: ["text", "seconds"],
  },
  "animation-name": {
    args: ["text"],
  },
  "animation-duration": {
    args: ["seconds"],
  },
  "animation-iteration-count": {
    args: ["integer"],
  },
  "animation-direction": {
    args: ["dropdown"],
    dropdownValue: ["normal", "reverse", "alternate", "alternate-reverse"],
  },
  "background-position": {
    args: ["percent"],
  },
  "text-shadow": {
    args: ["px", "px", "px", "color"],
  },
  cursor: {
    args: ["dropdown"],
    dropdownValue: [
      "pointer",
      "grab",
      "zoom-in",
      "not-allowed",
      "crosshair",
      "wait",
      "help",
    ],
  },
  top: {
    args: ["px"],
  },
  bottom: {
    args: ["px"],
  },
  left: {
    args: ["px"],
  },
  right: {
    args: ["px"],
  },
};

const cssBlocks = Object.keys(CSS_BLOCK_TYPES).map((type) => {
  const { args, dropdownValue, valueFormatter } = CSS_BLOCK_TYPES[type];

  const typeNameSplit = type.split("-").join(" ");
  const typeName = `${typeNameSplit[0].toUpperCase()}${typeNameSplit.slice(1)}`;

  const messageArgs = args.map((_, index) => `%${index + 1}`).join(" ");
  const message0 = `${typeName} ${messageArgs}`;

  let count = 1;
  let argsList = [];

  return {
    type,
    message0,
    args0: args.map((arg) => {
      if (arg === "dropdown") {
        return {
          type: "field_dropdown",
          name: "VALUE",
          options: dropdownValue.map((value) => {
            if (typeof value === "string") return [value, value];

            return value;
          }),
        };
      }

      if (arg === "color") {
        return {
          type: "input_value",
          name: "COLOR",
          check: "Colour",
        };
      }

      if (arg === "text") {
        return {
          type: "input_value",
          name: "TEXT",
          check: "String",
        };
      }

      if (arg === "px") {
        let argName = arg.toUpperCase();

        if (argsList.includes(arg)) {
          argName = `${argName}${count}`;
          count++;
        }
        argsList.push(arg);

        return {
          type: "input_value",
          name: argName,
          check: "Number",
        };
      }

      if (arg === "float") {
        return {
          type: "input_value",
          name: "FLOAT",
          check: "Number",
        };
      }

      if (arg === "integer") {
        return {
          type: "input_value",
          name: "INTEGER",
          check: "Number",
        };
      }

      if (arg === "seconds") {
        return {
          type: "input_value",
          name: "SECONDS",
          check: "Number",
        };
      }

      if (arg === "percent") {
        return {
          type: "input_value",
          name: "PERCENT",
          check: "Number",
        };
      }

      throw new Error(`Unknown arg type: ${arg}`);
    }),
    previousStatement: null,
    nextStatement: null,
    colour: 200,
    ...(valueFormatter ? { valueFormatter } : {}),
  };
});

export const cssBlocksForGeneration = cssBlocks.map((block) => ({
  ...block,
  jsName: STYLES_TO_JS[block.type],
}));

export const blocks = [
  {
    type: "add_animation",
    message0: "Add animation with name %1 from %2 to %3",
    args0: [
      {
        type: "input_value",
        name: "NAME",
        check: "String",
      },
      {
        type: "input_statement",
        name: "FROM",
      },
      {
        type: "input_statement",
        name: "TO",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 200,
  },
  ...cssBlocksForGeneration,
];
