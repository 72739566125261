import React from "react";
import "../../styles/HowTo.css";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import WebIcon from "@mui/icons-material/Web";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";

const LESSON_TYPES = {
  art: {
    backgroundColor: "rgb(138 59 113 / 63%)",
    icon: ColorLensIcon,
  },
  code: {
    backgroundColor: "rgb(137 246 250 / 41%)",
    icon: DeveloperModeIcon,
  },
  howto: {
    backgroundColor: "rgb(51 111 110 / 62%)",
    icon: ViewCarouselIcon,
  },
  page: {
    backgroundColor: "rgb(119 152 64 / 77%)",
    icon: WebIcon,
  },
  game: {
    backgroundColor: "rgb(3 169 244 / 59%)",
    icon: VideogameAssetIcon,
  },
};

const HowToListItem = ({ lesson, handleLessonClick }) => {
  const { icon: Icon, backgroundColor } = LESSON_TYPES[lesson.category];

  return (
    <span
      className="nav-list-item"
      style={{ backgroundColor }}
      onClick={() => handleLessonClick(lesson)}
    >
      {lesson.title}
      <Icon sx={{ marginLeft: 1, fontSize: 40 }} />
    </span>
  );
};

export default HowToListItem;
