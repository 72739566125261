export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const STYLE_TYPES = {
  NUMBER: "number",
  COLOR: "color",
  TEXT: "text",
  DROPDOWN: "dropdown",
};

export const createDiv = (htmlBlockly, { CLASS }) => {
  const div = htmlBlockly.newBlock("div");
  div.initSvg();
  div.render();

  const classInputBlock = htmlBlockly.newBlock("text");
  classInputBlock.setFieldValue(CLASS, "TEXT");
  classInputBlock.initSvg();
  classInputBlock.render();
  classInputBlock.setColour("#e56a6a");

  classInputBlock.outputConnection.connect(div.inputList[0].connection);

  return div;
};

export const createImage = (htmlBlockly, { SRC, WIDTH, HEIGHT, CLASS }) => {
  const image = htmlBlockly.newBlock("add_image");
  image.initSvg();
  image.render();

  const srcInputBlock = htmlBlockly.newBlock("text");
  srcInputBlock.setFieldValue(SRC, "TEXT");
  srcInputBlock.initSvg();
  srcInputBlock.render();
  srcInputBlock.setColour("#e56a6a");

  const widthValue = htmlBlockly.newBlock("math_number");
  widthValue.initSvg();
  widthValue.render();
  widthValue.setFieldValue(WIDTH, "NUM");

  const heightValue = htmlBlockly.newBlock("math_number");
  heightValue.initSvg();
  heightValue.render();
  heightValue.setFieldValue(HEIGHT, "NUM");

  const classInputBlock = htmlBlockly.newBlock("text");
  classInputBlock.setFieldValue(CLASS, "TEXT");
  classInputBlock.initSvg();
  classInputBlock.render();

  srcInputBlock.outputConnection.connect(image.inputList[0].connection);
  widthValue.outputConnection.connect(image.inputList[1].connection);
  heightValue.outputConnection.connect(image.inputList[2].connection);
  classInputBlock.outputConnection.connect(image.inputList[3].connection);

  return image;
};

const createFunction = (htmlBlockly, name) => {
  const func = htmlBlockly.newBlock("procedures_defnoreturn");
  func.initSvg();
  func.render();

  func.setFieldValue(name, "NAME");

  return func;
};

const createLink = (htmlBlockly, linkToAddress) => {
  const link = htmlBlockly.newBlock("link");
  link.initSvg();
  link.render();

  const linkInputBlock = htmlBlockly.newBlock("text");
  linkInputBlock.setFieldValue(linkToAddress, "TEXT");
  linkInputBlock.initSvg();
  linkInputBlock.render();
  linkInputBlock.setColour("#e56a6a");

  linkInputBlock.outputConnection.connect(link.inputList[0].connection);

  return link;
};

const createInput = (htmlBlockly, placeholderText, className) => {
  const input = htmlBlockly.newBlock("add_input");
  input.initSvg();
  input.render();

  const textInputBlock = htmlBlockly.newBlock("text");
  textInputBlock.setFieldValue(placeholderText, "TEXT");
  textInputBlock.initSvg();
  textInputBlock.render();
  textInputBlock.setColour("#e56a6a");

  textInputBlock.outputConnection.connect(input.inputList[0].connection);

  const classInputBlock = htmlBlockly.newBlock("text");
  classInputBlock.setFieldValue(className, "TEXT");
  classInputBlock.initSvg();
  classInputBlock.render();
  classInputBlock.setColour("#e56a6a");

  classInputBlock.outputConnection.connect(input.inputList[1].connection);

  return input;
};

const createButton = (htmlBlockly, buttonText, className, onClick) => {
  const button = htmlBlockly.newBlock("add_button");
  button.initSvg();
  button.render();

  const textInputBlock = htmlBlockly.newBlock("text");
  textInputBlock.setFieldValue(buttonText, "TEXT");
  textInputBlock.initSvg();
  textInputBlock.render();
  textInputBlock.setColour("#e56a6a");

  textInputBlock.outputConnection.connect(button.inputList[0].connection);

  const classInputBlock = htmlBlockly.newBlock("text");
  classInputBlock.setFieldValue(className, "TEXT");
  classInputBlock.initSvg();
  classInputBlock.render();
  classInputBlock.setColour("#e56a6a");

  classInputBlock.outputConnection.connect(button.inputList[1].connection);

  if (onClick) {
    button.setFieldValue(onClick, "OPTIONS");
  }

  return button;
};

export const createHeader = (htmlBlockly, { SIZE, TEXT, CLASS }) => {
  const header = htmlBlockly.newBlock("add_header");
  header.initSvg();
  header.render();
  header.setFieldValue(SIZE, "HEADER_SIZE");

  const textInputBlock = htmlBlockly.newBlock("text");
  textInputBlock.setFieldValue(TEXT, "TEXT");
  textInputBlock.initSvg();
  textInputBlock.render();
  textInputBlock.setColour("#e56a6a");

  textInputBlock.outputConnection.connect(header.inputList[0].connection);

  const classInputBlock = htmlBlockly.newBlock("text");
  classInputBlock.setFieldValue(CLASS, "TEXT");
  classInputBlock.initSvg();
  classInputBlock.render();
  classInputBlock.setColour("#e56a6a");

  classInputBlock.outputConnection.connect(header.inputList[1].connection);

  return header;
};

export const createParagraph = (htmlBlockly, { TEXT, CLASS }) => {
  const paragraph = htmlBlockly.newBlock("add_paragraph");
  paragraph.initSvg();
  paragraph.render();

  const textInputBlock = htmlBlockly.newBlock("text");
  textInputBlock.setFieldValue(TEXT, "TEXT");
  textInputBlock.initSvg();
  textInputBlock.render();
  textInputBlock.setColour("#e56a6a");

  textInputBlock.outputConnection.connect(paragraph.inputList[0].connection);

  const classInputBlock = htmlBlockly.newBlock("text");
  classInputBlock.setFieldValue(CLASS, "TEXT");
  classInputBlock.initSvg();
  classInputBlock.render();
  classInputBlock.setColour("#e56a6a");

  classInputBlock.outputConnection.connect(paragraph.inputList[1].connection);

  return paragraph;
};

const createCard = (htmlBlockly, parentDiv, cards, cardTitle, cardText) => {
  const card = createDiv(htmlBlockly, "card");
  cards.inputList[1].connection.connect(card.previousConnection);
  parentDiv.nextConnection.connect(cards.previousConnection);

  const cardTitleHeader = createHeader(
    htmlBlockly,
    "h4",
    cardTitle,
    "card-title"
  );

  cardText.reverse().forEach((text) => {
    const paragraph = createParagraph(htmlBlockly, text, "card-text");
    card.inputList[1].connection.connect(paragraph.previousConnection);
  });

  card.inputList[1].connection.connect(cardTitleHeader.previousConnection);
};

export const createClassSelectorBlock = (
  cssBlockly,
  { CLASS, PSEUDO = null },
  id = null,
  blockType = "style_classes"
) => {
  const styles = cssBlockly.newBlock(blockType, id);
  styles.initSvg();
  styles.render();
  styles.setFieldValue(CLASS, "OPTIONS");

  if (PSEUDO) {
    styles.setFieldValue(PSEUDO, "PSEUDO");
  }

  return styles;
};

export const createTagSelectorBlock = (cssBlockly, { TAG }, id = null) => {
  const styles = cssBlockly.newBlock("style_element", id);
  styles.initSvg();
  styles.render();
  styles.setFieldValue(TAG, "OPTIONS");

  return styles;
};

export const createMultipartBlock = (
  cssBlockly,
  selector,
  offsetX,
  offsetY,
  blurRadius,
  color,
  blockName = "box-shadow"
) => {
  const styleBlock = cssBlockly.newBlock(blockName);
  styleBlock.initSvg();
  styleBlock.render();

  selector.inputList[1].connection.connect(styleBlock.previousConnection);
  const colorBlockValue = cssBlockly.newBlock("colour_picker");
  colorBlockValue.initSvg();
  colorBlockValue.render();
  colorBlockValue.setFieldValue(color, "COLOUR");

  const offsetXValue = cssBlockly.newBlock("math_number");
  offsetXValue.initSvg();
  offsetXValue.render();
  offsetXValue.setFieldValue(offsetX, "NUM");

  const offsetYValue = cssBlockly.newBlock("math_number");
  offsetYValue.initSvg();
  offsetYValue.render();
  offsetYValue.setFieldValue(offsetY, "NUM");

  const blurRadiusValue = cssBlockly.newBlock("math_number");
  blurRadiusValue.initSvg();
  blurRadiusValue.render();
  blurRadiusValue.setFieldValue(blurRadius, "NUM");

  offsetXValue.outputConnection.connect(styleBlock.inputList[0].connection);
  offsetYValue.outputConnection.connect(styleBlock.inputList[1].connection);
  blurRadiusValue.outputConnection.connect(styleBlock.inputList[2].connection);
  colorBlockValue.outputConnection.connect(styleBlock.inputList[3].connection);
};
export const createClassStyleBlock = (
  cssBlockly,
  selector,
  blockName,
  blockValue,
  type,
  dropdownName = "VALUE",
  connectionIndex = 1
) => {
  const styleBlock = cssBlockly.newBlock(blockName);
  styleBlock.initSvg();
  styleBlock.render();

  if (selector)
    selector.inputList[connectionIndex].connection.connect(
      styleBlock.previousConnection
    );
  let styleBlockValue;
  if (type === STYLE_TYPES.COLOR) {
    styleBlockValue = cssBlockly.newBlock("colour_picker");
    styleBlockValue.initSvg();
    styleBlockValue.render();
    styleBlockValue.setFieldValue(blockValue, "COLOUR");
  }

  if (type === STYLE_TYPES.NUMBER) {
    styleBlockValue = cssBlockly.newBlock("math_number");
    styleBlockValue.initSvg();
    styleBlockValue.render();
    styleBlockValue.setFieldValue(blockValue, "NUM");
  }

  if (type === STYLE_TYPES.TEXT) {
    styleBlockValue = cssBlockly.newBlock("text");
    styleBlockValue.initSvg();
    styleBlockValue.render();
    styleBlockValue.setFieldValue(blockValue, "TEXT");
  }

  if (type === STYLE_TYPES.DROPDOWN) {
    try {
      styleBlockValue = styleBlock.setFieldValue(blockValue, dropdownName);
    } catch (err) {
      console.log(blockName, blockValue);
    }
  }

  if (type !== STYLE_TYPES.DROPDOWN)
    styleBlockValue.outputConnection.connect(
      styleBlock.inputList[0].connection
    );

  return styleBlock;
};

async function runStyles(cssBlockly) {
  // Styles
  const bodyStyles = cssBlockly.newBlock("style_element");
  bodyStyles.initSvg();
  bodyStyles.render();
  bodyStyles.setFieldValue("body", "ELEMENT");

  createClassStyleBlock(
    cssBlockly,
    bodyStyles,
    "font-family",
    "Trebuchet MS, sans-serif",
    STYLE_TYPES.DROPDOWN,
    "FONT"
  );
  createClassStyleBlock(
    cssBlockly,
    bodyStyles,
    "display",
    "flex",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    bodyStyles,
    "flex-direction",
    "column",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    bodyStyles,
    "align-items",
    "center",
    STYLE_TYPES.DROPDOWN
  );

  // height: 200px;
  // background-color: #ddd;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  // TODO: A hack to wait for the styles to be rendered
  await delay(100);
  const heroStyles = createClassSelectorBlock(cssBlockly, "hero");
  createMultipartBlock(cssBlockly, heroStyles, 10, 10, 10, "#c0c0c0");

  createClassStyleBlock(cssBlockly, heroStyles, "height", 200, "number");

  createClassStyleBlock(
    cssBlockly,
    heroStyles,
    "background-color",
    "#a2bd9e",
    "color"
  );
  createClassStyleBlock(
    cssBlockly,
    heroStyles,
    "display",
    "flex",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    heroStyles,
    "align-items",
    "center",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    heroStyles,
    "justify-content",
    "center",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    heroStyles,
    "border-radius",
    5,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    heroStyles,
    "padding",
    20,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    heroStyles,
    "padding",
    20,
    STYLE_TYPES.NUMBER
  );

  // .hero-infographic {
  //   width: 200px;
  //   height: 200px;
  //   background-color: white;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  //   border-radius: 50%;
  // }

  const heroInfographicStyles = createClassSelectorBlock(
    cssBlockly,
    "hero-infographic"
  );
  createClassStyleBlock(
    cssBlockly,
    heroInfographicStyles,
    "width",
    200,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    heroInfographicStyles,
    "height",
    200,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    heroInfographicStyles,
    "background-color",
    "#fff",
    STYLE_TYPES.COLOR
  );
  createClassStyleBlock(
    cssBlockly,
    heroInfographicStyles,
    "display",
    "flex",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    heroInfographicStyles,
    "align-items",
    "center",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    heroInfographicStyles,
    "justify-content",
    "center",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    heroInfographicStyles,
    "border-radius",
    100,
    STYLE_TYPES.NUMBER
  );

  const heroNumberStyles = createClassSelectorBlock(cssBlockly, "hero-number");
  createClassStyleBlock(
    cssBlockly,
    heroNumberStyles,
    "font-size",
    72,
    STYLE_TYPES.NUMBER
  );

  // display: flex;
  // justify-content: space-between;
  // width: 80%;
  // margin-top: 40px;

  const cardsStyles = createClassSelectorBlock(cssBlockly, "cards");
  createClassStyleBlock(
    cssBlockly,
    cardsStyles,
    "display",
    "flex",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    cardsStyles,
    "justify-content",
    "space-between",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    cardsStyles,
    "width",
    800,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    cardsStyles,
    "margin-top",
    40,
    STYLE_TYPES.NUMBER
  );

  // .card {
  //   width: 30%;
  //   background-color: white;
  //   box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  //   border-radius: 10px;
  //   padding: 20px;
  //   text-align: center;
  //   margin-bottom: 40px;
  // }
  const cardStyles = createClassSelectorBlock(cssBlockly, "card");
  createMultipartBlock(cssBlockly, cardStyles, 10, 10, 10, "#c0c0c0");

  createClassStyleBlock(
    cssBlockly,
    cardStyles,
    "width",
    300,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    cardStyles,
    "margin-left",
    10,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    cardStyles,
    "margin-right",
    10,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    cardStyles,
    "background-color",
    "#a2bd9e",
    STYLE_TYPES.COLOR
  );
  createClassStyleBlock(
    cssBlockly,
    cardStyles,
    "border-radius",
    10,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    cardStyles,
    "padding",
    20,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    cardStyles,
    "text-align",
    "center",
    STYLE_TYPES.DROPDOWN,
    "ALIGN"
  );
  createClassStyleBlock(
    cssBlockly,
    cardStyles,
    "margin-bottom",
    40,
    STYLE_TYPES.NUMBER
  );

  const cardTitleStyles = createClassSelectorBlock(cssBlockly, "card-title");
  createClassStyleBlock(
    cssBlockly,
    cardTitleStyles,
    "font-size",
    20,
    STYLE_TYPES.NUMBER
  );
  // createClassStyleBlock(
  //   cssBlockly,
  //   cardTitleStyles,
  //   "font_weight_property",
  //   "bold",
  //   STYLE_TYPES.DROPDOWN
  // );
  createClassStyleBlock(
    cssBlockly,
    cardTitleStyles,
    "margin-bottom",
    20,
    STYLE_TYPES.NUMBER
  );

  const subscribeStyles = createClassSelectorBlock(cssBlockly, "subscribe");
  createClassStyleBlock(
    cssBlockly,
    subscribeStyles,
    "margin-top",
    40,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    subscribeStyles,
    "display",
    "flex",
    STYLE_TYPES.DROPDOWN
  );
  createClassStyleBlock(
    cssBlockly,
    subscribeStyles,
    "justify-content",
    "center",
    STYLE_TYPES.DROPDOWN
  );

  const subscribeTitleStyles = createClassSelectorBlock(
    cssBlockly,
    "subscribe-title"
  );
  createClassStyleBlock(
    cssBlockly,
    subscribeTitleStyles,
    "font-size",
    20,
    STYLE_TYPES.NUMBER
  );
  // createClassStyleBlock(
  //   cssBlockly,
  //   subscribeTitleStyles,
  //   "font_weight_property",
  //   "bold",
  //   STYLE_TYPES.DROPDOWN
  // );
  createClassStyleBlock(
    cssBlockly,
    subscribeTitleStyles,
    "margin-bottom",
    20,
    STYLE_TYPES.NUMBER
  );

  const subscribeButtonStyles = createClassSelectorBlock(cssBlockly, "submit");

  createClassStyleBlock(
    cssBlockly,
    subscribeButtonStyles,
    "background-color",
    "#fff",
    STYLE_TYPES.COLOR
  );
  createClassStyleBlock(
    cssBlockly,
    subscribeButtonStyles,
    "border-radius",
    10,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    subscribeButtonStyles,
    "padding",
    10,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    subscribeButtonStyles,
    "margin-left",
    10,
    STYLE_TYPES.NUMBER
  );
  const subscribeEmailStyles = createClassSelectorBlock(cssBlockly, "email");

  createClassStyleBlock(
    cssBlockly,
    subscribeEmailStyles,
    "background-color",
    "#fff",
    STYLE_TYPES.COLOR
  );
  createClassStyleBlock(
    cssBlockly,
    subscribeEmailStyles,
    "border-radius",
    10,
    STYLE_TYPES.NUMBER
  );
  createClassStyleBlock(
    cssBlockly,
    subscribeEmailStyles,
    "padding",
    10,
    STYLE_TYPES.NUMBER
  );
}

export async function loadNavbar({
  HTML: htmlBlockly,
  CSS: cssBlockly,
  JS: jsBlockly,
}) {
  // Navbar
  const parentDiv = createDiv(htmlBlockly, "hero");
  const childDiv = createDiv(htmlBlockly, "hero-infographic");
  const heroNumberDiv = createDiv(htmlBlockly, "hero-number");
  parentDiv.inputList[1].connection.connect(childDiv.previousConnection);
  childDiv.inputList[1].connection.connect(heroNumberDiv.previousConnection);

  const heroNumberHeader = createHeader(
    htmlBlockly,
    "h3",
    "2030",
    "hero-number-header"
  );
  heroNumberDiv.inputList[1].connection.connect(
    heroNumberHeader.previousConnection
  );

  // Cards
  const cards = createDiv(htmlBlockly, "cards");

  createCard(htmlBlockly, parentDiv, cards, "Impact", [
    "Improves access to energy and reduces poverty",
    "Reduces greenhouse gas emissions and combats climate change",
    "Supports economic growth and job creation",
    "Improves health and education outcomes",
  ]);
  createCard(htmlBlockly, parentDiv, cards, "Targets", [
    "7.1: By 2030, ensure universal access to affordable, reliable and modern energy services.",
    "7.2: By 2030, increase substantially the share of renewable energy in the global energy mix.",
    "7.3: By 2030, double the global rate of improvement in energy efficiency.",
  ]);
  createCard(htmlBlockly, parentDiv, cards, "Sustainable Development Goal 7", [
    "Affordable and Clean Energy",
    "Ensure access to affordable, reliable, sustainable and modern energy for all.",
  ]);
  const func = createFunction(jsBlockly, "open_link");
  const link = createLink(
    jsBlockly,
    "https://www.un.org/sustainabledevelopment/energy/"
  );
  func.inputList[1].connection.connect(link.previousConnection);

  await delay(100);

  const subscribe = createDiv(htmlBlockly, "subscribe");
  const subscribeTitle = createDiv(htmlBlockly, "subscribe-title");
  const emailInput = createInput(htmlBlockly, "Enter your email", "email");
  const learnMore = createButton(htmlBlockly, "Learn More", "submit");
  const submitButton = createButton(
    htmlBlockly,
    "Pledge an hour",
    "submit",
    "open_link"
  );

  subscribe.inputList[1].connection.connect(subscribeTitle.previousConnection);
  subscribeTitle.inputList[1].connection.connect(
    submitButton.previousConnection
  );
  subscribeTitle.inputList[1].connection.connect(learnMore.previousConnection);
  subscribeTitle.inputList[1].connection.connect(emailInput.previousConnection);
  cards.nextConnection.connect(subscribe.previousConnection);

  // Ensure classes are loaded here
  await runStyles(cssBlockly);
}

export const loadWorkspaceFromCode = (pen, workspace) => {};
