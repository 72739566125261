/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/*
This toolbox contains HTML blocks.
*/
export const toolbox = {
  kind: "categoryToolbox",
  contents: [
    {
      name: "Text",
      categorystyle: "text_category",
      kind: "category",
      contents: [
        {
          kind: "block",
          type: "add_header",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "text",
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "add_paragraph",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "text",
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "list_item",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "add_anchor_tag",
          inputs: {
            HREF: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "",
                },
              },
            },
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "text",
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
      ],
    },
    {
      name: "Interactive",
      categorystyle: "text_category",
      kind: "category",
      contents: [
        {
          kind: "block",
          type: "add_button",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "add_input",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
      ],
    },
    {
      name: "Containers",
      categorystyle: "text_category",
      kind: "category",
      contents: [
        {
          kind: "block",
          type: "div",
          inputs: {
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "span",
          inputs: {
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "add_list",
          inputs: {
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
      ],
    },
    {
      name: "Media",
      categorystyle: "text_category",
      kind: "category",
      contents: [
        {
          kind: "block",
          type: "add_image",
          inputs: {
            IMAGE_URL: {
              shadow: {
                type: "text",
                fields: {
                  IMAGE_URL: "abc",
                },
              },
            },
            WIDTH: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 250,
                },
              },
            },
            HEIGHT: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 250,
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "add_svg",
          inputs: {
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "add_canvas",
          inputs: {
            ID: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "myCanvas",
                },
              },
            },
            WIDTH: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 250,
                },
              },
            },
            HEIGHT: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 250,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "add_audio",
          inputs: {
            SOURCE: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "song.mp3",
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "add_video",
          inputs: {
            SOURCE: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "video.mp4",
                },
              },
            },
            CLASS: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "class",
                },
              },
            },
            WIDTH: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 250,
                },
              },
            },
            HEIGHT: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 250,
                },
              },
            },
          },
        },
      ],
    },
  ],
};
