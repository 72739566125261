import React from "react";
import Footer from "../Footer";
import BLOG_POSTS from "../../posts";
import { Helmet } from "react-helmet-async";

const TAGS_COLOR = {
  Code: "rgb(138 59 113 / 63%)",
  Games: "rgb(3 169 244 / 59%)",
  Scratch: "rgb(251 166 25 / 59%)",
  Starter: "rgb(137 246 250 / 41%)",
  Websites: "rgb(119 152 64 / 77%)",
};

const LearnGallery = () => (
  <>
    <Helmet>
      <title>Learn with Blockpen</title>
      <meta
        name="description"
        content="Learn everything from how to make a website or game with Blockpen to how to make a game with Scratch. Blockpen is a block-based programming tool for learning the basics of web development"
      />
      <meta
        name="og:description"
        content="Learn everything from how to make a website or game with Blockpen to how to make a game with Scratch. Blockpen is a block-based programming tool for learning the basics of web development"
      />
    </Helmet>
    <div className="learn-gallery">
      <div className="learn-gallery-container">
        {BLOG_POSTS.map(({ path, tags, data }) => (
          <div className="learn-gallery-item">
            <a href={path}>
              <img
                src={data.headerImg}
                alt={data.headerAlt}
                className="learn-gallery-img"
                width="100"
                height="100"
              />
              <h3 className="learn-gallery-title">{data.header}</h3>
              <div className="learn-gallery-tags">
                {tags.map((tag) => (
                  <span
                    className="learn-gallery-tag"
                    style={{ backgroundColor: TAGS_COLOR[tag] }}
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </a>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  </>
);

export default LearnGallery;
