const Display = ({ code }) => {
  return (
    <iframe
      title="display"
      className="display"
      srcDoc={`
        <html>
            <head>
                <style>
                  html { background-color: #fff; }
                  ${code.CSS}
                </style>
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Blaka+Hollow&family=Bungee+Inline&family=Bungee+Shade&family=Creepster&family=Dancing+Script:wght@500&family=Dosis:wght@600&family=Fjord+One&family=Fredoka+One&family=Gajraj+One&family=Gloock&family=Josefin+Sans:wght@200&family=Marhey:wght@300&family=Montserrat:wght@300&family=Mynerve&family=Rampart+One&family=Space+Grotesk&family=Tilt+Neon&family=Tilt+Prism&family=Tourney&family=Ultra&display=swap" rel="stylesheet">
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
              </head>
            <body>
              <div id='output'>${code.HTML}</div>
              <script>${code.JS}</script>
            </body>
        </html>
    `}
    />
  );
};

export default Display;
