import React, { useEffect, useState } from "react";
import "../../styles/Blog.css";
import LearnTooltip from "../lessons/LearnTooltip";
import { TOOLTIPS } from "../../constants/tooltips";
import { Helmet } from "react-helmet-async";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

// TODO: Links between blog pages
const LearnBlog = ({ post }) => {
  const { sections, headerImg, header, subheader, description } = post;
  const [tooltip, setTooltip] = useState(null);
  const [anchorTag, setAnchorTag] = useState(window.location.hash);

  const headerStyles = {
    fontFamily: "Raleway, sans-serif",
    fontWeight: "800",
    color: "#4e606c",
  };

  const listItemStyles = {
    position: "relative",
    paddingLeft: "0.875rem",
  };

  const linkStyles = {
    display: "block",
    width: "100%",
    textDecoration: "none",
    color: "#6B7280",
    "&:hover": {
      color: "#4B5563",
    },
  };

  useEffect(() => {
    const blog = document.getElementById("blog-container");
    blog.addEventListener("click", (e) => {
      if (e.target.tagName === "B") {
        const { top, left } = e.target.getBoundingClientRect();

        let type = e.target.innerText;

        if (e.target.dataset?.definition) {
          type = e.target.dataset.definition;
        }

        if (!TOOLTIPS[type]) return;

        setTooltip({
          type,
          top,
          left,
        });
      } else {
        setTooltip(null);
      }
    });
  }, []);

  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{header}</title>
        <meta property="og:title" content={header} />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        {/* TODO: Meta keywords */}
        {/* TODO: og:image */}
      </Helmet>
      <main id="blog-container">
        <header className="blog-hero">
          <div className="hero-content">
            <span>
              <h1 className="header-custom">{header}</h1>
              <h2 className="subheader-custom">{subheader}</h2>
            </span>
            {!isMobile && (
              <img
                src={headerImg}
                alt="hero"
                height="250"
                width="250"
                className="hero-image"
              />
            )}
          </div>
        </header>
        {tooltip && (
          <LearnTooltip
            type={tooltip.type}
            top={tooltip.top}
            left={tooltip.left}
            closeTooltip={() => setTooltip(null)}
          />
        )}
        <div className="content-custom">
          <div className="content-panel">
            <div className="nav-container">
              <nav>
                <ol style={{ paddingLeft: "1rem" }}>
                  {sections.map((section) => (
                    <li
                      key={section.title}
                      style={{ fontSize: "14px", paddingBottom: "1.5rem" }}
                    >
                      <h2 style={headerStyles}>{section.title}</h2>
                      <ol className="nav-list">
                        {section.items.map((item) => {
                          const href = `#${item.id}`;

                          return (
                            <li
                              key={item.text}
                              style={listItemStyles}
                              className={`list-item ${
                                href === anchorTag ? "selected" : ""
                              }`}
                            >
                              <a
                                href={href}
                                onClick={() => setAnchorTag(href)}
                                style={linkStyles}
                              >
                                {item.title}
                              </a>
                            </li>
                          );
                        })}
                      </ol>
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
          </div>
          <div className="content-body">
            <article>
              {sections.map((contentSection) => (
                <section className="content-container">
                  <h3 className="content-header">{contentSection.title}</h3>
                  {contentSection.items.map((item) => (
                    <div style={{ margin: "0 0 3rem 0" }}>
                      <h4
                        className="content-subheader"
                        id={item.id}
                        style={{ marginTop: "-100px", paddingTop: "100px" }}
                      >
                        {item.title}
                      </h4>
                      <p className="content-body-paragraph">{item.body}</p>
                      {!!item.image && (
                        <img
                          src={item.image}
                          alt={item.alt}
                          height="200"
                          width="200"
                          className="content-image"
                        />
                      )}
                      {!!item.tieInLesson ? (
                        <div className="quick-links">
                          <div className="quick-link">
                            <a
                              className="quick-link-header"
                              href={item.tieInLesson.href}
                              style={{ textDecoration: "none" }}
                            >
                              {item.tieInLesson.text}
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                  <hr />
                </section>
              ))}
            </article>
          </div>
        </div>
      </main>
    </>
  );
};

export default LearnBlog;
