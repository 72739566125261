import { useState, useEffect, useMemo } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

import Display from "../Display";
import Editor from "../Editor";
import { loadWorkspacesFromCode, saveWorkspaces } from "../serialization";
import {
  generateHTMLBlocksFromCode,
  generateCSSBlocksFromCode,
} from "../blockUtils/helpers";
import "../styles/App.css";
import ListPens from "./ListPens";
import SavePen from "./SavePen";
import { useMutation, useQuery, gql } from "@apollo/client";
import useWorkspace from "../hooks/useWorkspace";
import {
  addClassOption,
  addFunctionOption,
  getClassOptions,
  getFunctionOptions,
} from "../blocks/dynamicDropdowns";
import { loadCSSClasses, loadJSFunctions } from "../helpers/dropdownHelpers";
import { getDocument } from "../helpers/getDocument";
import { LOAD_PEN_BY_NAME_AND_ID } from "./DisplayOnly";
import HowToContainer from "./lessons/HowToContainer";
import ActionButton from "./ui/ActionButton";
import { LoadingSvg } from "./ui/LoadingSvg";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { BASE_EDITOR_PATH } from "../constants/paths";

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const CREATE_PEN = gql`
  mutation createPen(
    $title: String!
    $html: String!
    $css: String!
    $js: String!
    $document: String!
  ) {
    createPen(
      title: $title
      html: $html
      css: $css
      js: $js
      document: $document
    ) {
      id
      title
      html
      css
      js
      document
    }
  }
`;

const UPDATE_PEN = gql`
  mutation updatePen(
    $id: Int!
    $title: String!
    $html: String!
    $css: String!
    $js: String!
    $document: String!
  ) {
    updatePen(
      id: $id
      title: $title
      html: $html
      css: $css
      js: $js
      document: $document
    ) {
      id
      title
      html
      css
      js
      document
    }
  }
`;

const MainEditor = ({ menuOpen, setMenuOpen, loginScreen, setLoginScreen }) => {
  const params = useParams();
  const queryParams = useQueryParams();

  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [activePen, _setActivePen] = useState(false);
  const [isSaveTemplateOpen, setIsSaveTemplateOpen] = useState(false);
  const [isCurrentUsersPen, setCurrentUsersPen] = useState(false);
  const [learnParam, setLearnParam] = useState(null);

  const [expand, _setExpand] = useState({ HTML: false, CSS: false, JS: false });
  const [createPen] = useMutation(CREATE_PEN);
  const [updatePen] = useMutation(UPDATE_PEN);
  const [loadedPenId, setLoadedPenId] = useState(null);
  const [howToModal, setHowToModal] = useState(null);
  const [isLoadPenLoading, setIsLoadPenLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDemo, setDemo] = useState(false);
  const [viewCode, _setViewCode] = useState({
    HTML: false,
    CSS: false,
    JS: false,
  });

  const { code, workspace, setCode, reloadWorkspaces } = useWorkspace({
    callbacks: {
      addClassOption,
      addFunctionOption,
      getClassOptions,
      getFunctionOptions,
    },
  });

  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const setViewCode = (language) => {
    if (!viewCode[language] && code[language] === "") return;
    _setViewCode((prev) => {
      return { ...prev, [language]: !prev[language] };
    });
  };

  const [isAuthed, setIsAuthed] = useState(null);

  const navigate = useNavigate();

  const { data: loadedPenData, loading: loadedPenLoading } = useQuery(
    LOAD_PEN_BY_NAME_AND_ID,
    {
      variables: { penId: loadedPenId },
      skip: !loadedPenId,
    }
  );

  const onSavePen = () => {
    if (!isAuthed) {
      setLoginScreen(true);
      return;
    }

    if (activePen?.id) {
      savePen();
      return;
    }

    navigate(`${BASE_EDITOR_PATH}/save`);
  };

  const isLoading =
    loadedPenLoading ||
    isSaveLoading ||
    isLoadPenLoading ||
    isAuthed === null ||
    loadedPenId === null;

  useEffect(() => {
    if (params?.path === "login") {
      if (!localStorage.getItem("AUTH_TOKEN")) {
        setLoginScreen(true);
      } else {
        navigate(BASE_EDITOR_PATH);
      }
    }

    if (params?.path === "signup" && !localStorage.getItem("AUTH_TOKEN")) {
      if (!localStorage.getItem("AUTH_TOKEN")) {
        setLoginScreen(true);
      } else {
        navigate(BASE_EDITOR_PATH);
      }
    }

    if (params?.path === "load") {
      if (localStorage.getItem("AUTH_TOKEN")) {
        setIsLoadModalOpen(true);
      } else {
        setLoginScreen(true);
      }
    }

    if (params?.path === "save") {
      if (localStorage.getItem("AUTH_TOKEN")) {
        setIsSaveTemplateOpen(true);
      } else {
        setLoginScreen(true);
      }
    }

    if (params?.path === "demo") {
      setDemo(true);
    }
  }, [params?.path]);

  useEffect(() => {
    if (queryParams.size > 0) {
      setLearnParam(queryParams.get("learn"));
    }
  }, [queryParams]);

  useEffect(() => {
    if (loadedPenData?.loadPenById) {
      if (`${activePen?.id}` === `${loadedPenData?.loadPenById?.id}`) {
        return;
      }
      setActivePen(loadedPenData.loadPenById, true);
      setCurrentUsersPen(loadedPenData.loadPenById?.isCurrentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPenData?.loadPenById]);

  useEffect(() => {
    // let penId = localStorage.getItem("penId");

    let penId = null;
    if (params?.id) {
      penId = params.id;
    }

    setTimeout(() => {
      if (penId) {
        setLoadedPenId(parseInt(penId));
      } else {
        setLoadedPenId("");
      }
      if (isMobile) {
        setExpand("HTML");
      }
    }, [1500]);
  }, []);

  // TODO: When loading from local storage, the CSS is not set properly because the HTML
  // classes aren't loaded at that point
  const setActivePen = async (pen, loadWorkspace = false) => {
    let loadFromCode = false;

    if (!pen) {
      _setActivePen(null);
      navigate(BASE_EDITOR_PATH);
      return;
    }
    const { html: HTML, css: CSS, js: JS } = pen;

    try {
      loadCSSClasses(HTML, CSS);
      loadJSFunctions(JS);
    } catch (e) {
      loadFromCode = true;

      if (HTML) {
        generateHTMLBlocksFromCode(HTML, workspace.HTML);
      }

      if (CSS) {
        generateCSSBlocksFromCode(
          CSS,
          workspace.CSS,
          addClassOption,
          getClassOptions
        );
      }
    }

    _setActivePen(pen);

    const penId = parseInt(pen.id);
    setLoadedPenId(penId);

    // localStorage.setItem("penId", penId);

    if (params.id !== penId) {
      navigate(`${BASE_EDITOR_PATH}/edit/${penId}`);
    }

    if (loadWorkspace && !loadFromCode) {
      loadWorkspacesFromCode(workspace, { HTML, JS, CSS });
      reloadWorkspaces();
    }

    if (workspace) saveWorkspaces(workspace);
  };

  const setExpand = (type) => {
    const otherKeys = Object.keys(expand).filter((key) => key !== type);
    const obj = {};

    otherKeys.forEach((key) => {
      obj[key] = false;
    });

    _setExpand({ ...obj, [type]: !expand[type] });
  };

  useEffect(() => {
    if (loadedPenData?.getSinglePen) {
      const data = loadedPenData?.getSinglePen;
      const { html: HTML, css: CSS, js: JS } = data;

      loadWorkspacesFromCode(workspace, { HTML, CSS, JS });
    }
  }, [loadedPenData]);

  const savePen = (penName) => {
    setIsSaveLoading(true);
    saveWorkspaces(workspace);
    const { HTML: html, CSS: css, JS: js } = localStorage;
    const document = getDocument(code);

    const data = {
      title: penName || activePen?.title,
      html: html,
      css: css,
      js: js,
      document,
    };

    if (activePen?.id && isCurrentUsersPen) {
      updatePen({
        variables: { id: activePen.id, ...data },
        onCompleted(data) {
          const { id, title, html, css, js } = data?.updatePen;

          setActivePen({ id, title, html, css, js, document });
          setLoadedPenId(`${id}`);
          setTimeout(() => {
            setIsSaveLoading(false);
          }, [1500]);
        },
      });
      return;
    }

    createPen({
      variables: data,
      onCompleted(data) {
        const { id, title, html, css, js } = data?.createPen;

        setActivePen({ id, title, html, css, js });
        setLoadedPenId(`${id}`);

        setTimeout(() => {
          setIsSaveLoading(false);
        }, [1500]);
      },
    });
  };

  useEffect(() => {
    // TODO: Actually authorize the user here
    const token = localStorage.getItem("AUTH_TOKEN");

    setIsAuthed(!!token);
  }, []);

  return (
    <>
      {isLoading && <LoadingSvg />}
      {(howToModal || isDemo || learnParam) && (
        <HowToContainer
          workspace={workspace}
          setExpand={setExpand}
          closeModal={() => {
            setHowToModal(false);
            setDemo(false);
          }}
          learnParam={learnParam}
          isDemo={isDemo}
        />
      )}
      <ListPens
        isOpen={isLoadModalOpen}
        setActivePen={(pen, loadWorkspace) => {
          setActivePen(pen, loadWorkspace);
          setIsLoadModalOpen(false);
        }}
        closeModal={() => {
          setIsLoadModalOpen(false);
          navigate(BASE_EDITOR_PATH);
        }}
        // handleSubmit={handleLoadFromCodeSubmit}
        setIsLoading={(value) => setIsLoadPenLoading(value)}
      />
      <SavePen
        isOpen={isSaveTemplateOpen}
        savePen={savePen}
        closeModal={() => {
          setIsSaveTemplateOpen(false);
          navigate(BASE_EDITOR_PATH);
        }}
      />
      {menuOpen && (
        <div className="menu-overlay-container">
          <div className="menu-overlay">
            <ActionButton
              value="New Pen"
              onClick={() => {
                setMenuOpen(false);

                setActivePen(null);

                workspace.HTML.clear();
                workspace.CSS.clear();
                workspace.JS.clear();

                localStorage.removeItem("HTML");
                localStorage.removeItem("CSS");
                localStorage.removeItem("JS");
                localStorage.removeItem("penId");
                setLoadedPenId("");
                setCode({ HTML: "", CSS: "", JS: "" });
              }}
              additionalClassNames="menu-button"
            />
            <ActionButton
              value="Learn"
              onClick={() => {
                setMenuOpen(false);
                setHowToModal(true);
              }}
              color="rgba(137, 246, 250, 0.41)"
              animate={!isAuthed && loadedPenId === ""}
              additionalClassNames="menu-button"
            />
            <ActionButton
              value="Load Pen"
              onClick={() => {
                setMenuOpen(false);

                if (!isAuthed) {
                  setLoginScreen(true);
                  return;
                }

                navigate(`${BASE_EDITOR_PATH}/load`);
              }}
              additionalClassNames="menu-button"
            />
            <ActionButton
              value="Save Pen"
              onClick={() => {
                onSavePen();
                setMenuOpen(false);
              }}
              additionalClassNames="menu-button"
            />
            <Link
              className="share-link"
              to={activePen ? `/projects/share/${activePen?.id}` : ""}
            >
              <ActionButton
                additionalClassNames="menu-button share-button"
                value="Share"
                onClick={() => setMenuOpen(false)}
              />
            </Link>
          </div>
        </div>
      )}
      <div className="Main">
        <Editor
          setExpand={setExpand}
          expand={expand.HTML}
          hide={expand.JS || expand.CSS}
          workspace={workspace?.HTML ? workspace.HTML : null}
          type="HTML"
          viewCode={viewCode.HTML}
          setViewCode={setViewCode}
        >
          <div
            id="blocklyHTMLDiv"
            style={viewCode.HTML ? { display: "none" } : { display: "block" }}
          />
          {/* {viewCode.HTML && (
            <CodeViewer code={removeIdsAndStyle(code.HTML, true)} type={HTML} />
          )} */}
        </Editor>
        <Editor
          setExpand={setExpand}
          expand={expand.CSS}
          hide={expand.HTML || expand.JS}
          workspace={workspace?.CSS ? workspace.CSS : null}
          type="CSS"
          viewCode={viewCode.CSS}
          setViewCode={setViewCode}
        >
          <div
            id="blocklyCSSDiv"
            style={viewCode.CSS ? { display: "none" } : { display: "block" }}
          />
          {/* {viewCode.CSS && (
            <CodeViewer code={removeIdsAndStyle(code.CSS, true)} type={CSS} />
          )} */}
        </Editor>
        <Editor
          setExpand={setExpand}
          expand={expand.JS}
          hide={expand.HTML || expand.CSS}
          workspace={workspace?.JS ? workspace.JS : null}
          type="JS"
          viewCode={viewCode.JS}
          setViewCode={setViewCode}
        >
          <div
            id="blocklyJSDiv"
            style={viewCode.JS ? { display: "none" } : { display: "block" }}
          />
          {/* {viewCode.JS && (
            <CodeViewer code={removeIdsAndStyle(code.JS, true)} type={JS} />
          )} */}
        </Editor>
        <Display code={code} />
      </div>
    </>
  );
};

export default MainEditor;
