import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";

const GET_ALL_PENS = gql`
  query getAllPens {
    getAllPens {
      id
      title
      html
      css
      js
    }
  }
`;

const ListPens = ({
  isOpen,
  setActivePen,
  closeModal,
  handleSubmit,
  setIsLoading,
}) => {
  const { data, loading } = useQuery(GET_ALL_PENS, { skip: !isOpen });
  const [showCodeForm, setShowCodeForm] = useState(null);

  const [formValues, setFormValues] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ [name]: value });
  };

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, [1500]);
    }
  }, [setIsLoading, loading]);

  return isOpen ? (
    <div id="pens-container" onClick={closeModal}>
      <div className="pens-list">
        {!!showCodeForm ? (
          <div style={{ width: "400px" }} onClick={(e) => e.stopPropagation()}>
            <h1 className="pens-title">{showCodeForm}</h1>
            <textarea
              id={showCodeForm.toLowerCase()}
              name={showCodeForm.toLowerCase()}
              style={{ width: "100%", height: "100px" }}
              onChange={handleChange}
            />
            <input
              className="load-button"
              type="button"
              value="Load Blocks"
              onClick={() => {
                if (showCodeForm === "HTML") {
                  // clear form
                  document.getElementById("html").value = "";
                  setShowCodeForm("CSS");

                  handleSubmit({ html: formValues.html });
                  return;
                }

                handleSubmit({ css: formValues.css });
              }}
            />
          </div>
        ) : (
          <>
            {/* <input
              className="load-button"
              type="button"
              value="Load code"
              style={{
                height: "fit-content",
                fontSize: "20px",
              }}
              onClick={(e) => {
                e.stopPropagation();

                setShowCodeForm("HTML");
              }}
            /> */}
          </>
        )}
        {showCodeForm ? (
          ""
        ) : data?.getAllPens?.length ? (
          data.getAllPens.map(({ id, title }) => (
            <div
              className="pens-slides"
              key={id}
              onClick={(e) => {
                e.stopPropagation();
                setActivePen(
                  data?.getAllPens.find((p) => p.id === id),
                  true
                );
              }}
            >
              <h1 className="pens-title">{title}</h1>
            </div>
          ))
        ) : (
          <h1 className="pens-title" style={{ color: "#4e606c" }}>
            Pens you save will appear here!
          </h1>
        )}
      </div>
    </div>
  ) : (
    ""
  );
};

export default ListPens;
