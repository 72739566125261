import React from "react";
import "../../styles/HowTo.css";
import HowToListItem from "./HowToListItem";
import {
  HOW_TO_CENTER_CONTENT_STEPS,
  ALIEN_LANDING_PAGE_STEPS,
  IMAGE_CAROUSEL_STEPS,
} from "../../constants/lessons";

const HOW_TO_CENTER_CONTENT = "HOW_TO_CENTER_CONTENT";
export const ALIEN_LANDING_PAGE = "ALIEN_LANDING_PAGE";
const IMAGE_CAROUSEL = "IMAGE_CAROUSEL";

export const howToTypes = {
  [HOW_TO_CENTER_CONTENT]: HOW_TO_CENTER_CONTENT_STEPS,
  [ALIEN_LANDING_PAGE]: ALIEN_LANDING_PAGE_STEPS,
  // [IMAGE_CAROUSEL]: IMAGE_CAROUSEL_STEPS,
};

const HOW_TOS = [
  { category: "code", title: "Centering content", type: HOW_TO_CENTER_CONTENT },
  { category: "page", title: "Alien landing page", type: ALIEN_LANDING_PAGE },
  // { category: "art", title: "Making art with canvas" },
  // { category: "howto", title: "Image Carousel", type: IMAGE_CAROUSEL },
  // { category: "game", title: "Snake Game" },
  // { category: "art", title: "Drawing a circle" },
];

const HowToList = ({ closeModal, setSelectedLesson }) => {
  const handleLessonClick = (selectedLesson) => {
    const { type } = selectedLesson;
    const lesson = howToTypes[type];
    setSelectedLesson(lesson);
  };

  return (
    <div className="full-page-modal" onClick={closeModal}>
      <div
        className="how-to-list"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="how-to-list-items">
          {HOW_TOS.map((lesson, i) => (
            <HowToListItem
              key={i}
              lesson={lesson}
              handleLessonClick={handleLessonClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowToList;
