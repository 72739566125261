/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Dark theme.
 */

import Blockly from "blockly/core";

// Temporarily required to ensure there's no conflict with
// Blockly.Themes.Dark
Blockly.registry.unregister("theme", "dark");

/**
 * Dark theme.
 */
export default Blockly.Theme.defineTheme("dark", {
  base: Blockly.Themes.Classic,
  categoryStyles: {
    list_category: {
      colour: "#4a148c",
    },
    logic_category: {
      colour: "#8b4513",
    },
    loop_category: {
      colour: "#85E21F",
    },
    text_category: {
      colour: "#EFB26E",
    },
  },
  blockStyles: {
    list_blocks: {
      colourPrimary: "#4a148c",
      colourSecondary: "#394956",
      colourTertiary: "#CDB6E9",
    },
    logic_blocks: {
      colourPrimary: "#8b4513",
      colourSecondary: "#e56a6a",
      colourTertiary: "#C5EAFF",
    },
    loop_blocks: {
      colourPrimary: "#85E21F",
      colourSecondary: "#e56a6a",
      colourTertiary: "#C5EAFF",
    },
    text_blocks: {
      colourPrimary: "#EFB26E",
      colourSecondary: "#e56a6a",
      colourTertiary: "#C5EAFF",
    },
  },
  componentStyles: {
    workspaceBackgroundColour: "#4e606c",
    toolboxBackgroundColour: "#384a56",
    toolboxForegroundColour: "#fff",
    flyoutBackgroundColour: "#384a56",
    flyoutForegroundColour: "#f8f8f2",
    flyoutOpacity: 1,
    scrollbarColour: "#fff",
    insertionMarkerColour: "#fff",
    insertionMarkerOpacity: 0.3,
    scrollbarOpacity: 0.4,
    cursorColour: "#d0d0d0",
    blackBackground: "#333",
  },
});
