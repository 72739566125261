/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import * as Blockly from "blockly/core";

/**
 * Saves the state of the workspace to browser's local storage.
 * @param {Blockly.Workspace} workspace Blockly workspace to save.
 */
export const save = function (workspace, type) {
  const data = Blockly.serialization.workspaces.save(workspace);
  window.localStorage?.setItem(type, JSON.stringify(data));
};

/**
 * Loads saved state from local storage into the given workspace.
 * @param {Blockly.Workspace} workspace Blockly workspace to load into.
 */
export const load = function (workspace, type) {
  const data = window.localStorage?.getItem(type);
  if (!data) return;

  try {
    // Don't emit events during loading.
    Blockly.Events.disable();
    Blockly.serialization.workspaces.load(JSON.parse(data), workspace, false);
    Blockly.Events.enable();
  } catch (e) {
    console.log("serialization error, likely loading HTML, CSS and JS");
  }
};

export const loadFromCode = async function (workspace, data) {
  if (!data) return;

  try {
    // Don't emit events during loading.
    Blockly.Events.disable();
    Blockly.serialization.workspaces.load(JSON.parse(data), workspace, false);
    Blockly.Events.enable();
  } catch (e) {
    console.log("serialization error, likely loading HTML, CSS and JS");
  }
};

export const loadWorkspaces = function (workspace) {
  Object.keys(workspace).forEach((key) => {
    load(workspace[key], key);
  });
};

export const loadWorkspacesFromCode = function (workspace, data) {
  Object.keys(workspace).forEach((key) => {
    loadFromCode(workspace[key], data[key]);
  });
};

export const saveWorkspaces = function (workspace) {
  Object.keys(workspace).forEach((key) => {
    save(workspace[key], key);
  });
};
