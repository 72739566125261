import React from "react";
import arrowsExpand from "../static/arrows-angle-expand.svg";
import arrowsContract from "../static/arrows-angle-contract.svg";

const Tab = ({ type, setExpand, expand, icon }) => {
  const TYPES = ["HTML", "CSS", "JS"];

  const renderTabs = () => {
    return (
      <>
        {TYPES.map((t, index) => (
          <React.Fragment key={index}>
            {expand || type === t ? renderTab(t, type === t) : ""}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderTab = (overrideType, isActive, icon = "") => (
    <div
      className="tab"
      onClick={() => {
        setExpand(isActive ? type : overrideType);
      }}
      style={expand && isActive ? { backgroundColor: "rgb(57 73 86)" } : {}}
    >
      {isActive ? type : overrideType}{" "}
      {icon ? (
        icon
      ) : (
        <img
          src={expand && isActive ? arrowsContract : arrowsExpand}
          style={{ marginLeft: "0.5rem", height: 12 }}
        />
      )}
    </div>
  );

  return (
    <div className="tab-container">
      {/* {renderTab("", false, icon)} */}
      {renderTabs()}
    </div>
  );
};

export default Tab;
