import * as Blockly from "blockly";

import { HTML, CSS } from "../constants/types";
import {
  createDiv,
  createParagraph,
  createHeader,
  createImage,
  createClassSelectorBlock,
} from "../blockUtils";
import { addClassOption } from "../blocks/dynamicDropdowns";
import { CSS_BLOCK_TYPES } from "../blocks/css";
import { ARG_TO_INPUT_TYPES } from "../toolbox/css";

export const createAnimationBlock = (cssBlockly, { NAME }) => {
  const animationBlock = cssBlockly.newBlock("add_animation");
  animationBlock.initSvg();
  animationBlock.render();

  const nameInputBlock = cssBlockly.newBlock("text");
  nameInputBlock.setFieldValue(NAME, "TEXT");
  nameInputBlock.initSvg();
  nameInputBlock.render();
  nameInputBlock.setColour("#e56a6a");

  nameInputBlock.outputConnection.connect(
    animationBlock.inputList[0].connection
  );

  return animationBlock;
};

const createStyleBlock = (
  cssBlockly,
  selector,
  type,
  values,
  parentBlockArgPosition = 0
) => {
  const { args } = CSS_BLOCK_TYPES[type];

  const styleBlock = cssBlockly.newBlock(type);
  styleBlock.initSvg();
  styleBlock.render();

  // connect to selector
  if (selector) {
    const position = parentBlockArgPosition + 1;
    selector.inputList[position].connection.connect(
      styleBlock.previousConnection
    );
  }

  args.forEach((argName) => {
    const arg = argName.toUpperCase();
    if (arg === "DROPDOWN") {
      styleBlock.setFieldValue(values["VALUE"], "VALUE");
      return;
    }

    const { defaultValueType, inputType } = ARG_TO_INPUT_TYPES[arg];

    const styleBlockValue = cssBlockly.newBlock(inputType);
    styleBlockValue.initSvg();
    styleBlockValue.render();
    styleBlockValue.setFieldValue(values[arg], defaultValueType);
    styleBlockValue.outputConnection.connect(
      styleBlock.inputList[0].connection
    );
  });
};

const BLOCK_TO_FN = {
  div: { fn: createDiv, workspaceType: HTML },
  add_paragraph: { fn: createParagraph, workspaceType: HTML },
  add_header: { fn: createHeader, workspaceType: HTML },
  style_classes: { fn: createClassSelectorBlock, workspaceType: CSS },
  add_animation: { fn: createAnimationBlock, workspaceType: CSS },
  add_image: { fn: createImage, workspaceType: HTML },
};

export const getBlockWorkspaceType = (blockType) => {
  if (BLOCK_TO_FN[blockType]) {
    return BLOCK_TO_FN[blockType].workspaceType;
  }

  if (CSS_BLOCK_TYPES[blockType]) {
    return CSS;
  }

  return HTML;
};

export const moveBlock = (block, x, y) => {
  if (!block) return;

  block.moveTo(new Blockly.utils.Coordinate(x, y));
};

export const createBlock = (
  blockType,
  args,
  workspace,
  parentBlock = null,
  parentBlockArgPosition = 0
) => {
  if (CSS_BLOCK_TYPES[blockType]) {
    createStyleBlock(
      workspace[CSS],
      parentBlock,
      blockType,
      args,
      parentBlockArgPosition
    );
  }

  const blockData = BLOCK_TO_FN[blockType];

  if (!blockData) return;

  const workspaceToGenerate = workspace[blockData.workspaceType];
  const block = blockData.fn(workspaceToGenerate, args);

  if (Object.keys(args).includes("CLASS")) {
    addClassOption(args["CLASS"], block.id);
  }

  moveBlock(block, 100, 100);

  if (parentBlock) {
    parentBlock.inputList[1].connection.connect(block.previousConnection);
  }

  return block;
};
