import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = ({
  setAuthToken,
  user = null,
  setMenuOpen,
  menuOpen,
  loginScreen,
  setLoginScreen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [signInWithEmail, setSignInWithEmail] = useState(false);
  const isLoggedIn = !!user;
  const hideOptions = location.pathname.startsWith("/projects");
  const hideNavbar = location.pathname.startsWith("/projects/share");

  const handleAvatarClick = () => {
    setLoginScreen((prevState) => !prevState);
  };

  return hideNavbar ? (
    <div />
  ) : (
    <div className="blog-navbar">
      <div
        className="logo-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          <h1 onClick={() => navigate("/")} className="blog-logo">
            Blockpen{" "}
          </h1>
          {hideOptions && (
            <ExpandCircleDownIcon
              onClick={() => setMenuOpen((prevState) => !prevState)}
              className="expand-icon"
              sx={
                menuOpen
                  ? {
                      transform: "scaleY(-1)",
                      fontSize: 30,
                    }
                  : { fontSize: 30 }
              }
            />
          )}
        </span>
        {hideOptions ? (
          ""
        ) : (
          <span className="options-menu">
            <a href="/projects/new" className="navitem">
              Create
            </a>
            <a href="/learn" className="navitem">
              Learn
            </a>
            <a href="/about" className="navitem">
              About
            </a>
          </span>
        )}
        <span
          style={
            isLoggedIn
              ? {
                  minWidth: "100px",
                  height: 50,
                  backgroundColor: "#1f4f64",
                  borderRadius: "25px",
                  filter: "drop-shadow(2px 4px 6px black)",
                  padding: "5px 0 5px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { minWidth: "100px" }
          }
        >
          {loginScreen && (
            <div
              style={{
                position: "absolute",
                width: "400px",
                height: isLoggedIn || !signInWithEmail ? "150px" : "250px",
                backgroundColor: "#4f606c",
                borderRadius: "25px",
                transform: isLoggedIn
                  ? "translate(-40%, 70%)"
                  : signInWithEmail
                  ? "translate(-70%, 25%)"
                  : "translate(-70%, 40%)",
                filter: "drop-shadow(2px 4px 6px black)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!isLoggedIn ? (
                signInWithEmail ? (
                  <>
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="10"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      style={{
                        width: 15,
                        color: "white",
                        position: "absolute",
                        top: 22.5,
                        left: 15,
                        cursor: "pointer",
                      }}
                      onClick={() => setSignInWithEmail(false)}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 19.5L8.25 12l7.5-7.5"
                      ></path>
                    </svg>
                    <form
                      className="auth-form dropdown"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span className="label">Username</span>
                      <input
                        type="email"
                        name="email"
                        placeholder="email"
                        className="auth-input"
                        onChange={() => {}}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      <span className="label">Password</span>
                      <input
                        type="password"
                        name="password"
                        placeholder="password"
                        className="auth-input"
                        onChange={() => {}}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      {false && <h4 className="validation">{{}?.message}</h4>}
                      <button
                        className="auth-button"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{ fontSize: 18, margin: 0 }}
                      >
                        Submit
                      </button>
                    </form>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => setSignInWithEmail(true)}
                      className="email-login-button"
                    >
                      Login with Email
                    </button>
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        setAuthToken(credentialResponse.credential);
                        setLoginScreen(false);
                      }}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />
                  </>
                )
              ) : (
                <h2 style={{ color: "white", fontFamily: "Sigmar One" }}>
                  Welcome {user.name}
                </h2>
              )}
            </div>
          )}
          <span
            className="pts"
            style={isLoggedIn ? { opacity: 1 } : { opacity: 0 }}
          >
            10
          </span>
          <img
            src="https://cdn.discordapp.com/attachments/1010577922941263892/1104450830649786441/PNG_image_28.PNG"
            width="50"
            height="50"
            className="user-avatar"
            style={
              !isLoggedIn ? { filter: "drop-shadow(2px 4px 6px black)" } : {}
            }
            onClick={handleAvatarClick}
          />
        </span>
      </div>
    </div>
  );
};

export default Navbar;
