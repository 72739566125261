import * as Blockly from "blockly";
import { stripId } from ".";

export const htmlGenerator = new Blockly.Generator("HTML");

htmlGenerator.PRECEDENCE = 0;

htmlGenerator.scrub_ = function (block, code, thisOnly) {
  const nextBlock = block.nextConnection && block.nextConnection.targetBlock();
  if (nextBlock && !thisOnly) {
    return code + htmlGenerator.blockToCode(nextBlock);
  }
  return code;
};

htmlGenerator["text"] = function (block) {
  const textValue = block.getFieldValue("TEXT");
  const code = `${textValue}`;
  return [code, htmlGenerator.PRECEDENCE];
};

htmlGenerator["colour_picker"] = function (block) {
  // Colour picker.
  const code = block.getFieldValue("COLOUR");
  return [code, htmlGenerator.PRECEDENCE];
};

htmlGenerator["math_number"] = function (block) {
  const code = String(block.getFieldValue("NUM"));
  return [code, htmlGenerator.PRECEDENCE];
};

htmlGenerator["div"] = function (block) {
  const id = stripId(block.id);
  const statement = htmlGenerator.statementToCode(block, "BODY");
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);

  const code = `<div class="${className}" data-id="${id}">${statement}</div>`;

  return code;
};

htmlGenerator["span"] = function (block) {
  const id = stripId(block.id);
  const statement = htmlGenerator.statementToCode(block, "BODY");
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);

  const code = `<span class="${className}" data-id="${id}">${statement}</span>`;

  return code;
};

htmlGenerator["add_list"] = function (block) {
  const id = stripId(block.id);
  const statement = htmlGenerator.statementToCode(block, "BODY");
  const type = block.getFieldValue("TYPE");
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);

  const tag = type === "ordered" ? "ol" : "ul";

  const code = `<${tag} class="${className}" data-id="${id}">${statement}</${tag}>`;

  return code;
};

htmlGenerator["list_item"] = function (block) {
  const id = stripId(block.id);
  const text = htmlGenerator.valueToCode(block, "TEXT", 0) || "";
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);

  const code = `<li class="${className}" data-id="${id}">${text}</li>`;

  return code;
};

htmlGenerator["add_input"] = function (block) {
  const id = stripId(block.id);
  const text = htmlGenerator.valueToCode(block, "TEXT", 0) || "";
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);

  const code = `<input type="text" placeholder="${text}" class="${className}" data-id="${id}"/>`;

  return code;
};

htmlGenerator["add_image"] = function (block) {
  const id = stripId(block.id);
  let src = htmlGenerator.valueToCode(block, "IMAGE_URL", 0) || "";
  src = src.replace(/'/g, '"');
  const width = htmlGenerator.valueToCode(block, "WIDTH", 0) || "";
  const height = htmlGenerator.valueToCode(block, "HEIGHT", 0) || "";
  let className = htmlGenerator.valueToCode(block, "CLASS", 0) || "";

  const code = `<img src=${src} class="${className}" width=${width} height=${height} data-id="${id}"/>`;

  return code;
};

htmlGenerator["add_header"] = function (block) {
  const id = stripId(block.id);
  const text = htmlGenerator.valueToCode(block, "TEXT", 0);
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);
  const headerType = block.getFieldValue("HEADER_SIZE");

  const code = `<${headerType} class="${className}" data-id="${id}">${text}</${headerType}>`;

  return code;
};

htmlGenerator["add_paragraph"] = function (block) {
  const id = stripId(block.id);
  const text = htmlGenerator.valueToCode(block, "TEXT", 0);
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);

  const code = `<p class="${className}" data-id="${id}">${text}</p>`;

  return code;
};

htmlGenerator["add_anchor_tag"] = function (block) {
  const id = stripId(block.id);
  const text = htmlGenerator.valueToCode(block, "TEXT", 0);
  let href = htmlGenerator.valueToCode(block, "HREF", 0);
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);

  if (!href.includes("http")) {
    href = "http://" + href;
  }

  const code = `<a href=${href} target="_blank" class="${className}" data-id="${id}">${text}</p>`;

  return code;
};

htmlGenerator["add_button"] = function (block) {
  const id = stripId(block.id);
  const text = htmlGenerator.valueToCode(block, "TEXT", 0);
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);
  const click = block.getFieldValue("OPTIONS");

  const code = `<button value="test" class="${className}" data-id="${id}" onclick="${click}()">${text}</button>`;

  return code;
};

htmlGenerator["add_audio"] = function (block) {
  const id = stripId(block.id);
  const source = htmlGenerator.valueToCode(block, "SOURCE", 0);
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);
  const isAutoplay = block.getFieldValue("AUTOPLAY") === "autoplay";

  const code = `<audio class=${className} controls ${
    isAutoplay ? "autoplay" : ""
  } data-id="${id}"><source src=${source} /></audio>`;

  return code;
};

htmlGenerator["add_video"] = function (block) {
  const id = stripId(block.id);
  const source = htmlGenerator.valueToCode(block, "SOURCE", 0);
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);
  const width = htmlGenerator.valueToCode(block, "WIDTH", 0) || "";
  const height = htmlGenerator.valueToCode(block, "HEIGHT", 0) || "";
  const isAutoplay = block.getFieldValue("AUTOPLAY") === "autoplay";

  const code = `<video width=${width} height=${height} class=${className} ${
    isAutoplay ? "autoplay" : ""
  } data-id="${id}"><source src=${source} /></video>`;

  return code;
};

htmlGenerator["add_canvas"] = function (block) {
  const id = stripId(block.id);
  const idName = htmlGenerator.valueToCode(block, "ID", 0);
  const width = htmlGenerator.valueToCode(block, "WIDTH", 0) || "";
  const height = htmlGenerator.valueToCode(block, "HEIGHT", 0) || "";

  const code = `<canvas width=${width} height=${height} id=${idName} data-id="${id}" />`;

  return code;
};

htmlGenerator["add_svg"] = function (block) {
  const id = stripId(block.id);
  const className = htmlGenerator.valueToCode(block, "CLASS", 0);
  const type = block.getFieldValue("TYPE");

  const code = `
    <span class="material-symbols-outlined ${className}" data-id="${id}" style="font-size: 50px;">
      ${type}
    </span>
    `;

  return code;
};
