import { useCallback, useEffect, useState } from "react";
import * as Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
import { generator } from "../generator";
import { toolbox as jsToolbox } from "../toolbox/javascript";
import { toolbox as htmlToolbox } from "../toolbox/html";
import { toolbox as cssToolbox } from "../toolbox/css";
import Theme from "../theme";
import { htmlGenerator } from "../generator/htmlGenerator";
import { cssGenerator } from "../generator/cssGenerator";
import { ZOOM_DEFAULTS } from "../constants/blocklyDefaults";
import { blocks } from "../blocks/index";
import { HTML, JS } from "../constants/types";

const WORKSPACE_LANGUAGES = {
  HTML: {
    toolbox: htmlToolbox,
    generator: htmlGenerator,
  },
  CSS: {
    toolbox: cssToolbox,
    generator: cssGenerator,
  },
  JS: {
    toolbox: jsToolbox,
    generator: Object.assign(javascriptGenerator, generator),
  },
};

const useWorkspace = ({ callbacks = [] }) => {
  const [workspace, setWorkspace] = useState({ HTML: "", CSS: "", JS: "" });
  const [code, setCode] = useState({ HTML: "", CSS: "", JS: "" });

  // Register the blocks and generator with Blockly
  Blockly.common.defineBlocks(blocks);

  // We can fire custom events using `fireEventListener` if we want to do something with the code for the tutorial

  const changeHandler = useCallback(async (e, type, blockly) => {
    // We have access to the event type and other important info on the event object
    const value = WORKSPACE_LANGUAGES[type];

    if (
      e.isUiEvent ||
      e.type == Blockly.Events.FINISHED_LOADING ||
      blockly.isDragging()
    )
      return;

    const code = value.generator.workspaceToCode(blockly);

    setCode((prev) => ({ ...prev, [type]: code }));
  }, []);

  const dropdownEventHandler = useCallback((e, type, blockly) => {
    const isCreateEvent = e.type === Blockly.Events.BLOCK_CREATE;
    const isChangeEvent = e.type === Blockly.Events.BLOCK_CHANGE;

    const cssIds = callbacks.getClassOptions();
    const jsIds = callbacks.getFunctionOptions();

    if (type === HTML) {
      if (isCreateEvent) {
        const { id, fields } = e?.json?.inputs?.CLASS?.shadow || {};

        callbacks.addClassOption(fields?.TEXT, id);
      }

      if (isChangeEvent && cssIds.includes(e.blockId)) {
        const name = e.newValue;
        const id = e.blockId;

        if (!name || !id) return;

        callbacks.addClassOption(name, id);
      }
    }

    if (type === JS) {
      if (isCreateEvent) {
        const id = e.blockId;
        const name = e?.json?.fields?.NAME;

        if (!name || !id) return;

        callbacks.addFunctionOption(name, id);
      } else if (isChangeEvent && jsIds.includes(e.blockId)) {
        const id = e.blockId;
        const name = e?.newValue;

        if (!name || !id) return;

        callbacks.addFunctionOption(name, id);
      }
    }
  }, []);

  useEffect(() => {
    // Set up UI elements and inject Blockly
    for (const [key, value] of Object.entries(WORKSPACE_LANGUAGES)) {
      const blocklyDiv = document.getElementById(`blockly${key}Div`);

      if (blocklyDiv?.firstChild) return;

      const blockly = Blockly.inject(blocklyDiv, {
        toolbox: value.toolbox,
        theme: Theme,
        zoom: ZOOM_DEFAULTS,
      });

      blockly.addChangeListener((e) => changeHandler(e, key, blockly));
      blockly.addChangeListener((e) => dropdownEventHandler(e, key, blockly));
      setWorkspace((prev) => ({ ...prev, [key]: blockly }));
    }
  }, []);

  /**
   * Manual trigger to reload workspaces
   */
  const reloadWorkspaces = () => {
    for (const [key] of Object.entries(WORKSPACE_LANGUAGES)) {
      changeHandler({}, key, workspace[key]);
    }
  };

  return { code, workspace, setCode, reloadWorkspaces };
};

export default useWorkspace;
