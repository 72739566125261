export const STYLES_TO_JS = {
  color: "color",
  "background-color": "backgroundColor",
  "background-image": "backgroundImage",
  "background-size": "backgroundSize",
  "background-repeat": "backgroundRepeat",
  "border-radius": "borderRadius",
  border: "border",
  "font-size": "fontSize",
  width: "width",
  height: "height",
  margin: "margin",
  "margin-right": "marginRight",
  "margin-left": "marginLeft",
  "margin-top": "marginTop",
  "margin-bottom": "marginBottom",
  padding: "padding",
  "padding-right": "paddingRight",
  "padding-left": "paddingLeft",
  "padding-top": "paddingTop",
  "padding-bottom": "paddingBottom",
  "text-align": "textAlign",
  "font-family": "fontFamily",
  "box-shadow": "boxShadow",
  opacity: "opacity",
  display: "display",
  "flex-direction": "flexDirection",
  "justify-content": "justifyContent",
  "align-items": "alignItems",
  transition: "transition",
  "animation-name": "animationName",
  "animation-duration": "animationDuration",
  "animation-iteration-count": "animationIterationCount",
  "animation-direction": "animationDirection",
  "background-position": "backgroundPosition",
  "text-shadow": "textShadow",
  cursor: "cursor",
  top: "top",
  bottom: "bottom",
  left: "left",
  right: "right",
  "z-index": "zIndex",
};
