const addTextCallback = function add_text(text, color) {
  const outputDiv = document.getElementById("output");
  const textEl = document.createElement("p");
  textEl.innerText = text;
  textEl.style.color = color;
  outputDiv.appendChild(textEl);
};

const addAlertCallback = function add_alert(text) {
  alert(text);
};

const addConsoleLogCallback = function console_log(text) {
  console.log(text);
};

const openLinkCallback = function open_link(src) {
  window.open(src, "_blank");
};

const elementValueCallback = function element_value(className) {
  const el = document.querySelector(`.${className}`);

  if (el) {
    return el.value;
  }

  return "";
};

const elementByClassNameCallback = function element_value(className) {
  const el = document.querySelector(`.${className}`);

  if (el) {
    return el;
  }

  return null;
};

const setElementCallback = function set_element_property(
  element,
  propertyType,
  value
) {
  element[propertyType] = value;
};

const storeDataCallback = function database_set(name, value, isDebounced) {
  if (!isDebounced) return;

  const data = {
    operationName: "setValue",
    variables: { name, value },
    query:
      "mutation setValue($name: String!, $value: String!) {\n  setValue(name: $name, value: $value) {\n id }\n}",
  };

  fetch("http://localhost:3000/", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "content-type": "application/json" },
  });
};

const getDataCallback = async function database_get(datastore, isDebounced) {
  if (!isDebounced) return;

  const data = {
    operationName: "getValue",
    variables: { name: datastore },
    query:
      "query getValue($name: String!) {\n  getValue(name: $name) {\n values }\n}",
  };
  const res = await fetch("http://localhost:3000/", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "content-type": "application/json" },
  });

  const responseData = await res.json();

  return responseData?.data?.getValue?.values;
};

const getCanvasCallback = function get_canvas(idName) {
  const canvas = document.getElementById(idName);

  if (!canvas) return null;

  const ctx = canvas.getContext("2d");

  return ctx;
};

const drawLineCallback = function draw_line(
  canvasName,
  x1,
  y1,
  x2,
  y2,
  color,
  lineWidth
) {
  // eslint-disable-next-line no-undef
  const ctx = get_canvas(canvasName);
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
};

const setElementStyleCallback = function set_element_style(element, styleObj) {
  Object.keys(styleObj).forEach((key) => {
    element.style[key] = styleObj[key];
  });
};

const addEventListenerCallback = function add_event_listener(
  element,
  type,
  callback
) {
  // might need to wrap the callback depending on what's passed into the event listener statement
  element.addEventListener(type, callback);
};

export {
  addTextCallback,
  addAlertCallback,
  openLinkCallback,
  elementValueCallback,
  elementByClassNameCallback,
  setElementCallback,
  storeDataCallback,
  getDataCallback,
  addConsoleLogCallback,
  getCanvasCallback,
  drawLineCallback,
  setElementStyleCallback,
  addEventListenerCallback,
};
