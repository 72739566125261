/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

export const addInput = {
  type: "add_input",
  message0: "Input %1 %2",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 220,
};

export const addImage = {
  type: "add_image",
  message0: "Add image %1 with width %2 and height %3 %4",
  args0: [
    {
      type: "input_value",
      name: "IMAGE_URL",
      check: "String",
    },
    {
      type: "input_value",
      name: "WIDTH",
      check: "Number",
    },
    {
      type: "input_value",
      name: "HEIGHT",
      check: "Number",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  tooltip: "",
  helpUrl: "",
};

export const addHeader = {
  type: "add_header",
  message0: "Add header %1 %2 %3",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
    {
      type: "field_dropdown",
      name: "HEADER_SIZE",
      options: [
        ["h1", "h1"],
        ["h2", "h2"],
        ["h3", "h3"],
        ["h4", "h4"],
        ["h5", "h5"],
        ["h6", "h6"],
      ],
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  tooltip: "",
  helpUrl: "",
};

export const addParagraph = {
  type: "add_paragraph",
  message0: "Add paragraph %1 %2",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  tooltip: "",
  helpUrl: "",
};

export const addAnchorTag = {
  type: "add_anchor_tag",
  message0: "Add link href %1 with text %2 %3",
  args0: [
    {
      type: "input_value",
      name: "HREF",
      check: "String",
    },
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  tooltip: "",
  helpUrl: "",
};

export const addDiv = {
  type: "div",
  message0: "div %1 %2",
  args0: [
    { type: "input_value", name: "CLASS" },
    { type: "input_statement", name: "BODY" },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 220,
};

export const addList = {
  type: "add_list",
  message0: "List %1 %2 %3",
  args0: [
    { type: "input_value", name: "CLASS" },
    {
      type: "field_dropdown",
      name: "TYPE",
      options: [
        ["ordered", "ordered"],
        ["unordered", "unordered"],
      ],
    },
    { type: "input_statement", name: "BODY" },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 220,
};

export const addListItem = {
  type: "list_item",
  message0: "List item %1 %2",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
};

export const addSpan = {
  type: "span",
  message0: "span %1 %2",
  args0: [
    { type: "input_value", name: "CLASS" },
    { type: "input_statement", name: "BODY" },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 220,
};

export const addButton = {
  type: "add_button",
  message0: "Button %1 %2",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 200,
};

export const addAudio = {
  type: "add_audio",
  message0: "Audio %1 %2 play when %3",
  args0: [
    {
      type: "input_value",
      name: "SOURCE",
      check: "String",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
    {
      type: "field_dropdown",
      name: "AUTOPLAY",
      options: [
        ["user clicks", "user clicks"],
        ["autoplay", "autoplay"],
      ],
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 200,
  inputsInline: false,
};

export const addVideo = {
  type: "add_video",
  message0: "Video %1 %2 width %3 height %4 play when %5",
  args0: [
    {
      type: "input_value",
      name: "SOURCE",
      check: "String",
    },
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
    {
      type: "input_value",
      name: "WIDTH",
      check: "Number",
    },
    {
      type: "input_value",
      name: "HEIGHT",
      check: "Number",
    },
    {
      type: "field_dropdown",
      name: "AUTOPLAY",
      options: [
        ["user clicks", "user clicks"],
        ["autoplay", "autoplay"],
      ],
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 200,
  inputsInline: false,
};

export const addCanvas = {
  type: "add_canvas",
  message0: "Canvas id %1 width %2 height %3",
  args0: [
    {
      type: "input_value",
      name: "ID",
      check: "String",
    },
    {
      type: "input_value",
      name: "WIDTH",
      check: "Number",
    },
    {
      type: "input_value",
      name: "HEIGHT",
      check: "Number",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 200,
  inputsInline: false,
};

export const addSvg = {
  type: "add_svg",
  message0: "Svg %1 %2",
  args0: [
    {
      type: "input_value",
      name: "CLASS",
      check: "String",
    },
    {
      type: "field_dropdown",
      name: "TYPE",
      options: [
        ["circle", "add_circle"],
        ["search", "search"],
        ["home", "home"],
        ["menu", "menu"],
        ["settings", "settings"],
        ["add", "add"],
        ["favorite", "favorite"],
        ["check_circle", "check_circle"],
        ["delete", "delete"],
        ["star", "star"],
        ["arrow right", "arrow_right"],
        ["arrow left", "arrow_left"],
      ],
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 200,
  inputsInline: false,
};

export const blocks = [
  addDiv,
  addInput,
  addImage,
  addHeader,
  addParagraph,
  addSpan,
  addAnchorTag,
  addList,
  addListItem,
  addAudio,
  addVideo,
  addCanvas,
  addSvg,
];
