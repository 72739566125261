import React, { useEffect, useState } from "react";

const ActionButton = ({
  onClick,
  value,
  type = "",
  className = "",
  color = "",
  animate = false,
  additionalClassNames = "",
}) => {
  const classNames = `load-button ${additionalClassNames}`;
  const [additionalStyles, setAdditionalStyles] = useState(
    color ? { backgroundColor: color } : {}
  );

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        setAdditionalStyles({
          ...additionalStyles,
          animation: "tilt-n-move-shaking .35s ease-in-out 2",
        });
      }, 500);
    }
  }, [animate]);

  return (
    <button
      className={classNames || "load-button"}
      type={type || "button"}
      style={{ height: "fit-content", ...additionalStyles }}
      onClick={onClick}
    >
      {value}
    </button>
  );
};

export default ActionButton;
