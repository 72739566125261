import React, { useEffect } from "react";
import * as Blockly from "blockly";
import Tab from "./components/Tab";
import TerminalIcon from "@mui/icons-material/Terminal";
const Editor = ({
  type,
  setExpand,
  expand,
  hide,
  children,
  workspace,
  viewCode,
  setViewCode,
}) => {
  const style = expand ? { width: "100%" } : hide ? { display: "none" } : {};

  useEffect(() => {
    if (expand) {
      Blockly.svgResize(workspace);
    }
  }, [expand]);

  const additionalStyles = viewCode ? { flexGrow: 0.25 } : { flexGrow: 1 };

  return (
    <div className="editor" style={{ ...style, ...additionalStyles }}>
      <Tab
        type={type}
        setExpand={setExpand}
        expand={expand}
        icon={
          <TerminalIcon
            onClick={() => {
              setViewCode(type);
            }}
          />
        }
      />
      {children}
    </div>
  );
};

export default Editor;
