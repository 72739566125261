export const MAKE_A_LANDING_PAGE = {
  header: "How to make a landing page",
  subheader:
    "How to get started making a landing page with HTML & CSS in Blockpen",
  headerImg:
    "https://media.discordapp.net/attachments/1010577922941263892/1109859239779893360/PNG_image.PNG?width=1164&height=1015",
  headerAlt: "Alien landing",
  sections: [
    {
      title: "Intro",
      items: [
        {
          title: "What is a landing page?",
          id: "what-is-a-landing-page",
          body: (
            <span>
              A landing page is a page that welcomes visitors to a website. It's
              the first thing that people see when they visit your website. A
              landing page is a standalone web page, designed specifically for
              marketing or advertising. It's where a visitor 'lands' after they
              click on a link in an email, an ad, YouTube, social media, or
              similar places on the web. Unlike general websites, a landing page
              has a single focus. This is called its <b>call to action</b>{" "}
              (CTA), which could be signing up for a newsletter, registering for
              a webinar, buying a product, etc. It's important to make a good
              first impression!
            </span>
          ),
        },
      ],
    },
    {
      title: "Types of landing pages",
      items: [
        {
          title: "The many types of landing pages",
          id: "the-many-types-of-landing-pages",
          body: (
            <span>
              There are many types of landing pages. Here are some examples:
              <ul>
                <li>
                  <span style={{ fontWeight: "800" }}>Lead capture pages</span>{" "}
                  - These pages are designed to capture a visitor's information,
                  such as their name and email address. They usually have a form
                  that visitors fill out in order to get something in return,
                  such as a free ebook or newsletter.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>Click-through pages</span>{" "}
                  - These pages are designed to encourage visitors to click
                  through to another page. They usually have a button that
                  visitors click on in order to get something in return, such as
                  a free ebook or newsletter.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>Sales pages</span> - These
                  pages are designed to sell a product or service. They usually
                  have a button that visitors click on in order to get something
                  in return, such as a free ebook or newsletter.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>Video pages</span> - These
                  pages are designed to get visitors to watch a video about a
                  product or service.
                </li>
              </ul>
            </span>
          ),
        },
      ],
    },
    {
      title: "Building your first landing page",
      items: [
        {
          title: "Getting started",
          id: "getting-started",
          body: (
            <span>
              Want to dive right in? Blockpen offers interactive tutorials that
              lead you step by step through the process of building a landing
              page.
            </span>
          ),
          tieInLesson: {
            text: "Build an alien landing page",
            href: "/projects/new?learn=ALIEN_LANDING_PAGE",
          },
        },
        {
          title: "What you'll need",
          id: "what-a-landing-page-needs",
          body: (
            <span>
              A landing page needs a few things:
              <ul>
                <li>
                  <span style={{ fontWeight: "800" }}>A title</span> - This
                  should be the name of your product or service.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>A description</span> -
                  This should be a short description of your product or service.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>A hero</span> - This
                  should be a large image that captures the essence of your
                  product or service.
                </li>
                <li>
                  <span style={{ fontWeight: "800" }}>A call to action</span> -
                  This should be a button that visitors click on in order to get
                  something in return, such as a free ebook or newsletter.
                </li>
              </ul>
            </span>
          ),
        },
        {
          title: "How to build it",
          id: "how-to-build-a-landing-page",
          body: (
            <span>
              To build a landing page, you need to use <b>HTML</b> and{" "}
              <b>CSS</b>. HTML is used to structure the content of your page,
              such as the title, description, hero, and call to action. CSS is
              used to style the content of your page, such as the color, font,
              and size of the text. You can use Blockpen to build it or you can
              use an online code editor like{" "}
              <a
                href="https://codepen.io"
                target="_blank"
                style={{ fontWeight: "800", color: "#28323a" }}
              >
                Codepen
              </a>
              <br />
              If you use Blockpen you can drag and drop HTML and CSS blocks to
              create your landing page.
            </span>
          ),
          tieInLesson: {
            text: "Build a landing page",
            href: "/projects/new",
          },
        },
      ],
    },
    {
      title: "Build it with code",
      items: [
        {
          title: "Start with HTML",
          id: "start-with-html",
          body: (
            <span>
              If you want to build your landing page with code, you can start by
              setting up the structure in HTML. You can start by building the
              container for your landing page. This is where all of the content
              will go.
              <pre>{`<div class="container"></div>`}</pre>A <b>div</b> is a
              container that can hold other elements. The class attribute is
              used to give the div a name. This is so that you can style it
              later with CSS. Next, you can add a title to your landing page.
              <pre>{`<h1 class="title">Check out my games</h1>`}</pre>A{" "}
              <b>h1</b> is a heading.
              <pre>{`<h2 class="description">I make games for fun</h2>`}</pre>A{" "}
              <b>h2</b> is a subheading. You can add a description to your page.
            </span>
          ),
        },
        {
          title: "Style it with CSS",
          id: "style-it-with-css",
          body: (
            <span>
              If you want to style your landing page with CSS, you can start by
              adding a style tag to the head of your HTML document.
              <pre>{`<style></style>`}</pre>. Optionally, if you're using a code
              editor like Codepen, you can add the styles directly into the{" "}
              <b>CSS</b> section. Let's add some CSS to style the landing page.
              <pre>{`.container {
  width: 800px;
  height: 600px;
}`}</pre>
              This will set the width and height of the container to 800px and
              600px respectively. A <b>px</b> is a unit of measurement that
              stands for pixels. You can add some styles to your title too.
              <pre>{`.title {
  font-size: 48px;
  font-weight: 800;
  color: red;
}`}</pre>{" "}
              This will set the font size to 48px, the font weight to 800, and
              the color to red. Let's also add some styles to the description.
              <pre>{`.description {
  font-size: 24px;
  font-weight: 400;
  color: blue;
}`}</pre>
              There are many other styles you can add to your HTML{" "}
              <b>elements</b>. You can see a full list of them at{" "}
              <a
                href="https://www.w3schools.com/css/"
                target="_blank"
                style={{ fontWeight: "800", color: "#28323a" }}
              >
                W3Schools
              </a>
              , a great resource for learning <b>HTML</b> and <b>CSS</b>!
            </span>
          ),
        },
        {
          title: "Add a hero",
          id: "add-a-hero",
          body: (
            <span>
              A hero is a large image that captures the essence of your product
              or service. You can add a hero to your landing page by adding an{" "}
              <b>img</b> tag to your HTML document.
              <pre>{`<img class="hero" src="my-awesome-img.png" />`}</pre>
              The <b>src</b> attribute is used to specify the location of the
              image. You can add some styles to your hero too.
              <pre>{`.hero {
  width: 250px;
  height: 250px;
  border-radius: 25px;
}`}</pre>
            </span>
          ),
        },
        {
          title: "Add a call to action",
          id: "add-a-call-to-action",
          body: (
            <span>
              A call to action is a button that visitors click on in order to
              get something in return, such as a free ebook or newsletter. You
              can add a call to action to your landing page by adding a{" "}
              <b>button</b> tag to your HTML document.
              <pre>{`<button class="cta">Sign up for my newsletter</button>`}</pre>
              You can add some styles to your call to action too.
              <pre>{`.cta {
  width: 200px;
  height: 50px;
  border-radius: 25px;
  background-color: green;
  color: white;
}`}</pre>
            </span>
          ),
        },
        {
          title: "Putting it all together",
          id: "putting-it-all-together",
          body: (
            <span>
              Now that you've learned how to build a landing page with{" "}
              <b>HTML</b> and <b>CSS</b>, you can put it all together. Here's an
              example of a landing page that you can build. <br />
              Here's the <span style={{ fontWeight: "800" }}>HTML</span>
              <pre>{`<div class="container">
  <h1 class="title">Check out my games</h1>
  <h2 class="description">I make games for fun</h2>
  <img class="hero" src="my-awesome-img.png" />
  <button class="cta">Sign up for my newsletter</button>
</div>`}</pre>
              And the <span style={{ fontWeight: "800" }}>CSS</span>
              <pre>{`.container {
  width: 800px;
  height: 600px;
}
.title {
  font-size: 48px;
  font-weight: 800;
  color: red;
}
.description {
  font-size: 24px;
  font-weight: 400;
  color: blue;
}
.hero {
  width: 250px;
  height: 250px;
  border-radius: 25px;
}
.cta {
  width: 200px;
  height: 50px;
  border-radius: 25px;
  background-color: green;
  color: white;
}`}</pre>
            </span>
          ),
        },
        {
          title: "Build it with Blockpen",
          id: "build-it-with-blockpen",
          body: (
            <span>
              Woah that was a lot of code! If you want to build your landing
              page with real-world code but don't want to type it all out, you
              can use Blockpen!
            </span>
          ),
          tieInLesson: {
            text: "Try Building One with Blockpen",
            href: "/projects/new",
          },
        },
      ],
    },
  ],
};
